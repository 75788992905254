@import 'share';
.layout {
  padding-bottom: 24px;
}

.side {
  width: 320px;
}

.recommend {
  border-top: 1px solid $gray-100;
}

@include media('>=phone') {
  .container {
    padding-top: 48px;
  }

  .layout {
    display: flex;
    justify-content: space-between;
  }

  .body {
    width: 700px;
  }

  .lowestPrice {
    margin-top: 48px;
  }

  .hasRealGuide {
    margin-top: 0;
  }

  .recommend {
    padding-top: 64px;
  }
}

@include media('<phone') {
  .container {
    position: relative;
    margin-top: -48px;
  }

  .layout {
    display: block;
  }

  .body {
    width: 100%;
  }

  .side {
    display: none;
  }

  .recommend {
    padding-top: 40px;
  }
}
