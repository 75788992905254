@import 'share';
.container {
  composes: container from './CardShare.module.scss';
  margin-bottom: 16px;
  width: 780px;

  &:last-child {
    margin-bottom: 0;
  }

  &::after {
    transition: all .2s ease;
  }

  &:hover::after {
    box-shadow: inset 0 0 0 1px $gray-400;
  }
}

.container.block {
  width: 100%;
}

.body {
  display: inline-block;
  height: 156px;
  padding: 20px 16px 14px 20px;
  position: relative;
  vertical-align: middle;
  width: calc(100% - 208px);
}

.title {
  line-height: 1;
  margin-bottom: 8px;
}

.category {
  line-height: 1;
  margin-bottom: 8px;
}

.review {
  bottom: 12px;
  left: 20px;
  line-height: 1px;
  position: absolute;
}

.dot {
  color: $gray-500;
  margin: 0 4px;
  vertical-align: middle;
}

.price {
  bottom: 12px;
  position: absolute;
  right: 16px;
}

.wishlist {
  top: 8px;
  position: absolute;
  right: 8px;
  z-index: 1;
}

.period {
  display: inline-block;
  vertical-align: middle;
}

@include media('>=tablet', '<desktop') {
  .container {
    width: 681px;
  }
}

@include media('<tablet') {
  .container {
    width: 100%;
  }
}

@include media('<phone') {
  .container {
    border-bottom: 1px solid $gray-200;
    border-radius: 0;
    padding-bottom: 14px;

    &::after {
      box-shadow: none;
    }

    &:hover::after {
      box-shadow: none;
    }
  }

  .body {
    height: 96px;
    padding: 0;
    padding-left: 16px;
    width: calc(100% - 96px);
  }

  .category {
    margin-bottom: 4px;
  }

  .title {
    margin-bottom: 6px;
  }
  
  .price {
    right: 0;
    bottom: 0;
  }

  .review {
    bottom: 0;
    left: 16px;
  }

  .period {
    display: none;
  }

  .wishlist {
    right: 0;
    top: 0;
  }
}

