@import 'share';
.container {
  display: flex;
  align-items: center;
  justify-content: space-between;  
  width: 118px;
  height: 40px;
  padding: 0 10px;
  border: 1px solid $gray-300;
  border-radius: 4px;
  background-color: $white;
  outline: none;
}

.container.partner {
  border: 0;
  background-color: transparent;
}

.content {
  display: flex;
  flex: 1;
}

.partner > .content {
  align-items: center;
}

.icon {
  width: 24px;
  height: 24px;
  margin-right: 4px;
}

.partner .icon {
  width: 16px;
  height: 16px;
  margin-right: 14px;
}

.label {
  font-size: 14px;
  font-weight: 500;
  color: $gray-500;
  vertical-align: top;
}

.arrow {
  width: 16px;
  height: 16px;
}

.partner > .arrow {
  display: none;
}