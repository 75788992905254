@import 'share';
.title {
  h3 {
    font-weight: bold;
    font-size: 22px;
    line-height: 30px;

    em {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
    }
  }

  p {
    margin-top: 4px;
    font-size: 12px;
    color: $gray-500;
  }
}

.form {
  margin-top: 20px;
}

.label {
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
  color: $gray-700;
}

.termsContainer {
  margin-top: 48px;
}

.terms {
  margin-top: 20px;
}

.notice {
  margin-top: 16px;
  margin-bottom: 24px;
  font-size: 12px;
  color: $gray-500;
}

@include media('<phone') {
  .title {
    h3 {
      font-size: 20px;
      line-height: 26px;
    }
  }
}
