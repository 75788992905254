@import 'share';
@value marginZero, paddingZero, borderZero, unsetMinHeight from './layout.module.scss';

.container {
  border-radius: 4px;
  background-color: $white;
  position: relative;
}

.large {
  width: 700px;
}

.medium {
  width: 500px;
}

.small {
  width: 400px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 72px;
  padding: 24px;
  border-bottom: 1px solid $gray-200;
}

.header.hidden {
  display: none;
}

.title {
  composes: marginZero;

  font-size: 20px;
  font-weight: bold;
  color: $gray-800;
}

.header.no-title {
  border-bottom: 0;
}

.close {
  composes: paddingZero borderZero;

  border: 0;
  width: 24px;
  height: 24px;
  background-color: transparent;
}

.body {
  composes: unsetMinHeight;

  height: 100%;
  padding: 24px;
}

.footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 16px 24px;
  border-top: 1px solid $gray-200;
}

@include media('<phone') {
  .container {
    width: 100%;
    height: 100%;
    border-radius: 0;
    display: flex;
    flex-direction: column;
  }

  .header {
    justify-content: center;
    position: relative;
    height: 50px;
    padding: 12px;
  }

  .header-buttons {
    position: absolute;
    right: 12px;
  }

  .title {
    font-size: 17px;
    max-width: 210px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .close {
    position: absolute;
    left: 12px;
  }

  .body {
    padding: 20px;
    flex: 1;
    overflow: auto;
  }

  .footer {
    width: 100%;
    padding: 12px 16px calc(constant(safe-area-inset-bottom) + 12px) 16px;
    padding: 12px 16px calc(env(safe-area-inset-bottom) + 12px) 16px;
    background-color: $white;
  }
}

.fix {
  position: sticky;
  top: 0;
  width: 100%;
  background: white;
  z-index: 100;
}
