@import 'share';
.row {
  text-align: right;
  line-height: normal;
}

.row.margin {
  margin-bottom: 4px;
}

.refund {
  color: $gray-700;
  background-color: $gray-80;
  letter-spacing: -0.3px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 4px;
  padding: 2px 4px;
}

.origin {
  font-size: 13px;
  line-height: 1.23;
  letter-spacing: -0.4px;
  color: $gray-400;
  text-decoration: line-through;
  margin-right: 2px;
  vertical-align: middle;
}

.icon {
  width: 16px;
  height: 16px;
}

.rate {
  font-size: 13px;
  font-weight: 500;
  line-height: 1.23;
  letter-spacing: -0.4px;
  color: $red-400;
  vertical-align: middle;
}

.unit {
  font-size: 13px;
  line-height: 1.38;
  letter-spacing: -0.4px;
  color: $gray-400;
  margin-right: 4px;
}

.price {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: -0.4px;
  color: $gray-800;
}

.symbol {
  font-size: 15px;
  font-weight: 600;
  line-height: 1.33;
  letter-spacing: normal;
  color: $gray-800;
}

.expired {
  font-size: 13px;
  font-weight: 600;
  line-height: 1.23;
  letter-spacing: -0.3px;
  color: $gray-400;
}

@include media('>=phone') {
  .container {
    margin-right: 48px;
  }
}

@include media('<phone') {
  .container {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}
