@import 'share';
.container {
  background-color: $white;
  width: 500px;
  border-radius: 4px;
  box-shadow: 0 15px 20px 0 rgba($black, 0.15), 0 0 0 1px rgba($black, 0.15);
}

.header {
  width: 100%;
  height: 72px;
  border-bottom: 1px solid $gray-200;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-size: 20px;
  font-weight: 700;
  color: $gray-800;
  margin: 0;
}

.body {
  padding: 24px;
}

.content {
  font-size: 15px;
  line-height: 1.67;
  color: $gray-800;
}

.footer {
  width: 100%;
  height: 72px;
  text-align: right;
  padding: 16px;
  border-top: 1px solid $gray-200;
}

@include media('<phone') {
  .container {
    width: 100%;
  }

  .header {
    height: 50px;
  }

  .title {
    font-size: 16px;
  }

  .footer {
    height: 80px;
  }
}