@import 'share';
.container {
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  position: relative;

  &::after {
    border-radius: 4px;
    box-shadow: inset 0 0 0 1px rgba($black, 0.16);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.body {
  background-color: $white; 
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 10px 16px 16px 16px;
  position: relative;
}

@include media('<phone') {
  .body {
    background-color: inherit;
    padding: 8px 0 0 0;
  }
}