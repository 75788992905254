@import 'share';
.container {
  padding: 96px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  margin-bottom: 32px;
  font-size: 32px;
  font-weight: 700;
  color: $white;
}

.notices {
  padding: 0;
  margin-bottom: 32px;
  width: 612px;
  text-align: left;
}

.notice {
  font-size: 16px;
  line-height: 1.38;
  letter-spacing: -0.42px;
  color: $gray-400;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
}

.button {
  width: 176px;
  height: 48px;
  text-align: center;
  color: $white;
  font-size: 16px;
  font-weight: 700;
  border-radius: 2px;
  background-color: #484f55;
  outline: none;
  border: 0;
}

@include media('<phone') {
  .container {
    padding: 72px 0;
  }

  .notices {
    margin-bottom: 30px;
    width: 100%;
  }
}

