// Blue
$blue-50: #f5fbff;
$blue-80: #e7f4fd;
$blue-100: #cbe7fd;
$blue-200: #a7d4f9;
$blue-300: #79bef5;
$blue-400: #51abf3;
$blue-500: #2b96ed;
$blue-600: #1583db;
$blue-700: #0a70c2;
$blue-800: #025ba5;
$blue-900: #01457d;

// Gray
$gray-50: #f8f9fa;
$gray-60: #f5f6f7;
$gray-80: #f1f3f5;
$gray-100: #e9ecef;
$gray-200: #dee2e6;
$gray-300: #ced4da;
$gray-400: #adb5bd;
$gray-500: #848c94;
$gray-600: #666d75;
$gray-700: #495056;
$gray-800: #343a40;
$gray-900: #212529;

// Green
$green-50: #e6f8f3;
$green-80: #d7f6ee;
$green-100: #94e7d1;
$green-200: #71d2b8;
$green-300: #58caab;
$green-400: #33b893;
$green-500: #27ab86;
$green-600: #1c9674;
$green-700: #137b5e;
$green-800: #10634c;
$green-900: #0a4534;

// Yellow
$yellow-50: #fff8e1;
$yellow-80: #fff2c6;
$yellow-100: #ffe182;
$yellow-200: #ffd74e;
$yellow-300: #ffc929;
$yellow-400: #ffbf00;
$yellow-500: #ffad01;
$yellow-600: #ff9a00;
$yellow-700: #f78000;
$yellow-800: #db5600;
$yellow-900: #b94100;

// Red
$red-50: #fbf1ef;
$red-80: #ffd4cc;
$red-100: #ffaea1;
$red-200: #ff8d7c;
$red-300: #fe6a54;
$red-400: #fa5b4a;
$red-500: #ec4937;
$red-600: #dd3c2a;
$red-700: #c83323;
$red-800: #aa2a1b;
$red-900: #8d2115;

// Etc
$white: #fff;
$black: #000;
$review-star: #f8d21c;
$jungle-green: #2cc36b;
$bookingcom-blue: #0b326b;
$bookingcom-text: #5c687f;
$bookingcom-score: #003580;
$bookingcom-review-count: #3e4853;
$kakao: #f7e317;
$kakao-brown: #381e1f;
$apple: #343A40;
$apple-border: #212529;
$facebook: #4267b2;
$naver: #1ec800;
$dgmPurple-bg: #f5eeff;
$dgmPurple: #7439cb;
$promotionDgmPurple: #371f5b;
