@import 'share';
.button {
  width: 176px;
  height: 48px;
  text-align: center;
  color: $white;
  font-size: 16px;
  font-weight: 700;
  border-radius: 2px;
  background-color: #484f55;
  outline: none;
  border: 0;
}

.dialogContainer {
  :global(.mrt-terms-contents) {
    padding: 24px;
  }
}
