@import 'share';
.container {
  font-size: 32px;
  font-weight: 700;
  line-height: 1.25;
  letter-spacing: -0.4px;
  color: $gray-800;
  margin: 0;
  word-break: break-all;
}

@include media('<phone') {
  .container {
    font-size: 24px;
    line-height: 1.33;
  }
}
