@import 'share';
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
.container {
  width: 100%;
  max-width: 1242px;
  padding: 0 16px;
  background-color: $white;
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  width: 48px;
  height: 48px;
  transform: rotate(0deg);
  animation-name: rotate;
  animation-iteration-count: infinite;
  animation-duration: 2s;
  animation-timing-function: linear;
}

:global(.before-loading) {
  height: 100vh;
}

@include media('<phone') {
  .container {
    align-items: flex-start;
  }

  .loader {
    width: 24px;
    height: 24px;
    margin-top: 200px;
  }
}
