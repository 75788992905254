@import 'share';
.offer-container__price__wrapper {
  :global {
    .offer-popover-price-single-datepicker {
      border-radius: 8px;
      border: 1px solid #e9ecef;
      background: #fff;

      box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.1), 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
      z-index: 1000;

      &.Popover.bottom-left {
        top: calc(100% + 12px);
      }
    }
  }
}
