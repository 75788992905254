@import 'share';
.container {
  margin-bottom: 24px;
}

.row {
  position: relative;
  margin-top: 24px;
}

@include media('<phone') {
  .container {
    margin-bottom: 16px;
  }

  .row {
    margin-top: 16px;
    min-height: 24px;
  }

  .row.fix {
    min-height: 46px;
  }

  .price {
    position: absolute;
    top: 0;
    right: 0;
  }
}
