@import 'share';
.container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 20px;
}

@include media('>=phone', '<tablet') {
  .container {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
}

@include media('<phone') {
  .container {
    display: block;
  }
}