// TODO: 기존 button.scss mixin에서 사용 중인 부분을 이 파일에 정의한 새 버튼 디자인으로 교체 필요.

@mixin btn-mrt-new {
  @include transition-effect(0.1s);

  border-radius: 2px;
  cursor: pointer;
  font-weight: 700;
  text-align: center;
}

@mixin common-button {
  background: none;
  border: 0;
  line-height: 1;
  outline: none !important;
  padding: 0;
  vertical-align: middle;
}

.app-close-button {
  @include common-button;

  img {
    height: 24px;
    width: 24px;

    @media #{ $information-mobile } {
      height: 16px;
      width: 16px;
    }

    &:hover {
      background-color: $gray-80;
      border-radius: 2px;
    }
  }
}

.app-button {
  @include common-button;
  @include btn-mrt-new;

  &:disabled {
    box-shadow: none !important;
  }

  &__type {
    &--primary {
      background-color: $blue-400;
      color: $white;

      &:hover {
        background-color: $blue-500;
        box-shadow: 0 1px 3px 0 rgba($black, 0.3);
      }

      &:active {
        background-color: $blue-500;
        box-shadow: 0 0 0 3px $blue-200;
      }

      &:disabled {
        background-color: $gray-200;
        color: $white;
      }
    }

    &--secondary {
      background-color: $blue-80;
      color: $blue-500;

      &:hover {
        background-color: $blue-100;
        box-shadow: 0 1px 3px 0 rgba($blue-500, 0.2);
      }

      &:active {
        background-color: $blue-80;
        box-shadow: 0 0 0 3px $blue-200;
      }

      &:disabled {
        background-color: $gray-60;
        color: $gray-300;
      }
    }

    &--outline {
      background-color: $white;
      border: solid 1px $gray-300;
      color: $gray-700;

      &:hover {
        border: solid 1px $gray-300;
        box-shadow: 0 1px 3px 0 rgba($black, 0.1);
      }

      &:active {
        border: solid 1px $gray-300;
        box-shadow: 0 0 0 3px $gray-80;
      }

      &:disabled {
        border: solid 1px $gray-100;
        color: $gray-300;
      }
    }

    &--gray {
      background-color: $gray-100;
      color: $gray-700;

      &:hover {
        background-color: $gray-200;
        box-shadow: 0 1px 3px 0 rgba($black, 0.2);
      }

      &:active {
        background-color: $gray-100;
        box-shadow: 0 0 0 3px $gray-60;
      }

      &:disabled {
        background-color: $gray-60;
        color: $gray-300;
      }
    }

    &--link {
      background-color: transparent;
      color: $blue-500;
      height: auto !important;
      padding: 4px !important;
      width: auto !important;

      &:hover {
        border-bottom: 2px solid $blue-100;
        color: $blue-600;
        padding-bottom: 1px !important;
      }

      &:active {
        border-bottom: 0;
        box-shadow: 0 0 0 3px $blue-100;
        text-decoration: none;
      }

      &:disabled {
        color: $gray-300;
      }
    }
  }

  &__size {
    &--xl {
      font-size: 18px;
      height: 64px;
      padding: 0 40px;
    }

    &--l {
      font-size: 16px;
      height: 48px;
      padding: 0 16px;
    }

    &--m {
      font-size: 14px;
      height: 40px;
      padding: 0 16px;
    }

    &--s {
      font-size: 13px;
      height: 32px;
      padding: 0 11px;
    }
  }
}
