@import 'share';
.container {
  margin-top: 48px;
  margin-bottom: 0;
}

@include media('<phone') {
  .container {
    margin-top: 24px;
  }
}
