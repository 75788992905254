@import 'share';
.label {
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
  color: $gray-700;
}

.cd {
  display: flex;
  margin-top: 24px;
  justify-content: space-between;

  .cdItem {
    width: calc(50% - 4px);
    margin-left: 4px;

    &:first-child {
      margin-left: 0;
      margin-right: 4px;
    }
  }
}
