@import 'share';
.container {
  padding: 80px 0 50px;

  img {
    margin: 0 auto;
  }

  .link {
    display: block;
  }
}

.section {
  img {
    width: 1060px;
  }
}

.banner {
  margin-top: 24px;

  img {
    width: 1060px;
  }
}

.customer {
  display: flex;
  justify-content: center;
  margin-top: 50px;

  .customerCenterBtn {
    width: 320px;
    background-color: #DFD2FF;
    color: #3A1A68;
    font-size: 22px;
  }

  .customerCenterIco {
    width: 26px;
    margin: 0;
    margin-right: 8px;
  }
}

@include media('<phone') {
  .container {
    padding: 38px 11px 28px;

    img {
      width: 100%;
    }
  }

  .customer {
    padding: 0 35px;
    margin-top: 32px;

    .customerCenterBtn {
      width: 100%;
      font-size: 15px;

      .customerCenterIco {
        width: 19px;
      }
    }
  }
}
