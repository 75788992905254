@import 'share';
.container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.partner {
  display: flex;
  align-items: center;
  flex: 1;
}

.thumbnail {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
  box-shadow: 0 0 0 1px rgba(33, 37, 41, 0.15);
}

.name {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.29;
  letter-spacing: -0.4px;
  color: $gray-800;
}

.messageIcon {
  margin-right: 2px;
}

.messageLabel {
  vertical-align: middle;
}
