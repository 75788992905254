@import 'share';
@include media('>=phone') {
  .video {
    width: 100%;
    height: 394px;
  }
}

@include media('<phone') {
  .video {
    position: relative;
    width: 100vw;
    height: calc((100vw * 9) / 16);
    left: -16px;
  }
}
