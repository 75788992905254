@import 'share';
.title {
  font-size: 22px;
  font-weight: 700;
  line-height: 1.36;
  color: $gray-800;
  margin-bottom: 16px;
}

@include media('>=phone') {
  .container {
    margin-top: 24px;
    margin-bottom: 0;
  }
}
