@import 'share';
.label {
  font-weight: normal;
  color: $gray-800;
}

.large.label {
  font-size: 16px;
}

.medium.label {
  font-size: 14px;
}

.label,
.input {
  vertical-align: bottom;
}

.input {
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  position: relative;
  margin: 0 8px 0 0;
  border: 1px solid $gray-300;
  border-radius: 2px;
  transition: border 0.2s, background-color 0.2s;
}

.input:focus {
  border: 1px solid $gray-300;
}

.input:hover {
  border: 2px solid $gray-400;
}

.input:checked {
  border: 1px solid $blue-400;
  background-color: $blue-400;
}

.input:checked::-ms-check {
  border: 1px solid $blue-400;
  background-color: $blue-400;
  color: white;
}

.input:checked::after {
  content: '';
  display: block;
  position: absolute;
  background: url('../../images/icons/ic-check-white.svg');
  background-position: center;
  background-size: 100%;
}

.input:checked::after::-ms-check {
  content: '';
  display: block;
  position: absolute;
  color: white;
  background-image: url('../../images/icons/ic-check-white.svg');
  background-position: center;
  background-size: 100%;
}

.large.input:checked::after {
  width: 22px;
  height: 18px;
  top: 2px;
  left: 0;
}

.medium.input:checked::after {
  width: 18px;
  height: 16px;
  top: 1px;
  left: 0;
}

.input[disabled] {
  border: 1px solid $gray-100;
}

.large.input {
  width: 24px;
  height: 24px;
}

.medium.input {
  width: 20px;
  height: 20px;
}
