@import 'share';
.input {
  width: 100%;
  border-radius: 2px;
  border: 0;
  box-shadow: 0 0 0 1px $gray-300 inset;
  background-color: $white;
  font-weight: 500;
  color: $gray-800;
  transition: box-shadow 0.2s ease;
  -webkit-appearance: none;
  appearance: none;
}

.input::placeholder {
  color: $gray-400;
}

.input:hover {
  box-shadow: 0 0 0 1px $gray-500 inset;
}

.input:focus {
  box-shadow: 0 0 0 2px $blue-500 inset;
}

.input.small {
  height: 32px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 13px;
}

.input.medium {
  height: 40px;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 14px;
}

.input.large {
  height: 48px;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 16px;
}

.invalid > .input {
  box-shadow: 0 0 0 2px #ff8000 inset;
}

.invalid > .description {
  color: #ff8000;
}

.success > .input {
  box-shadow: 0 0 0 2px $green-400 inset;
}

.success > .description {
  color: $green-400;
}

.description {
  font-size: 12px;
  font-weight: 500;
  color: $gray-500;
  margin-top: 8px;
  margin-bottom: 0;
  width: 100%;
}

.description.left {
  text-align: left;
}

.description.right {
  text-align: right;
}

.input[disabled], .input[readonly] {
  cursor: not-allowed;
}

.input[disabled],
.input[disabled]:hover,
.input[readonly],
.input[readonly]:hover {
  box-shadow: 0 0 0 1px $gray-100 inset !important;
  background-color: $gray-50 !important;
  color: $gray-400 !important;
}

.label {
  width: 100%;
}

.label > p {
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 500;
  color: $gray-700;
  line-height: 16px;
}
