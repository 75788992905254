@import 'share';
.container {
  display: inline-block;
}

.top {
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.29;
  width: 100%;
}

.top > span {
  vertical-align: middle;
}

.originalPrice {
  color: $gray-500;
  text-decoration: line-through;
}

.rate {
  color: $red-400;
}

.price {
  font-size: 24px;
  font-weight: 700;
  color: $gray-800;
  line-height: 1;
  letter-spacing: -0.34px;
}

.coupon,
.suffix {
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.22px;
  color: $gray-500;
}

.coupon {
  margin-right: 4px;
}

.suffix {
  margin-left: 4px;
}

.resting {
  font-size: 13px;
  color: $red-500;
}

@include media('<phone') {
  .container {
    margin-bottom: 0;
  }

  .top {
    text-align: right;
  }

  .price {
    font-size: 20px;
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: -0.29px;
  }

  .bottom {
    text-align: right;
  }
}
