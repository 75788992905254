@import 'share';
.container {
  width: 100%;
  text-align: center;
}

.description {
  font-size: 12px;
  color: $gray-600;
}

.container > .link {
  font-size: 12px;
  color: $gray-600;
  text-decoration: underline;
  margin-left: 4px;
}