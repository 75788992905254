@import 'share';
.RangeDatePicker--vertical {
  .DayPicker_weekHeader {
    padding: 0 !important;
    border-bottom: 1px solid $gray-200;
  }

  .DayPicker_weekHeader_ul {
    display: flex;
    margin: 0;
    font-size: 13px;
    color: $gray-600;
    padding: 8px 0px;
  }

  .DayPicker_weekHeader_li {
    flex: 1;
  }

  .DayPicker_transitionContainer {
    padding-top: 35px;
    padding-bottom: 130px;
  }

  .CalendarMonthGrid {
    width: 100% !important;
  }

  .CalendarMonth {
    padding: 0 !important;
  }

  .CalendarMonth_caption {
    padding-right: 16px;
  }

  .CalendarMonth_table {
    width: 100%;
  }

  .CalendarDay {
    height: calc(100vw / 7) !important;
    width: calc(100vw / 7) !important;
  }

  .CalendarDay__selected_span:not(.CalendarDay__selected_start) {
    background-color: $blue-80 !important;
  }
}

.RangeDatePicker--horizontal {
  .CalendarDay {
    height: 40px;
    width: 40px;
  }

  .CalendarDay__selected_span:not(.CalendarDay__selected_start) {
    background-color: $blue-80 !important;
  }

  .DayPicker__hidden {
    visibility: visible;
  }

  .DayPicker_transitionContainer {
    height: 328px !important;
  }

  .DayPickerNavigation {
    display: flex;
    justify-content: space-between;
  }

  .DayPickerNavigation_button:first-child {
    margin-top: 20px;
    margin-left: 16px;
    border: 0;
  }

  .DayPickerNavigation_button:last-child {
    margin-top: 20px;
    margin-right: 16px;
    border: 0;
  }

  .DayPickerNavigation_button__disabled > img {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .DayPickerNavigation_button__disabled > img:hover {
    background-color: transparent;
  }
}
