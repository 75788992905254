@import 'share';
.container {
  p {
    font-size: 15px;
  }
  em {
    font-style: normal;
    color: $blue-500;
  }

  .customerCenterLink {
    padding: 0;
    margin: 0;
    border: 0;
    background: transparent;
    color: $blue-500;
  }
}

@include media('<phone') {
  .container {
    padding: 0 24px;
  }
  // Dialog custom
  .container > div {
    border-radius: 4px;
  }

  .container header {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    justify-content: space-between;
    padding-left: 24px;

    h2 {
      font-size: 16px;
    }

    & button {
      position: relative;
      left: auto;
    }
  }

  .container section {
    padding: 24px 0;
  }

  .container footer {
    position: relative;
    padding: 16px 24px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    & > a {
      width: 100%;
    }
  }
}
