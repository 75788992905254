@import 'share';
.container {
  position: relative;
}

.absolute {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute !important;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
}

.preImage {
  background-color: $gray-80;
  background-size: 32px 24px !important;
}

.loadedImage {
  background-size: cover;
  opacity: 0;
  transition: opacity 0.5s linear;
}

.children {
  z-index: 1;
}

.fadeInImage {
  opacity: 1;
}
