@import 'share';
.container {
  position: relative;
  text-align: left;
  width: 118px;
}

.select {
  appearance: none;
  width: 100%;
  height: 40px;
  border: 1px solid $gray-300;
  border-radius: 4px;
  outline: none;
  color: $gray-500;
  font-size: 14px;
  font-weight: 500;
  background-color: $white;
  background-repeat: no-repeat;
  background-position: 10px 8px;
  background-size: 24px;
  padding-left: 38px;
}

.arrow {
  position: absolute;
  top: 12px;
  right: 10px;
  width: 16px;
  height: 16px;
}