@import 'share';
.list {
  margin-top: 32px;
}

.sns {
  margin-bottom: 12px;
}

.sns:last-child {
  margin-bottom: 0;
}

.share {
  width: 100%;
  border: 0;
  outline: none;
  border-radius: 6px;
  padding: 12px;
  text-align: left;
  font-size: 16px;
  font-weight: 700;
}

.share > img {
  width: 32px;
  height: 32px;
  margin-right: 16px;
}

.share > span {
  vertical-align: middle;
}

.share.facebook {
  background-color: #355f9f;
  color: $white;
}

.share.kakao {
  background-color: #fae100;
  color: #381e1f;
}

.share.link {
  background-color: $gray-100;
  color: $gray-900;
}

@include media('<phone') {
  .list {
    margin-top: 16px;
  }
}