@import 'share';
.container {
  padding: 42px 0 40px;

  img {
    margin: 0 auto;
  }

  .box {
    padding: 0 55px;
    margin: 0 auto 32px;
    border-radius: 16px;
    background-color: $gray-50;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.title {
  img {
    width: 376px;
  }
}

.coupon {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}

.couponCard {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 329px;
  padding: 8px 16px 16px;
  border-radius: 8px;
  background-color: $gray-100;

  .imgBox {
    display: flex;
    align-items: center;
  }

  img {
    width: 227px;
  }

  .couponBtn {
    margin-top: 8px;
  }
}

.notice {
  margin-top: 40px;
  
  img {
    width: 510px;
  }
}

@include media('<phone') {
  .container {
    padding: 18px 0 32px;

    img {
      width: 100%;
    }

    .box {
      padding: 0;
      margin-bottom: 28px;
      border-radius: 16px;
    }
  }

  .title {
    padding: 0 27px;
  }

  .coupon {
    margin-top: 19px;

    .couponCard {
      padding-bottom: 28px;

      img {
        width: 245px;
      }

      .couponBtn {
        margin-top: -10px;
        width: 209px;
      }
    }
  }

  .notice {
    padding: 0 16px;
    margin-top: 32px;
  }
}
