@import 'share';
.container {
  border-radius: 4px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
}

.container.vertical {
  height: 166px;
  width: 100%;
}

.container.horizontal {
  height: 156px;
  width: 208px;
}

.image {
  height: 100%;
  width: 100%;
}

.vertical .image {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.horizontal .image {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.guarantee {
  background-color: $yellow-600;
  bottom: 0;
  color: $white;
  display: inline-block;
  font-size: 11px;
  font-weight: 500;
  line-height: 1;
  padding: 8px;
  position: absolute;
  right: 0;
}

@include media('<phone') {
  .container.vertical {
    height: 106px;
  }

  .container.horizontal {
    height: 96px;
    width: 96px;
  }

  .vertical .image {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .horizontal .image {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .guarantee {
    border-bottom-right-radius: 4px;
    font-size: 10px;
    padding: 4px;
  }
}