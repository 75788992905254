@import 'share';
.container {
  background-color: $white;
  border-radius: 5px;
  left: 50%;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 500px;

  .header {
    border-bottom: 1px solid $gray-200;
    padding: 24px;

    .title {
      color: $gray-800;
      font-size: 20px;
      font-weight: 700;
    }

    .button {
      cursor: pointer;
    }

    .close {
      float: right;
      height: 24px;
      vertical-align: middle;
      width: 24px;
    }
  }

  .body {
    color: $gray-800;
    font-size: 15px;
    letter-spacing: -0.2px;
    line-height: 1.67;
    padding: 24px;
    word-break: keep-all;

    .row {
      @media #{ $information-mobile } {
        display: inline;
        margin-right: 5px;
      }
    }

    .row > p {
      margin-bottom: 12px;
      line-height: 1.44;
      word-break: break-all;
    }

    .list {
      list-style: disc;
      padding-left: 20px;
    }

    .list > li {
      margin-bottom: 8px;
    }

    &__detail {
      font-weight: 600;
      padding-left: 18px;
    }

    .blue {
      color: $blue-500;
      font-weight: 600;
    }

    label {
      cursor: pointer;
      display: block;
      font-size: 14px;
      margin-top: 15px;
    }
  }
}

.footer {
  border-top: 1px solid $gray-200;
  padding: 12px 24px;

  span,
  .input {
    vertical-align: middle;
  }

  span {
    font-size: 14px;
    font-weight: normal;
    color: $gray-700;
  }

  .input {
    appearance: auto;
    appearance: checkbox;
    margin-right: 8px;
  }
}

@include media('<phone') {
  .container {
    width: 288px;
    .title {
      width: 200px;
      display: inline-block;
      line-height: normal;
    }
  }

  .body {
    max-height: 300px;
    overflow-y: auto;
  }
}
