@import 'share';
.container {
  width: 100%;
  padding: 12px 0;
  margin-bottom: -1px;
  border-top: 1px solid $gray-100;
  border-bottom: 1px solid $gray-100;
}

@include media('<phone') {
  .container {
    padding: 10px 0;
  }
}
