@import 'share';
.content {
  padding: 24px;
  color: $gray-800;
  font-size: 15px;
}

@include media('<phone') {
  .content {
    margin-bottom: 37px;
  }
}
