@import 'share';
.container {
  padding: 17px 0;
  margin: 8px 0 -1px;
  border-top: 1px solid $gray-100;
  border-bottom: 1px solid $gray-100;

  li {
    line-height: 38px;
    font-size: 16px;
    color: $gray-700;

    & > img {
      margin-right: 5px;
      vertical-align: -6px;
    }
  }

  @media #{ $information-mobile } {
    padding: 9px 0;
  }
}

.more {
  margin-left: 5px;
}
