@import 'share';
.container {
  padding: 32px 0;
  width: 100%;
  border-top: 1px solid $gray-100;
}

.container.noBorder {
  border-top: 0;
}

.container.noPadding {
  padding: 0;
}

.body {
  width: 100%;
}

.row {
  margin-bottom: 32px;
}

.title {
  font-size: 22px;
  font-weight: 700;
  line-height: 1.36;
  color: $gray-800;
  margin-top: 0;
  margin-bottom: 16px;
}

@include media('<phone') {
  .container {
    padding: 24px 0;
  }

  .row {
    margin-bottom: 24px;
  }

  .title {
    font-size: 20px;
  }
}
