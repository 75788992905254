@import 'share';
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 48px;
  padding: 0;
  border: 0;
  border-radius: 4px;
  background-color: $blue-500;
  outline: none;
  cursor: pointer;
  color: $white;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.2px;
  line-height: 1;
}

@include media('<phone') {
  .button {
    width: calc(100% - 36px);
    height: 50px;
    margin-right: 0;
  }
}
