@import 'share';
.container {
  position: relative;
  display: inline-block;
}

.review {
  color: $gray-500;
  font-size: 12px;
  margin-left: 4px;
  vertical-align: middle
}

.eventIcon {
  height: 12px;
  margin-right: 4px;
  width: 12px;
}

.eventText {
  color: $blue-500;
  font-size: 12px;
  line-height: 1;
  vertical-align: middle;
}

@include media('<phone') {
  .container {
    font-size: 11px;
  }

  .review {
    font-size: 11px;
  }

  .eventText {
    font-size: 11px;
    line-height: 1.18;
  }
}