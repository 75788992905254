@mixin filter($color, $opacity) {
  background-color: #{$color};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=#{$opacity})'; /* IE-8*/
  filter: alpha(opacity=#{$opacity}); /* IE-7*/
  opacity: #{$opacity/100};
}

@mixin opacity($opacity) {
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=#{$opacity * 100})'; /* IE-8*/
  filter: alpha(opacity=#{$opacity * 100}); /* IE-7*/
  opacity: #{$opacity};
}

@mixin content-top {
  top: 0;
  margin: 0 auto;
  margin-top: 10px;
}

@mixin content-middle($top) {
  top: #{$top};
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  margin: 0 auto;
}

@mixin content-bottom($bottom-margin) {
  bottom: 0;
  font-size: 14px;
  margin-bottom: #{$bottom-margin};
  width: 100% !important;
}

@mixin content-on-image($text-align, $width) {
  position: absolute;
  left: 0;
  right: 0;
  z-index: z('content-on-image');
  color: $white;
  text-align: #{$text-align};
  width: #{$width};
}

@mixin content-middle-center {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

@mixin translate($x, $y) {
  -webkit-transform: translate($x, $y);
          transform: translate($x, $y);
}

@mixin long-text-dots($line) {
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: #{$line};
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  overflow: hidden;
}

@mixin vertical-middle(){
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}

@mixin text-truncate($max-width) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: #{$max-width};
}

@mixin linear-gradient($direction, $ie-num, $gradients...) {
  background-color: nth($gradients, $ie-num);
  background-image: -webkit-gradient(linear, left top, left bottom, from($direction), to($gradients...));
  background-image: linear-gradient($direction, $gradients...);
}

@mixin box-shadow($args) {
  -webkit-box-shadow: $args;
  box-shadow: $args;
}

@mixin rotate($deg) {
  -webkit-transform: rotate($deg);
  transform: rotate($deg);
}

@mixin event-button($width, $heights, $font-sizes, $text-color, $bg-color) {
  background-color: $bg-color;
  color: $text-color;
  display: block;
  font-size: nth($font-sizes, 1);
  font-weight: 600;
  height: nth($heights, 1);
  line-height: nth($heights, 1);
  margin: 0 auto;
  text-align: center;
  width: $width;

  &:hover {
    background-color: darken($bg-color, 10%);
  }

  @media #{ $information-desktop } {
    font-size: nth($font-sizes, 2);
    height: nth($heights, 2);
    line-height: nth($heights, 2);
  }

  @media #{ $information-tablet } {
    font-size: nth($font-sizes, 3);
    height: nth($heights, 3);
    line-height: nth($heights, 3);
  }

  @media #{ $information-mobile } {
    font-size: nth($font-sizes, 4);
    height: nth($heights, 4);
    line-height: nth($heights, 4);
    width: 100%;
  }
}

@mixin arrow-up($size, $color) {
  border-bottom: $size solid $color;
  border-left: $size solid transparent;
  border-right: $size solid transparent;
  display: block;
  height: 0;
  width: 0;
}

@mixin placeholder-color($color) {
  ::-webkit-input-placeholder { color: $color; }
  ::-moz-placeholder { color: $color; }
  :-ms-input-placeholder { color: $color; }
  :-moz-placeholder { color: $color; }
}

@mixin keep-placeholder-on-focus($color) {
  &::-webkit-input-placeholder {
    color: $color;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
  &::-ms-input-placeholder {
    color: $color;
  }
  &::placeholder {
    color: $color;
  }

  &:focus::-webkit-input-placeholder{ color: $color !important }
  &:focus::-moz-placeholder{ color: $color !important }
  &:focus:-moz-placeholder{ color: $color !important }
  &:focus:-ms-input-placeholder{ color: $color !important }
}

@mixin transition-effect($duration: .08s, $timing-func: ease-out) {
  -webkit-transition: all $duration $timing-func;
  transition: all $duration $timing-func;
}

@mixin responsive-row {
  margin: 0 auto;
  width: 1060px;
  
  @media #{ $information-only-desktop } {
    margin: 0 auto;
    width: 928px;
  }

  @media #{ $information-only-tablet } {
    padding-left: 72px;
    width: 100%;
  }

  @media #{ $information-only-tablet } {
    padding-left: 72px;
    width: 100%;
  }
}

@mixin vertical-center {
  top: 50%;
  transform: translate(0, 50%);
}


@mixin offer-margin {
  margin: 32px 0px;
}