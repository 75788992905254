.ComboBox {
  background-color: $white;
  border-radius: 4px;
  padding: 8px 0;
  width: 400px;

  @media #{ $information-mobile } {
    border-radius: 0;
    width: 100%;    
  }

  &__item {
    color: $gray-800;
    font-size: 16px;
    padding: 12px 24px;

    &--selected {
      color: $blue-400;
      font-weight: 500;
    } 

    &__check {
      height: 24px;
      float: right;
      width: 24px
    }
  }
}