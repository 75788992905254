.offer-mobile-banner {
  @media #{$information-only-tablet} {
    width: 100%;
    height: 80px;
  }
  margin: 0 auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  @media #{$information-mobile} {
    width: 100%;
    height: 80px;
  }
}

.offer-mobile-banner-wrapper {
  @media #{$information-only-tablet} {
    height: 80px;
    width: 100%;
    margin-bottom: 32px;
  }
  @media #{$information-mobile} {
    width: 100%;
  }
}
