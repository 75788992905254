@import 'share';
.body {
  composes: body from './CardShare.module.scss';
  height: 121px;
}

.review {
  bottom: 16px;
  left: 16px;
  position: absolute;
}

.price {
  bottom: 16px;
  position: absolute;
  right: 16px;
}

.score {
  display: none;
  position: absolute;
  right: 8px;
  top: 8px;
}

@include media('<phone') {
  .review {
    display: none;
  }

  .price {
    bottom: 8px;
    left: 0;
    right: inherit;
  }

  .score {
    display: block;
  }
}