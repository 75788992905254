@import 'share';
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.number {
  display: flex;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: $gray-800;
  font-size: 18px;
  font-weight: 700;
  color: $white;
  margin-bottom: 26px;
  align-items: center;
  justify-content: center;
}

.title {
  margin: 0;
  font-size: 22px;
  font-weight: 700;
  line-height: 1.3;
  color: $gray-800;
}

@include media('<phone') {
  .number {
    margin-bottom: 16px;
  }
}