@import 'share';
.button {
  border: 0;
  padding: 0;
  position: relative;
  border-radius: 4px;
  background-color: transparent;
  font-weight: bold;
  text-align: center;
  cursor: pointer;

  transition: box-shadow 0.1s ease-out, background-color 0.1s ease-out, color 0.1s ease-out,
    border-bottom 0.1s ease-out;
}

button:focus {
  outline: none;
}

.button[disabled] {
  cursor: not-allowed;
}

.button[disabled]:hover {
  box-shadow: none;
}

.button > a {
  color: inherit;
  text-decoration: none;
}

.xLarge {
  height: 64px;
  padding-left: 40px;
  padding-right: 40px;
  font-size: 18px;
}

.large {
  height: 48px;
  padding-left: 32px;
  padding-right: 32px;
  font-size: 16px;
}

.medium {
  height: 40px;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 14px;
}

.link.medium {
  height: auto;
  padding: 6px;
  font-size: 14px;
}

.link.large {
  height: auto;
  padding: 8px;
  font-size: 16px;
}

.small {
  height: 32px;
  padding-left: 11px;
  padding-right: 11px;
  font-size: 13px;
}

.primary {
  border: 1px solid $blue-400;
  background-color: $blue-400;
  color: $white;
}

.primary:hover {
  border: 1px solid $blue-500;
  background-color: $blue-500;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
  color: $white;
}

.primary:visited {
  color: $white;
}

.primary:active {
  border: 1px solid $blue-500;
  background-color: $blue-500;
  box-shadow: 0 0 0 3px $blue-200;
}

.primary:focus {
  color: $white;
}

.primary[disabled] {
  border: 1px solid $blue-100;
  background-color: $blue-100;
}

.secondary {
  background-color: $blue-80;
  color: $blue-500;
}

.secondary:hover {
  background-color: $blue-100;
  box-shadow: 0 1px 3px 0 rgba(43, 150, 237, 0.2);
  color: $blue-500;
}

.secondary:visited {
  color: $blue-500;
}

.secondary:active {
  background-color: $blue-80;
  box-shadow: 0 0 0 3px $blue-200;
}

.secondary:focus {
  color: $blue-500;
}

.secondary[disabled] {
  background-color: $blue-50;
  color: $blue-200;
}

.outline {
  border: 1px solid $gray-300;
  background-color: $white;
  color: $gray-700;
}

.outline:hover {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  color: $gray-700;
}

.outline:visited,
.outline:focus {
  color: $gray-700;
}

.outline:active {
  box-shadow: 0 0 0 3px $gray-80;
}

.outline[disabled] {
  border: 1px solid $gray-100;
  color: $gray-300;
}

.gray {
  background-color: $gray-100;
  color: $gray-700;
}

.gray:hover {
  background-color: $gray-200;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  color: $gray-700;
}

.gray:visited,
.gray:focus {
  color: $gray-700;
}

.gray:active {
  background-color: $gray-100;
  box-shadow: 0 0 0 3px $gray-60;
}

.gray[disabled] {
  background-color: $gray-60;
  color: $gray-300;
}

.link {
  color: $blue-500;
  line-height: 1;
}

.link:hover {
  color: $blue-500;
  background-color: $blue-50;
  border-bottom: 0;
}

.link:focus {
  color: $blue-500;
  box-shadow: none;
}

.link:visited {
  color: $blue-500;
}

.link:active {
  color: $blue-600;
  background-color: $blue-80;
}

.link[disabled] {
  color: $gray-300;
  background-color: transparent;
}

.block {
  width: 100%;
  padding-left: 0 !important;
  padding-right: 0 !important;
  text-align: center;
}

.round {
  padding-left: 15px;
  padding-right: 15px;
}

.round.xLarge {
  border-radius: 32px;
}

.round.large {
  border-radius: 24px;
}

.round.medium {
  border-radius: 20px;
}

.round.small {
  border-radius: 16px;
}
