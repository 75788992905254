@import 'share';
.container {
  position: relative;
}

.popup {
  bottom: calc(100% + 12px);
  background-color: $white;
}

.options {
  width: 160px;
  padding: 6px 0;
}

.option {
  font-size: 15px;
  font-weight: 500;
  color: $gray-800;
  width: 100%;
}

.option.selected {
  color: $blue-500;
}

.button {
  width: 100%;
  height: 34px;
  padding: 0 16px;
  outline: none;
  border: 0;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: background-color 0.2s ease;
  background-color: $white;

  &:hover {
    background-color: $gray-80;
  }
}

.check {
  width: 16px;
  height: 16px;
}