@import 'share';
.container {
  width: 520px;
  margin: 56px auto 120px;
  padding: 40px 48px 48px;
}

.title {
  padding-bottom: 40px;
}

.title > h1 {
  margin-bottom: 8px;
  font-size: 32px;
  font-weight: 600;
  color: $gray-800;
  text-align: center;
}

.title > h2 {
  margin-bottom: 0;
  font-size: 16px;
  color: $gray-600;
  text-align: center;
}

.form {
  padding: 24px;
  border: 1px solid #dee2e6;
  border-radius: 8px;
}

.form.edit {
  margin-top: 24px;
}

.form.edit > h3 {
  margin-bottom: 24px;
  font-size: 16px;
  font-weight: 600;
  color: $gray-700;
}

@include media('<phone') {
  .container {
    width: 100%;
    margin: 32px 0 88px;
    padding: 0 18px;
  }

  .title > h1 {
    font-size: 24px;
  }

  .title > h2 {
    font-size: 14px;
  }

  .form {
    padding: 0;
    border: none;
  }
}
