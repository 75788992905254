.CardThumbnail {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  height: 187px;
  position: relative;
  width: 250px;

  @media #{ $information-desktop } {
    height: 187px;
    width: 296px;
  }

  @media #{ $information-mobile } {
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    height: 120px;
    width: 170px;
  }

  &__Wishlist {
    position: absolute;
    right: 8px;
    top: 8px;
  }

  &__LowPrice {
    background-color: $yellow-600;
    bottom: 0;
    color: $white;
    font-size: 11px;
    font-weight: 500;
    padding: 6px 8px;
    position: absolute;
    right: 0;

    @media #{ $information-mobile } {
      border-radius: 2px;
      font-size: 10px;
      padding: 4px;
    }
  }

  &__Loader {
    height: 100%;
    width: 100%;

    @include media('<phone') {
      border-radius: 2px;
    }
  }
}

.CardContent {
  padding: 8px 16px;
  width: 100%;

  @media #{ $information-mobile } {
    padding: 8px 0 0 0;
  }
}

.CardTitle {
  font-size: 15px;
  font-weight: 600;
  height: 45px;
  line-height: 1.47;
  margin-bottom: 20px;
  width: 100%;

  @media #{ $information-mobile } {
    font-size: 14px;
    height: 36px;
    line-height: 1.29;
    margin-bottom: 7px;
  }
}

.CardReview {
  display: inline-block;

  &__Count {
    color: $gray-500;
    font-size: 12px;
    line-height: 1;
    margin-left: 4px;
    vertical-align: middle;

    @media #{ $information-mobile } {
      bottom: 0;
      font-size: 11px;
    }
  }
}

.CardReviewEvent {
  line-height: 1;

  &__Image {
    height: 12px;
    margin-right: 4px;
    width: 12px;
  }

  &__Label {
    color: $blue-500;
    font-size: 12px;
    vertical-align: middle;
  }
}
