@import 'share';
.container {
    margin-top: 32px;
}

.container > h1 {
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 600;
    color: $gray-800;
}

.table {
    width: 100%;
    border-top: 1px solid $gray-100;
    border-bottom: 1px solid $gray-100;
}

.row {
    width: 100%;
    height: 48px;
    display: flex;
    border-bottom: 1px solid $gray-100;
}

.row:last-child {
    border-bottom: 0;
}

.row-title {
    width: 100px;
    height: 47px;
    padding-left: 10px;
    background-color: $gray-50;
}

.row-title > p {
    font-size: 14px;
    color: $gray-600;
    line-height: 48px;
}

.row-content {
    width: 100%;
    padding-left: 16px;
}

.row-content > p {
    font-size: 14px;
    font-weight: 500;
    color: #424242;
    line-height: 48px;
}

.account {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.account > p {
    font-size: 14px;
    font-weight: 500;
    color: #424242;
}

@include media('<phone') {
    .container > h1 {
        margin-left: 16px;
    }

    .row-title {
        padding-left: 16px;
    }

    .row-content {
        padding-right: 16px;
    }

    .account > p {
        line-height: 1.14;
    }
}
