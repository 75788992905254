.share-tooltip{
  width: 148px;
  z-index: 10;
  background-color: $white;
  box-shadow: 0 0 0 1px rgba(52, 58, 64, 0.15), 0 3px 8px 0 rgba(52, 58, 64, 0.15);
  text-align: left;
  border-radius: 2px;
  padding: 16px 0 8px;

  &--close{
    cursor: pointer;
    width: 20px;
    height: 20px;
    background-size: 16px;
    background-position: center;
    position: relative;
    top: -2px;
    right: -2px;
    display: inline-block;
    vertical-align: middle;
    float: right;
    &:hover{
      background-color: $gray-80;
    }
  }

  &__header {
    font-size: 15px;
    font-weight: bold;
    padding: 0px 16px;
    line-height: 16px;
    img {
      width: 16px;
      height: 16px;
      margin-left: 48px;
      &:hover{
        background-color: $gray-80;
      }
    }
  }
  &__content {
    padding: 0px 16px;
    height: 35px;
    line-height: 35px;
    &--top{
      margin-top: 8px;
    }
    img{
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
    p{
      vertical-align: middle;
      font-size: 15px;
      color: $gray-800;
      display: inline-block;
      width: 67px;
      height: 35px;
    }
    &:hover {
      border-radius: 2px;
      background-color: $gray-80;
    }
  }
}