@import 'share';
.container {
  width: 100%;
  height: 140px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  display: block;
  margin-bottom: 16px;
  border-radius: 4px;
}

.wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 32px;
  background-blend-mode: multiply;
  background-image: linear-gradient(to bottom, rgba($black, 0.25), rgba($black, 0.25));
  border-radius: 4px;
}

.content {
  text-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
  color: $white;
}

.content > .city {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 6px;
}

.content > .description {
  font-size: 16px;
  font-weight: 700;
}

.button {
  width: 106px;
  height: 40px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: $gray-900;
  background-color: $white;
}

@include media('<phone') {
  .container {
    height: 166px;
  }

  .wrapper {
    flex-direction: column;
    justify-content: center;
    padding: 0;
  }

  .container, .wrapper {
    border-radius: 0;
  }

  .content {
    text-align: center;
  }

  .content > .description {
    margin-bottom: 12px;
  }
}

