@import 'share';
.container {
  position: fixed;
  top: 0;
  left: 0;
  transform: translateY(-72px);
  transition: transform 0.2s ease;
  z-index: 10;
  background-color: $white;
  width: 100%;
  box-shadow: inset 0 -1px 0 0 $gray-100;
}

.container.show {
  transform: translateY(0);
}

.row {
  display: block;
}

.nav {
  width: 100%;
}

.share {
  display: none;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  z-index: 10;
}

.shareBtn {
  height: 100%;
  background-color: $white;
  padding: 12px;
  width: 48px;
}

.shareBtn > img {
  width: 24px;
  height: 24px;
}

.gradient {
  width: 40px;
  height: 100%;
  object-fit: contain;
  background-image: linear-gradient(to left, $white 0%, rgba($white, 0) 100%);
}

@include media('<phone') {
  .share {
    display: flex;
  }
}
