@import 'share';
.container {
  background-color: #003580;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  color: $white;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  height: 30px;
  line-height: 30px;
  text-align: center;
  vertical-align: middle;
  width: 30px;
}