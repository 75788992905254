@import 'share';
.container {
  position: relative;
  display: flex;
  height: 225px;
  width: 460px;
  margin-bottom: 24px;
  background: $white;
  border: 1px solid #D0D0D0;
  border-radius: 8px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    padding: 22px 0 12px 30px;

    .title {
      h5 {
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.2px;
        color: #2F3134;
      }

      p {
        margin: 0;
        font-weight: 600;
        font-size: 15px;
        line-height: 33px;
        letter-spacing: -0.62069px;
        color: #6B6F77;
        white-space: pre-wrap;
      }
    }

    .priceBox {
      .discountPrice {
        margin-bottom: 3px;
        text-decoration: line-through;
        font-size: 16px;
        line-height: 33px;
        letter-spacing: -0.005em;
        color: #6B6F77;
      }

      .tag {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 90px;
        height: 28px;
        background: #FFE1E1;
        border-radius: 4px;
        font-weight: 600;
        font-size: 16px;
        line-height: 42px;
        letter-spacing: -0.66px;
        color: #E43D3D;
      }

      .salePrice {
        font-weight: 700;
        font-size: 25px;
        line-height: 46px;
        letter-spacing: -0.01em;

        color: #2F3134;
      }
    }
  }

  .imgBox {
    margin-left: auto;
    width: 225px;

    .img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .arrow {
    position: absolute;
    bottom: 17px;
    right: 11px; 
    width: 57px;
    z-index: 10;
  }
}

@include media('<phone') {
  .container {
    height: 165px;
    width: 100%;
    margin-bottom: 16px;
    border-radius: 8px;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.1);

    .content {
      padding: 15px 0 12px 19px;

      .title {
        h5 {
          font-size: 17px;
          line-height: 24px;
        }

        p {
          font-size: 12px;
          line-height: 24px;
          letter-spacing: -0.45px;
        }
      }

      .priceBox {
        .discountPrice {
          margin-bottom: 2px;
          font-size: 12px;
          line-height: 24px;
        }

        .tag {
          width: 63px;
          height: 20px;
          border-radius: 3px;
          font-size: 11px;
          line-height: 30px;
          letter-spacing: -0.47px;
        }

        .salePrice {
          font-size: 18px;
          line-height: 33px;
          letter-spacing: -0.01em;
        }
      }
    }

    .imgBox {
      width: 165px;
    }

    .arrow {
      bottom: 8px;
      right: 9px;
      width: 39px;
    }
  }
}

@include media('<smallPhone') {
  .container {
    .imgBox {
      width: 120px;
    }
  }
}
