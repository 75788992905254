@import 'share';
.container {
  display: flex;
  width: 100%;
  padding: 24px;
  background-color: $white;
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 rgba($black, 0.1), 0 0 0 1px rgba($black, 0.05);
}

.status {
  width: 88px;
  margin-right: 8px;
  font-size: 15px;
  font-weight: 600;
}

.status.warning {
  color: #fa9a00;
}

.status.danger {
  color: $red-500;
}

.status.success {
  color: $green-500;
}

.status.complete {
  color: $gray-600;
}

.summary {
  flex: 1;
}

.header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 16px;
}

.header > .title {
  color: $gray-800;
  font-size: 18px;
  font-weight: 600;
  word-break: keep-all;
  line-height: 1.33;
  margin-right: 16px;
}

.header > .icon {
  width: 24px;
  height: 24px;
}

.content {
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  line-height: normal;
}

.content:last-child {
  margin-bottom: 0;
}

.content > .label {
  color: $gray-500;
  display: inline-block;
  width: 88px;
  margin-right: 8px;;
}

.content > .value {
  color: $gray-800;
}

@include media('<phone') {
  .container {
    flex-direction: column;
  }

  .status {
    margin-bottom: 10px;
  }
}

