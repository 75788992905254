@import 'share';
.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-y: auto;
    background-color: rgba(102, 109, 117, 0.9);
}

.reason {
    padding-top: 8px;
    padding-bottom: 16px;
}

.reason.no-account {
    padding-bottom: 0;
}

.account {
    padding-top: 32px;
    padding-bottom: 16px;
    border-top: 1px solid $gray-200;
}

.account > h2 {
    margin-bottom: 24px;
    font-size: 16px;
    font-weight: 600;
    color: $gray-700;
}

.footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer.no-account {
    justify-content: flex-end;
}

.footer > a {
    font-size: 13px;
    color: $gray-600;
    text-decoration: underline !important;
}

.toast {
  width: 100%;
}
