@import 'share';
.container {
  width: 100%;
  height: 160px;
  padding: 32px 40px;
  border-radius: 4px;
  border: solid 1px $gray-200;
  background-color: $white;
  box-shadow: 0 1px 0 0 #e9ecef;
}

.container > h2 {
  margin-bottom: 8px;
  font-size: 24px;
  font-weight: 600;
  color: $gray-700;
}

.account {
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 1px 0 0 $gray-100;
}

.account > h3 {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 600;
  color: $gray-800;
}

.account > p {
  font-size: 14px;
  color: $gray-800;
  flex: 1;
  text-align: center;
}

.account > p.empty {
  color: $gray-500;
}

@include media('<phone') {
  .container {
    height: 136px;
    padding: 24px 16px;
  }

  .container > h2 {
    margin-bottom: 12px;
    font-size: 16px;
  }
}
