@import 'share';
.container {
  display: inline-block;
  margin-right: 70px;
  text-align: left;
  width: 260px;

  &:last-child {
    margin-right: 0;
  }
}

.header {
  margin-bottom: 12px;
}

.logo {
  height: 30px;
  width: 30px;
}

.title {
  color: $blue-500;
  font-size: 14px;
  font-weight: 500;
  margin-left: 16px;
  vertical-align: middle;

  &:hover {
    color: $blue-500;
  }

  &:visited {
    color: $blue-500;
  }
}

.arrow {
  height: 10px;
  margin-left: 4px;
  width: 7px;
  vertical-align: initial;
}

.desc {
  color: $gray-600;
  font-size: 14px;
  line-height: 1.71;
}

@include media('<desktop') {
  .title {
    display: block;
    font-size: 15px;
    font-weight: 700;
    margin-left: 0;
    margin-top: 12px;
  }
}

@include media('>=tablet', '<desktop') {
  .container {
    margin-right: 56px;
    width: 240px;
  }

  .header {
    margin-bottom: 8px;
  }
}

@include media('>=phone', '<tablet') {
  .container {
    display: block;
    margin-right: 0;
    margin-bottom: 34px;
    width: 100%;
  }

  .header {
    margin-bottom: 8px;
    text-align: center;
    width: 100%;
  }

  .desc {
    margin: 0 auto;
    width: 330px;
  }
}

@include media('<phone') {
  .container {
    display: block;
    margin-right: 0;
    margin-bottom: 10px;
    padding: 0 24px;
    width: 100%;
  }

  .header {
    margin-bottom: 8px;
    text-align: center;
    width: 100%;
  }

  .desc {
    height: 72px;
    width: 100%;
  }
}

