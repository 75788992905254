$box-shadow: rgba($black, 0.05);

.segment__container {
  background-color: $white;
  box-shadow: 0 0 1px 1px $box-shadow, 0 6px 12px 0 $box-shadow, 0 2px 6px 0 $box-shadow;
  border-radius: 20px;
  display: table;
  height: 40px;
  position: relative;
  padding: 0 8px;

  .segment__item {
    border-right: 1px solid $gray-80;
    display: table-cell;
    height: 100%;
    padding: 0 16px;
    position: relative;
    vertical-align: middle;
    white-space: nowrap;

    &:last-child {
      border-right: 0;
    }

    &__layer {
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}