@import 'share';
.container {
  width: 100%;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba($black, 0.1), 0 0 0 1px $gray-100;
  background-color: $white;
}

.top {
  padding: 24px 24px 8px;
  border-bottom: 1px solid $gray-200;
}

.row {
  margin-bottom: 8px;
}

.price {
  margin-bottom: 16px;
  width: 100%;
  position: relative;
}

.dormant {
  margin: 0;
  color: $red-500;
}

.dormant > strong {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 2px;
}

.dormant > p {
  margin: 0;
  font-size: 12px;
}

.priceTable {
  margin-top: 16px;
}

.share {
  position: absolute;
  right: 0;
  top: 0;
}

.bottom {
  padding: 16px 24px;
}

.customPartnerEmpty {
  padding: 10px;
}
