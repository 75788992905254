@import 'share';
.container {
  margin-bottom: 16px;
}

.agrees {
  margin-top: 16px;
  margin-bottom: 10px;
  padding: 16px 8px 16px 16px;
  border: 1px solid $gray-200;
  border-radius: 4px;
}

.row {
  margin-bottom: 24px;
  width: 100%;
}

.row:last-child {
  margin-bottom: 0;
}

.item {
  display: flex;
  align-items: center;
  width: 100%;
}

.label {
  flex: 1;
}

.subcription {
  font-size: 12px;
  color: $gray-500;
  margin-left: 28px;
  margin-top: 4px;
}

.notices {
  width: 100%
}

.notice {
  font-size: 12px;
  line-height: 1.5;
  color: $gray-500;
}

.subscriptions {
  visibility: hidden;
}

.subscriptions > input[type="checkbox"] {
  width: 0;
  height: 0;
}