@import 'share';
.review {
  display: inline-block;
  margin-left: 8px;
  vertical-align: middle;
}

.review > p {
  line-height: 1;
  padding: 0;
}

.status {
  color: #003580;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 4px;
}

.count {
  color: $gray-700;
  font-size: 10px;
}

.count > strong {
  font-weight: 600;
}