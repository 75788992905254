.OfferListCard {
  position: relative;
  line-height: 1;
  margin-top: 16px;

  &__Cover {
    display: inline-block;
    height: 156px;
    width: 100%;

    &:before {
      box-shadow: 0 0 0 1px $gray-200 inset;
      border-radius: 2px;
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      transition: all 0.2s ease;
      width: 100%;
      z-index: 1;

      @media #{ $information-mobile } {
        border-radius: 0;
        box-shadow: none;
      }
    }
    @media #{ $information-above-mobile } {
      &:hover {
        &:before {
          box-shadow: 0 0 0 1px $gray-400 inset;
        }
      }
    }

    @media #{ $information-mobile } {
      height: 128px;
      padding-bottom: 14px;
      padding-top: 16px;
    }
  }

  @media #{ $information-mobile } {
    border-bottom: 1px solid $gray-200;
    margin-top: 0;
  }

  &__Thumbnail {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 2px;
    display: inline-block;
    height: 100%;
    position: relative;
    width: 208px;

    @media #{ $information-mobile } {
      height: 96px;
      margin-right: 16px;
      width: 96px;
    }
  }

  &__LowPrice {
    background-color: $yellow-600;
    bottom: 0;
    color: $white;
    font-size: 11px;
    font-weight: 500;
    height: 26px;
    padding-top: 7px;
    position: absolute;
    right: 0;
    text-align: center;
    width: 76px;

    @media #{ $information-mobile } {
      border-radius: 2px;
      font-size: 10px;
      height: 18px;
      padding-top: 4px;
      width: 62px;
    }
  }

  &__Content {
    display: inline-block;
    height: 100%;
    padding: 20px 16px 14px 20px;
    position: relative;
    vertical-align: top;
    width: calc(100% - 208px);

    @media #{ $information-mobile } {
      padding: 0;
      width: calc(100% - 112px);
    }

    &__Category {
      color: $gray-500;
      font-size: 13px;
      font-weight: 500;
      margin-bottom: 8px;
      line-height: 1;

      @media #{ $information-mobile } {
        font-size: 11px;
        margin-bottom: 4px;
      }
    }

    &__Title {
      @include long-text-dots(2);
      color: $gray-800;
      font-size: 18px;
      font-weight: 700;
      line-height: 1.33;
      margin-bottom: 8px;
      max-height: 46px;

      @media #{ $information-mobile } {
        font-size: 14px;
        font-weight: 600;
        line-height: 1.29;
        margin-bottom: 6px;
        max-height: 36px;
      }
    }

    &__Guide {
      color: $gray-600;
      font-size: 12px;
      line-height: 1.18;

      @media #{ $information-mobile } {
        font-size: 11px;
      }

      .name {
        margin-right: 2px;
      }
    }

    &__Review {
      left: 20px;
      position: absolute;
      bottom: 14px;

      @media #{ $information-mobile } {
        bottom: 0;
        left: 0;
      }

      .count {
        color: $gray-500;
        font-size: 12px;
        line-height: 1;
        margin-left: 4px;
        vertical-align: middle;

        @media #{ $information-mobile } {
          bottom: 0;
          font-size: 11px;
        }
      }

      .Duration {
        display: inline-block;

        &__Image {
          margin-right: 3px;
          height: 12px;
          width: 12px;
        }

        &__Text {
          color: $gray-500;
          font-size: 13px;
          vertical-align: middle;
        }
      }
    }

    &__Price {
      right: 16px;
      position: absolute;
      bottom: 12px;
      text-align: right;

      @media #{ $information-mobile } {
        bottom: 0;
        right: 0;
      }

      .OriginPrice {
        color: $gray-500;
        font-size: 12px;
        margin-bottom: 4px;
        text-decoration: line-through;

        @media #{ $information-mobile } {
          bottom: 0;
          font-size: 11px;
          margin-bottom: 2px;
        }
      }

      .SaleRate {
        color: #cc3229;
        font-size: 13px;
        font-weight: 600;
        line-height: 1;
        vertical-align: middle;

        @media #{ $information-mobile } {
          display: none;
        }
      }

      .SalePrice {
        color: $gray-800;
        font-size: 18px;
        font-weight: 600;
        line-height: 1;
        margin-left: 14px;
        vertical-align: middle;

        @media #{ $information-mobile } {
          font-size: 13px;
          font-weight: 500;
        }
      }

      .NowUseIcon {
        margin-left: 4px;

        @media #{ $information-mobile } {
          height: 12px;
          margin-left: 2px;
          width: 7px;
        }
      }
    }

    &__Wishlist {
      position: absolute;
      right: 8px;
      top: 8px;
      z-index: 1;

      @media #{ $information-mobile } {
        top: 8px;
        right: 0;
      }
    }
  }
}
