@import 'share';
.container {
    width: 100%;
    height: 182px;
    margin-top: 6px;
    padding: 32px;
    border: 1px solid $gray-100;
    border-radius: 2px;
    background-color: $white;
}

.container.empty {
    height: 200px;
}

.container > h1 {
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: 600;
    color: $gray-800;
}

.container > p {
    margin-bottom: 16px;
    font-size: 13px;
    color: $gray-500;
}

.container > p.empty {
    margin-bottom: 24px;
}

.account {
    width: 100%;
    height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 1px 0 0 $gray-100;
}

.account > p {
    font-size: 14px;
    color: $gray-800;
}
