@import 'share';
.container {
  padding: 72px 91px 91px;
  border-radius: 24px;
  background-color: $gray-800;
}

.share {
  display: flex;
  justify-content: center;
  margin: 50px 0;

  .shareBtn {
    width: 320px;
    background-color: #FFD232;
    font-size: 22px;
    color: $gray-900;
  }

  .shareIcon {
    width: 22px;
    margin-right: 8px;
  }
}

@include media('<phone') {
  .container {
    padding: 11px;
    border-radius: 16px;
  }

  .share {
    padding: 0 35px;
    margin: 32px 0;

    .shareBtn {
      width: 100%;
      font-size: 16px;
    }
  }
}
