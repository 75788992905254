.Dialog {
  background-color: $white;
  border-radius: 4px;

  &.Dialog__size--s {
    width: 400px;
  }

  &.Dialog__size--m {
    width: 500px;
  }

  &.Dialog__size--l {
    width: 700px;
  }

  @media #{ $information-mobile } {
    border-radius: 0;
    display: flex;
    height: 100vh;
    flex-direction: column;
    width: 100% !important;
  }

  .DialogHeader {
    border-bottom: 1px solid $gray-200;
    display: inline-block;
    height: 72px;
    padding: 24px;
    width: 100%;

    &__title {
      color: $gray-800;
      display: inline-block;
      font-size: 20px;
      font-weight: 700;
      line-height: 1;
      margin: 0;
      @media #{ $information-mobile } {
        max-width: 223px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    button {
      float: right;

      &:last-child {
        margin-right: 24px;
      }
    }

    @media #{ $information-mobile } {
      align-items: center;
      display: flex;
      height: 50px;
      justify-content: center;
      padding: 16px 12px;
      position: fixed;
      top: 0;
      left: 0;

      button {
        margin-right: 0 !important;

        &.app-close-button {
          position: absolute;
          left: 16px;

          img {
            height: 24px;
            width: 24px;
          }
        }

        &.app-button {
          position: absolute;
          right: 16px;
        }
      }

      &__title {
        font-size: 18px;
        text-align: center;
      }
    }
  }

  .DialogBody {
    padding: 24px;
    width: 100%;
    font-size: 15px;
    &.full {
      padding: 0;
    }
    &.disable-footer {
      height: 100%;
    }

    @media #{ $information-mobile } {
      display: flex;
      height: calc(100vh - 128px);
      margin-top: 50px;
      overflow-x: hidden;
      overflow-y: scroll;
      padding: 16px;
    }
  }

  .DialogFooter {
    border-top: 1px solid $gray-200;
    display: inline-block;
    padding: 16px 24px;
    width: 100%;

    &__content {
      display: inline-block;
    }

    &__buttons {
      text-align: right;

      button {
        margin-right: 8px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    @media #{ $information-mobile } {
      display: flex;
      height: 56px;
      padding: 8px;
      position: fixed;
      bottom: 0;
      left: 0;
      background-color: $white;

      &__buttons {
        display: flex;
        flex: 1;

        button {
          display: flex;
          flex: 1;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}
