.HotelCard {
  position: relative;
  height: 308px;

  &.swiper-slide {
    height: 308px;
    @media #{ $information-mobile } {
      height: 205px;
    }
  }

  &--list {
    margin-bottom: 20px;
    margin-right: 20px;

    @media #{ $information-above-desktop } {
      &:nth-child(3n) {
        margin-right: 0;
      }
    }

    @media #{ $information-tablet } {
      width: calc((100% - 20px) / 2);
      height: 100%;

      &:nth-child(2n) {
        margin-right: 0;
      }

      .CardThumbnail {
        height: calc(((100vw - 164px) / 2) * 187 / 302);
        width: 100%;
      }
    }

    @media #{ $information-mobile } {
      margin-right: 8px;
      height: 166px;
      width: calc((100% - 8px) / 2);

      .CardThumbnail {
        height: calc(((100vw - 40px) / 2) * 98 / 140);
        width: 100%;
      }
    }
  }

  &__Body {
    display: inline-block;
    width: 100%;
  }

  &__Review {
    float: left;

    &__Block {
      display: inline-block;
      height: 28px;
      vertical-align: middle;

      &:last-child {
        margin-left: 8px;
      }
    }

    &__Score {
      background-color: $bookingcom-score;
      border-radius: 4px 4px 4px 0;
      color: $white;
      font-size: 14px;
      font-weight: 600;
      height: 28px;
      padding: 0;
      text-align: center;
      width: 28px;
      line-height: 28px;

      @media #{ $information-mobile } {
        right: 4px;
        position: absolute;
        top: 4px;
      }
    }

    &__Status {
      margin-bottom: 2px;
      color: $gray-700;
      font-size: 12px;
      font-weight: 600;
      line-height: 14px;
    }

    &__Count {
      color: $gray-500;
      font-size: 11px;
      line-height: 12px;
    }
  }

  &__Price {
    height: 28px;
    float: right;
    text-align: right;

    @media #{ $information-mobile } {
      float: left;
      text-align: left;
    }

    &__Value {
      color: $gray-800;
      font-size: 14px;
      font-weight: 600;

      @media #{ $information-mobile } {
        color: $gray-600;
        font-size: 13px;
        font-weight: normal;
      }
    }

    &__Label {
      color: $gray-500;
      font-size: 12px;
      margin-top: 4px;
    }
  }
}
