@import 'share';
.container {
  padding: 6px 0 18px;
  margin-bottom: 12px;
  border-bottom: 1px solid $gray-100;
  line-height: 36px;
  font-size: 16px;
  color: $yellow-700;

  img {
    margin-right: 5px;
    vertical-align: -6px;
  }
  @media #{ $information-mobile } {
    padding: 0;
    margin-bottom: 0;
    border-bottom: 0;
  }
}
