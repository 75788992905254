@import 'share';
.margin-zero {
  margin: 0;
}

.padding-zero {
  padding: 0;
}

.border-zero {
  border: 0;
}

.unset-min-height {
  min-height: initial;
}
