@import 'share';
.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.iconLabelWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  line-height: normal;
  color: $gray-600;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: -0.25px;

  &:hover {
    color: $gray-800;
  }

  // 일반 이미지 hover 시에 표시 안함
  &:hover img:first-of-type {
    display: none;
  }

  // hover 이미지는 hover 시에만 표시되도록 변경
  & img:last-of-type {
    display: none;
  }

  &:hover img:last-of-type {
    display: flex;
  }
}

.iconImg {
  width: 16px;
  height: 16px;
  margin-right: 6px;
}

.iconLabelDivider {
  height: 16px;
  border: 0.5px solid #d8d8d8;
  border-top-width: 0;
  border-bottom-width: 0;
  margin-left: 18px;
  margin-right: 16px;
}
