@import 'share';
.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.prev,
.page,
.next {
  composes: paddingZero borderZero from './layout.module.scss';

  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: transparent;
  font-size: 14px;
  color: $blue-400;
  transition: background-color 0.2s, color 0.2s;
}

.prev {
  margin-right: 12px;
  transform: rotate(180deg);
}

.next {
  margin-left: 12px;
}

.page {
  margin-right: 4px;

  @include media('<phone') {
    margin-right: 0;
  }
}

.prev:hover,
.page:hover,
.next:hover {
  border: 2px solid $blue-400;
}

.prev[disabled],
.next[disabled] {
  border: 0;
}

.page.active {
  background-color: $blue-400;
  color: $white;
}

.page.last {
  margin-right: 0;
}
