@import 'share';
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  background-color: #f8f9fa;
  padding: 16px 20px;
  border-radius: 8px;
  gap: 16px;
}

@include media('>=phone') {
  .wrapper {
    flex-direction: row;
  }
}

.textWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
}

.title {
  text-align: left;
  font-size: 15px;
  font-weight: 600;
  line-height: 130%;
  letter-spacing: -0.01em;
  color: #141719;
}

.description {
  text-align: left;
  font-size: 13px;
  font-weight: 500;
  line-height: 130%;
  letter-spacing: -0.02em;
  color: #141719;
}

.buttonWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  background-color: #141719;
  border-radius: 20px;
}

@include media('>=phone') {
  .buttonWrapper {
    width: 180px;
  }
}

.button {
  font-weight: 600;
  font-size: 13px;
  line-height: 138%;
  letter-spacing: -0.01em;
  color: #ffffff;
}
