@import 'share';
.container {
  position: relative;
}

.thumbnail {
  position: relative;
}

.body {
  composes: body from './CardShare.module.scss';
  height: 145px;
}

.info {
  bottom: 16px;
  left: 16px;
  line-height: 1;
  position: absolute;
}

.review {
  margin-bottom: 8px;
}

.immediately {
  bottom: 16px;
  line-height: 1;
  position: absolute;
  right: 16px;
}

.wishlist {
  right: 8px;
  position: absolute;
  top: 8px;
  z-index: 1;
}

.period {
  bottom: 0;
  left: 0;
  position: absolute;
}

@include media('<phone') {
  .body {
    height: 94px;
  }

  .period {
    display: none;
  }

  .info {
    bottom: 12px;
    left: 0;
  }

  .review {
    display: none;
  }
}
