@import 'share';
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 66px);

  .inner {
    width: 100%;
    padding: 0 16px;
  }
}

.imgBox {
  margin-bottom: 18px;
  text-align: center;

  img {
    width: 60px;
    height: 60px;
  }
}

.notice {
  margin-bottom: 24px;
  text-align: center;

  h1 {
    margin-bottom: 8px;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.4px;
  }

  p {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.4px;
    color: $gray-500;
  }
}

.btnBox {
  display: flex;
  justify-content: space-between;
  text-align: center;

  button {
    width: 50%;

    &:first-child {
      margin-right: 8px;
    }
  }
}

.linkBox {
  margin-top: 24px;
  line-height: 22px;
  letter-spacing: -0.4px;
  text-align: center;
  color: $gray-600;
  font-weight: 600;

  a, a:link {
    margin-left: 8px;
    text-decoration: underline;
    font-weight: 600;
    color: $gray-600;
  }
}

@include media('<phone') {
  .btnBox button {
    padding: 0;
  }
}