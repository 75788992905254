.switch-button {
  border-radius: 16px;
  display: inline-block;
  height: 20px;
  transition: background-color .15s ease;
  vertical-align: middle;
  width: 35px;
  

  &.large {
    height: 32px;
    width: 56px;

    .switch-button__mark {
      border-radius: 28px;
      height: 28px;
      width: 28px;
    }

    &.on .switch-button__mark {
      transform: translate(26px, 2px);
    }
  }

  &.on {
    background-color: $blue-400;
  }

  &.off {
    background-color: $gray-300;
  }

  &__mark {
    background-color: $white;
    border-radius: 16px;
    box-shadow: 0 1px 3px 0 rgba($black, .2);
    height: 16px;
    position: absolute;
    transition: transform .15s ease;
    width: 16px;
  }

  &.on &__mark {
    transform: translate(17px, 2px);
  }

  &.off &__mark {
    transform: translate(2px, 2px);
  }
}
