@import 'share';
.buttonWrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.buttonIcon {
  margin-right: 8px;
}

.buttonLabel {
  vertical-align: middle;
}

.immediately {
  margin-top: 8px;
  margin-bottom: 0;
  text-align: center;
}

.immediately > .icon {
  margin-right: 4px;
}

.immediately > .desc {
  vertical-align: middle;
  font-size: 13px;
  letter-spacing: -0.2px;
  color: $gray-500;
}

.immediately > .question {
  margin-left: 4px;
}

.wishButtonWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 4px;
}

.wishButton {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  flex: 2;
}

.selectButton {
  flex: 8;
}
