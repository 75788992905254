$bar-height: 72px;

.offer__bottom-bar {
  z-index: 100;
  transition: transform 0.2s ease;

  @include media('>=phone') {
    display: none;
  }
  @include media('<phone') {
    position: fixed;
    bottom: 0;
    left: 0;
    height: calc(constant(safe-area-inset-bottom) + #{$bar-height});
    height: calc(env(safe-area-inset-bottom) + #{$bar-height});
    width: 100%;
    -webkit-backdrop-filter: blur(10px);
    background-color: $white;
    &__heart {
      display: inline-block;
      width: 44px;
      height: 44px;
      background-color: $gray-80;
      margin: 8px;
      text-align: center;
      line-height: 44px;
      &--icon {
        width: 24px;
        height: 24px;
      }
    }
    &__button-wrapper {
      display: inline-block;
    }
    button,
    a {
      width: calc(100% - 68px);
      height: 44px;
    }
  }
}

.offer-invisible {
  transform: translateY(calc(constant(safe-area-inset-bottom) + #{$bar-height}));
  transform: translateY(calc(env(safe-area-inset-bottom) + #{$bar-height}));
}
