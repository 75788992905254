.HotelListLink {
  display: inline-block;
  height: 48px;
  padding-top: 0px;
  min-width: 160px;
  &__Label {
    margin-right: 4px;
    vertical-align: middle;
  }
}
