@import 'share';
.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button {
  composes: paddingZero borderZero from './layout.module.scss';

  width: 32px;
  height: 32px;
  border: 1px solid $blue-400 !important;
  border-radius: 24px;
  background-color: $white;
  vertical-align: middle;
  transition: background-color 0.2s, border 0.2s;
}

.button:hover {
  background-color: $blue-50;
}

.button:active {
  background-color: $blue-80;
}

.disabled.button {
  border: 1px solid $gray-300 !important;
}

.disabled.button:hover {
  background-color: $white;
}

.value {
  width: 36px;
  height: 22px;
  font-size: 16px;
  font-weight: 500;
  color: $gray-800;
  text-align: center;
  line-height: 20px;
}
