@import 'share';
.accordion {
  margin-top: 16px;
  padding: 8px 0;
  border: 1px solid $gray-200;
  border-radius: 5px;
}

.active {
  .accordionTitle > button {
    transform: rotate(180deg);
  }
  .accordionContent {
    max-height: 300px;
  }
}

.accordionTitle {
  display: flex;
  height: 52px;
  justify-content: space-between;
  padding: 0 16px;

  // custom checkbox
  & > div {
    width: 90%;
  }

  & > button {
    width: 10%;
    border: 0;
    background: none;
    transition: all 0.5s ease;
  }
}

.accordionContent {
  max-height: 0px;
  overflow: hidden;
  background-color: $gray-50;
  transition: max-height 0.5s ease;

  .tableBox {
    padding: 16px 15px;

    table {
      font-size: 15px;
      font-weight: bold;
      border: 1px solid $gray-200;
      border-collapse: collapse;

      th,
      td {
        padding: 8px;
        border: 1px solid $gray-200;
        vertical-align: top;
      }

      th {
        width: 82px;
      }
    }
  }
}
