@import 'share';
.OfferCardPrice {
  position: relative;

  &__Origin {
    color: $gray-500;
    margin-right: 2px;
    text-decoration: line-through;
    vertical-align: bottom;

    @media #{ $information-mobile } {
      display: none;
    }

    .Price {
      font-size: 12px;
    }

    .Unit {
      font-size: 11px;
    }
  }

  &__Main {
    color: $gray-600;
    font-weight: 500;
    vertical-align: middle;

    .Price {
      font-size: 14px;
      @media #{ $information-mobile } {
        font-size: 13px;
      }
    }

    .Unit {
      font-size: 13px;
      @media #{ $information-mobile } {
        font-size: 12px;
      }
    }

    .Standard {
      color: $gray-500;
      font-size: 12px;
      @media #{ $information-mobile } {
        display: none;
      }
    }
  }

  &__NowUse {
    position: absolute;
    right: 0;
    top: 0;

    @media #{ $information-mobile } {
      display: inline-block;
      margin-left: 4px;
      position: relative;
    }
  }
}