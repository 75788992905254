@import 'share';
.container {
  box-shadow: inset 0 0 0 1px rgba($black, 0.16);
  display: block;
  height: 290px;
  margin-bottom: 20px;
  padding: 1px;
  position: relative;
  width: 100%;
  border-radius: 4px;
}

.thumbnail {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 96px;
  height: 160px;
  width: 100%;
}

.thumbnail.insurance {
  background-color: #e6ecf1;
}

.thumbnail.shilladfs {
  background-color: #fae8e7;
}

.thumbnail.ssgdfs {
  background-color: #ffefe2;
}

.thumbnail.wifi {
  background-color: #f2f1ff;
}

.thumbnail.rent {
  background-color: #edfbed;
}

.thumbnail.myvisa {
  background-color: $blue-80;
}

.thumbnail.usim {
  background-color: $blue-100;
}

.body {
  height: calc(100% - 160px);
  padding: 15px;
  position: relative;
}

.name {
  color: $gray-800;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 4px;
}

.description {
  color: $gray-600;
  font-size: 13px;
  font-weight: 500;
}

.logo {
  bottom: 16px;
  left: 16px;
  position: absolute;
}

.logo.insurance {
  bottom: 14px;
  width: 84px;
}

.logo.shilladfs {
  width: 172px;
}

.logo.ssgdfs {
  width: 76px;
}

.logo.wifi {
  width: 76px;
}

.logo.rent {
  width: 120px;
}

.logo.myvisa {
  width: 65px;
}

.logo.usim {
  width: 85px;
}

.arrow {
  display: none;
}

@include media('>=phone', '<desktop') {
  .container {
    height: 306px;
  }
}

@include media('<phone') {
  .container {
    box-shadow: none;
    border-bottom: 1px solid $gray-100;
    display: flex;
    height: 74px;
    margin-bottom: 0;
  }

  .thumbnail {
    background-color: $white !important;
    background-size: 30px;
    height: 100%;
    width: 72px;
  }

  .logo {
    display: none;
  }

  .body {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: auto;
    justify-content: center;
    padding: 0 30px 0 0;
  }

  .arrow {
    display: inline;
    position: absolute;
    right: 8px;
    top: 20px;
  }
}