@import 'share';
.container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
}

.title {
  color: $gray-700;
  line-height: 1.43;
  margin-bottom: 6px;
}

.quantity {
  color: $gray-500;
  line-height: 1.14;
}

.price {
  color: $gray-700;
  line-height: 1.14;
}
