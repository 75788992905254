@import 'share';
@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.6/dist/web/static/pretendard.css');

*,
*::before,
*::after {
  font-family: Pretendard, SF Pro KR, SF Pro Display, SF Pro Icons, -apple-system,
    BlinkMacSystemFont, Noto Sans KR, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
}

/* 2.0 server css 에서 덮어씌우는 것 방지 */
body {
  font-family: Pretendard, SF Pro KR, SF Pro Display, SF Pro Icons, -apple-system,
    BlinkMacSystemFont, Noto Sans KR, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
}
