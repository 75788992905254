@import 'share';
.container {
  position: relative;
  font-size: 12px;
  line-height: 1;
}

.name {
  color: $gray-600;
  vertical-align: middle;
}

.icon {
  height: 12px;
  margin-left: 2px;
  width: 8px;
}