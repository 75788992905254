@import 'share';
.title {
  margin: 0;
  margin-bottom: 72px;
  font-size: 38px;
  font-weight: 700;
  color: $white;
}

.body {
  width: 718px;
  height: 244px;
  border-radius: 12px;
  display: flex;
  margin: 0 auto;
  background-color: rgba($black, 0.1);
}

@include media('>=phone', '<tablet') {
  .container {
    padding: 72px 0;
  }
}

@include media('<phone') {
  .container {
    padding-top: 56px;
    padding-bottom: 42px;
  }

  .title {
    font-size: 24px;
    margin-bottom: 26px;
  }

  .body {
    flex-direction: column;
    width: 100%;
    height: 246px;
  }
}