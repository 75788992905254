@import 'share';
.container {
  width: 100%;
  min-height: 800px;
  margin-bottom: 48px;
  text-align: center;

  img {
    margin: 0 auto;
  }
}

.title {
  img {
    width: 376px;
  }
}

.hero {
  display: flex;
  flex-direction: column;
  align-content: center;
  text-align: center;
}

.heroLogo {
  margin-top: 46px;

  img {
    width: 400px;
  }
}

.heroSection {
  margin-top: 48px;

  img {
    width: 650px;
  }
}

.scroll {
  margin-top: 30px;

  img {
    width: 380px;
  }
}

@include media('<phone') {
  .container {
    min-height: 500px;
    margin-bottom: 20px;

    img {
      width: 100%;
    }
  }

  .title {
    padding: 0 67px;
  }

  .heroLogo {
    padding: 0 132px;
    margin-top: 20px;
  }

  .heroSection {
    padding: 0 13px;
    margin-top: 20px;
  }

  .scroll {
    padding: 0 45px;
    margin-top: 16px;
  }
}
