.option-popover {
  overflow-y: auto;
  &__content {
    padding: 16px;
    width: 428px;
    font-size: 14px;
    font-weight: 500;
    color: $gray-800;
    border-bottom: 1px solid $gray-100;
    @media #{$information-mobile} {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &:last-child {
      border-bottom: none;
    }
    @media #{$information-mobile} {
      width: 100%;
    }

    &--wrapper {
      width: 288px;
      display: inline-block;
      margin-right: 8px;
      vertical-align: middle;
      word-break: break-all;
      &--title {
        display: inline-block;
      }
      &--description {
        font-size: 11px;
        color: $gray-600;
        margin-top: 4px;
      }
      @media #{$information-mobile} {
        width: 180px;
      }
    }
    &--count {
      vertical-align: middle;
      display: inline-block;
      font-size: 16px;
      font-weight: 500;
      color: $gray-800;
      margin: 0px 6px;
      width: 24px;
      height: 20px;
      text-align: center;
    }
    &__icon-wrapper {
      width: 32px;
      height: 32px;
      position: relative;
      border-radius: 24px;
      background-color: $white;
      border: solid 1px $blue-400;
      display: inline-block;
      vertical-align: middle;
      text-align: center;
    }
    svg {
      display: inline-block;
      height: 100%;
      line-height: 1;
    }
  }
  &__option {
    &--wrapper {
      width: 100px;
      display: inline-block;
      vertical-align: middle;
      text-align: right;
      @media #{$information-mobile} {
        width: 125px;
      }
    }
  }
}
.option-clicked {
  &:hover {
    background-color: $blue-80;
  }
  .iconColor {
    stroke: $blue-400;
  }
}

.option-disable {
  border: solid 1px $gray-300;
  cursor: default;
}

.option-lodging--not-buyable {
  color: $gray-400 !important;
  &--title {
    display: inline-block;
    margin-right: 7px;
  }
  &--text {
    display: inline-block;
    span {
      color: $gray-500;
    }
  }
  p {
    color: $gray-400;
  }
}
