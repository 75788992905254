@import 'share';
.banner {
  display: inline-block;
  width: 100%;
}

.banner-one-wrapper {
  display: inline-block;
  width: 100%;
}

.image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 4px;
  height: 100%;
  width: 100%;
  position: relative;
  &::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    box-shadow: inset 0 0 0 1px rgba($black, 0.05);
    border-radius: 4px;
  }
}

@include media('<phone') {
  .banner-one-wrapper {
    width: calc(100% - 32px);
    margin: 0px 16px;
  }

  .banner-one {
    width: 100%;
  }

  .banner {
    width: calc(100vw - 32px) !important;
  }

  .container.banner-one {
    margin-bottom: 16px;
  }
}
