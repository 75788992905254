@import 'share';
.container {
  width: 100%;
  height: 210px;
  position: relative;
  border-radius: 4px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: block;
}

.wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 4px;
  background-color: rgba($black, 0.4);
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 48px;
}

.title {
  font-size: 24px;
  font-weight: 700;
  color: $white;
  margin-bottom: 8px;
}

.description {
  font-size: 16px;
  font-weight: 600;
  color: $white;
  margin-bottom: 16px;
}

.button {
  width: 112px;
  height: 40px;
  border-radius: 4px;
  text-align: center;
  display: block;
  padding: 10px 0; 
  font-size: 14px;
  font-weight: 600;
  color: $gray-900;
  background-color: $white;
}

@include media('<phone') {
  .container {
    height: 240px;
    border-radius: 0;
  }

  .wrapper {
    border-radius: 0;
    align-items: center;
    padding: 0;
  }
}