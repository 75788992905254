.banner-app-download {
  align-items: center;
  background-color: $white;
  box-shadow: inset 0 -1px 0 0 $gray-100;
  display: flex;
  padding: 0 12px;

  .app-image {
    border: 1px solid $gray-200;
    border-radius: 5px;
    height: 40px;
    margin-right: 12px;
    width: 40px;
  }

  .app-desc {
    color: $gray-800;
    display: flex;
    flex: 1;
    font-size: 12px;
    font-weight: 600;
    line-height: 1.33;
  }

  .close-button {
    line-height: 0;
    margin-left: 8px;
  }
}