@import 'share';
.container {
  position: absolute;
  right: 5px;
  bottom: 5px;
  display: inline-flex;
  height: 22px;
  align-items: center;
  padding: 0 5px;
  border-radius: 4px;
  background-color: $red-50;
  color: $red-500;
  font-size: 12px;
  font-weight: 700;
}

@include media('<phone') {
  .container {
    right: 0;
    bottom: 0;
  }
}
