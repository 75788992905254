@import 'share';
.container {
    width: 500px;
    height: 580px;
    background-color: $white;
    box-shadow: 0px 8px 16px rgba(33, 37, 41, 0.15);
    border-radius: 4px;
    position: relative;
}
.header {
    width: 100%;
    height: 72px;
    
    > span {
        font-size: 14px;
        font-weight: 700;
        vertical-align: top;
        letter-spacing: -0.4px;
        color: $gray-400;
        padding: 4px 6px;
        cursor: pointer;

        position: absolute;
        top: 22px;
        right: 64px;
    }
    .closeIcon {
        width: 20px;
        height: 20px;
        margin: 4px;
        cursor: pointer;

        position: absolute;
        top: 24px;
        right: 24px;
    }
}
.ieImage {
    width: 203px;
    height: 147px;
    display: block;
    margin: 0 auto;
    margin-top: 4px;
    margin-bottom: 12px;
}
.title {
    padding-bottom: 16px;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -0.6px;
    text-align: center;
}
.subtitle {
    width: 384px;
    margin: 0 auto;
    padding-top: 4px;

    font-size: 14px;
    line-height: 22px;
    vertical-align: top;
    letter-spacing: -0.6px;
    text-align: center;

    > span {
        font-weight: 700;
    }
}
.footer {
    width: 100%;
    height: 72px;
    border-top: 1px solid $gray-200;
    position: absolute;
    bottom: 0;
}
.buttonWrapper {
    position: absolute;
    bottom: 16px;
    right: 24px;

    > a {
        display: inline-block;
        min-width: 128px;
        height: 40px;
        border-radius: 4px;
        background-color: $gray-100;
        border: none;
        text-align: center;
        padding: 12px 16px;

        font-size: 14px;
        font-weight: 700;
        line-height: 14px;
        letter-spacing: -0.2px;
    }
    > a:active, a:hover, a:focus {
        text-decoration: none;
        color: inherit;
    }
}
.edge {
    margin-right: 8px;
}
.icon {
    width: 16px;
    height: 16px;
    margin-right: 4px;
}