@import 'share';
.container {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 32px;
}

.container > span {
  display: inline-block;
  overflow: hidden;
}

.container.header > span {
  font-size: 16px;
  color: $gray-400;
  font-weight: 700;
}

.rank {
  font-size: 14px;
  font-weight: 700;
  color: $gray-900;
  width: 40px;
  margin-right: 20px;
}

.id {
  font-size: 18px;
  font-weight: 500;
  color: $gray-900;
  flex: 1;
  text-align: left;
  margin-right: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.friend {
  font-size: 18px;
  font-weight: 600;
  color: $gray-800;
  width: 60px;
  text-align: left;
  margin-right: 30px;
}

.point {
  font-size: 18px;
  font-weight: 700;
  color: $blue-500;
  flex: 1;
  text-align: left;
}

@include media('<phone') {
  .container {
    margin-bottom: 24px;
  }

  .container.header > span {
    font-size: 13px;
  }

  .rank {
    font-size: 11.5px;
    width: 28px;
    margin-right: 16px;
  }

  .id {
    font-size: 14.7px;
    margin-right: 16px;
  }

  .friend {
    font-size: 14.7px;
    width: 64px;
    margin-right: 16px;
  }

  .point {
    font-size: 14.7px;
  }
}