@import 'share';
.container {
  box-shadow: 0 0 0 1px $gray-100;
  background-color: $white;
  padding: 0 20px;
  margin-bottom: 16px;
  width: 100%;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: -0.17px;
  color: $gray-700;
}

.row > strong {
  font-size: 18px;
}

.row > .warning {
  font-size: 13px;
  color: $red-400;
  line-height: 1.38;
}

.notifyBox {
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 15px 15px 15px 60px;
  margin-bottom: 30px;
  border: 1px solid $gray-200;
  border-radius: 4px;
  font-size: 15px;

  img {
    position: absolute;
    top: 16px;
    left: 16px;
  }
  strong {
    display: block;
    margin-bottom: 10px;
    font-size: 16px;
    letter-spacing: -1px;
  }
}

@include media('<phone') {
  .container {
    margin-bottom: 8px;
  }
}
