@import 'share';
.container {
  padding-top: 80px;
  padding-bottom: 140px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 40px;
  color: $gray-800;
}

.box {
  width: 440px;
  border: 1px solid $gray-200;
  border-radius: 2px;
  padding: 48px;
}

@include media('<phone') {
  .container {
    padding: 32px 24px 24px 24px;
  }

  .box {
    width: 100%;
    border: 0;
    padding: 0;
  }
}