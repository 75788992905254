@import 'share';
.body {
  margin-top: 20px;
  text-align: center;

  p {
    margin: 0;
  }
}

.icon {
  width: 80px;
  height: 80px;
  margin-bottom: 16px;
}

.body > .description {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 12px;
  line-height: normal;
  letter-spacing: -0.83px;
  white-space: nowrap;
}

.description > .prefix {
  color: $gray-800;
  margin-bottom: 4px;
}

.description > .suffix {
  color: $blue-500;
}

.caution {
  font-size: 14px;
  color: $gray-400;
  line-height: 1.57;
}