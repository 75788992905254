@import 'share';
.container {
  width: 100%;
  margin-bottom: 8px;
}

.name {
  font-size: 14px;
  font-weight: 700;
  line-height: 1.14;
  letter-spacing: -0.4px;
  color: $gray-500;
  vertical-align: middle;
}

.item {
  margin: 0 -4px;
}

.item:nth-child(3) {
  margin: 0 -6px;

  .name {
    line-height: normal;
  }
}

.arrow {
  width: 16px;
  height: 16px;
  margin-left: 2px;
}

.location {
  width: 16px;
  height: 16px;
  margin-right: 2px;
}
