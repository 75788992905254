@import 'share';
.container {
  width: 100%;
  padding: 16px;
  border-radius: 4px;
  border: 1px solid $gray-200;
  display: flex;
}

.body {
  flex: 1;
  word-break: break-word;
}

.title {
  margin: 0;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.38;
  letter-spacing: -0.3px;
  color: $gray-800;
}

.content {
  margin-top: 4px;
  font-size: 15px;
  line-height: 1.6;
  letter-spacing: -0.3px;
  color: $gray-800;
  word-break: break-all;
}

.content > strong,
.content > b {
  font-weight: 600;
}
