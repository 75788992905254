.with-more {
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  line-height: 25px;
  &--text {
    text-align: left;
    margin-top: 12px;
    font-size: 14px;
    font-weight: bold;

    span {
      transition: color 0.075s ease-out;
      font-size: 15px;
      color: $blue-500;
      &:hover {
        color: $blue-800;
      }
    }
  }
  p {
    margin: 0 0;
  }
  &--arrow-icon {
    width: 16px;
    height: 16px;
    margin-left: 2px;
  }
  &--gradient {
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    height: 184px;
    background: -moz-linear-gradient(
      top,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 50%,
      rgba(255, 255, 255, 1) 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 50%,
      rgba(255, 255, 255, 1) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 50%,
      rgba(255, 255, 255, 1) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    text-align: center;

    & > button {
      position: absolute;
      bottom: 32px;
      padding: 16px 40px;
      line-height: 16px;
      @include media('<phone') {
        width: 100%;
        left: 0;
      }
      @include media('>=phone') {
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0%);
      }
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
    }
  }
}

.more-clicked {
  max-height: none;
  overflow: none;
}

.more-visible {
  max-height: none;
  overflow: hidden;
}
