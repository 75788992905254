@import 'share';
.CardSwiperArrow {
  height: 43px;
  position: absolute;
  width: 20px;
  top: 50%;
  transform: translate(0, calc(50% - 43px));

  @media #{ $information-tablet } {
    display: none;
  }

  &--prev {
    @media #{ $information-above-tablet } {
      left: -36px;
    }

    @media #{ $information-above-desktop } {
      left: -52px;
    }
  }

  &--next {
    @media #{ $information-above-tablet } {
      right: -36px;
    }

    @media #{ $information-above-desktop } {
      right: -52px;
    }
  }
}