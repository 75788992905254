@import 'share';
.container {
  width: 100%;
  margin-top: 24px;
}

.container.noMargin {
  margin-top: 0;
}

.container:first-child {
  margin-top: 0;
}

.title {
  font-size: 17px;
  font-weight: 700;
  line-height: 1.41;
  letter-spacing: -0.28px;
  color: $gray-800;
  margin-bottom: 8px;
}

.contents {
  font-size: 15px;
  line-height: 1.6;
  letter-spacing: -0.3px;
  color: $gray-800;
}

@include media('<phone') {
  .title {
    font-size: 16px;
    line-height: 1.38;
    letter-spacing: -0.4px;
  }
}
