@import '~app/styles/share';

//메인 영역
.offer-container {
  &__view-together {
    width: 708px;
    margin: 0 auto;
    position: relative;

    .swiper-slide {
      margin-right: 12px;
    }

    @include media('>=phone') {
      .swiper-slide {
        width: 224px !important;
      }
    }
    @include media('<phone') {
      width: 100vw;
      margin: 0px -16px;
      & > h5 {
        padding-left: 16px;
      }
    }
    h5 {
      font-size: 24px;
      font-weight: bold;
    }
    & > div {
      width: 100% !important;
      margin-top: 16px !important;
    }
  }

  //special
  &__special {
    margin-bottom: 16px;
    font-size: 12px;
    &--price-guarantee {
      display: inline-block;
      width: 80px;
      height: 26px;
      background-color: #ff9b01;
      margin-right: 4px;
      color: white;
      text-align: center;
      line-height: 26px;
    }
    &--ignore-wait {
      display: inline-block;
      width: 60px;
      height: 26px;
      background-color: $green-800;
      color: white;
      text-align: center;
      line-height: 26px;
    }
  }
  //main
  &__main {
    width: 700px;
    margin-top: 48px;
    // @media #{$information-only-desktop} {
    //   width: 612px;
    // }
    // @media #{$information-only-tablet} {
    //   margin-top: 24px;
    //   width: 100%;
    // }
    @media #{$information-mobile} {
      margin-top: 24px;
      width: 100%;
    }
    iframe {
      height: 442px;
      width: 100% !important;
      @media #{$information-only-tablet} {
        height: 442px;
      }
      @media #{$information-mobile} {
        height: 241px;
      }

      &.youtube {
        padding-top: 24px;
        padding-bottom: 24px;
      }
    }
  }
  &__video {
    margin-bottom: 24px;

    @media #{$information-mobile} {
      margin-left: -16px;
      margin-right: -16px;
    }
  }

  &__title {
    font-size: 32px;
    font-weight: bold;
    line-height: 1.25;
    color: $gray-800;
    margin-bottom: 8px;
    @media #{$information-only-desktop} {
      width: 612px;
    }
    @media #{$information-only-tablet} {
      width: 100%;
    }
    @media #{$information-mobile} {
      font-size: 22px;
    }
  }

  //phto영역
  &__photo {
    &--grid {
      display: grid !important;
      width: 100%;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(1, 1fr);
      column-gap: 5px;
      @include media('<phone') {
        column-gap: 3px;
      }
      & > div {
        width: 100%;
      }
    }
    @media #{$information-mobile} {
      margin: 24px -16px;
      width: calc(100% + 32px) !important;
      &--review {
        width: 100% !important;
        margin: 0;
      }
    }

    &--cover {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: inherit;
      transition: all 0.2s ease-out;
      border-radius: 2px;

      @media #{$information-above-mobile} {
        &:hover {
          background-color: rgba(0, 0, 0, 0.3);
          z-index: 5;
        }
      }
      @media #{$information-mobile} {
        &:active {
          background-color: rgba(0, 0, 0, 0.3);
          z-index: 5;
        }
      }
    }
    &--view {
      cursor: pointer;
      @include media('<tablet') {
        ul > li:first-child {
          width: 100%;
        }
      }
    }
    width: 700px;

    @media #{$information-mobile} {
      width: 100%;
    }

    &--title {
      font-size: 24px;
      font-weight: bold;
      color: $gray-800;
      margin-bottom: 16px;
      @media #{$information-mobile} {
        font-size: 22px;
      }
    }
    li {
      display: inline-block;
      @media #{$information-mobile} {
        display: inline;
      }
    }

    &--zoom {
      position: absolute;
      right: 0;
      top: 0;
      width: 96px;
      height: 32px;
      line-height: 32px;
      opacity: 0.9;
      border-radius: 2px;
      background-color: $white;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
      margin-top: 8px;
      margin-right: 8px;
      color: $gray-700;
      font-size: 14px;
      text-align: center;

      &:hover {
        opacity: 1;
      }
    }

    &--zoom-icon {
      margin-right: 4px;
    }

    &--zoom-text {
      display: inline-block;
      vertical-align: middle;
    }

    &--big {
      width: 700px;
      height: 467px;
      background-size: cover;
      margin-bottom: 5px;
      position: relative;

      @media #{$information-mobile} {
        width: 100%;
        height: 66vw;
        margin-bottom: 0.5%;
      }
    }

    &--small {
      width: 100%;
      height: calc((230px) * 0.75);
      background-size: cover;
      position: relative;
      border-radius: 2px;

      &:hover {
        background-color: black opacity 30%;
      }

      @media #{$information-mobile} {
        width: 33%;
        height: calc(33vw * 0.75);
        display: inline-block;
        margin-right: 0.5%;
      }
      &__last {
        width: inherit;
        height: inherit;
      }
    }
  }
  //제목 하단 상세 바
  &__inner-bar {
    line-height: 1;
    margin-bottom: 32px;
    @media #{$information-only-tablet} {
      height: 29px;
      line-height: 29px;
    }
    @media #{$information-mobile} {
      display: flex;
      margin-bottom: 24px;
      align-items: center;
      justify-content: space-between;
    }
    img {
      display: inline-block;
      vertical-align: middle;
      @media #{$information-mobile} {
        height: inherit;
      }
    }
    &--sub-wrapper {
      display: inline-block;
      @media #{$information-mobile} {
        display: block;
        margin-bottom: 8px;
      }
    }
    &--wrapper {
      display: inline-block;
    }
    &--country {
      @include vertical-middle();
      font-size: 14px;
      color: $gray-500;
      @media #{$information-mobile} {
        height: inherit;
      }
      a {
        transition: color 0.075s ease-out;
        &:hover {
          color: $blue-500;
        }
      }
    }

    &__discount-box {
      display: inline-block;
      &--main-price {
        display: inline-block;
        font-size: 13px;
        font-weight: 500;
        color: $gray-500;
        margin-right: 4px;
        text-decoration: line-through;
      }
      &--percent {
        display: inline-block;
        color: $red-700;
        font-size: 13px;
        font-weight: 500;
        margin-right: 1px;
      }
      &--icon {
        width: 10px;
        height: 10px;
      }
    }

    &__price {
      width: 50%;
      float: right;
      display: inline-block;
      @media #{$information-mobile} {
        text-align: right;
      }
      &--content {
        float: right;
      }
      &--person {
        font-size: 13px;
        color: $gray-500;
        display: inline-block;
      }
      &--main {
        display: inline-block;
        font-weight: bold !important;
        font-size: 24px;
        color: $gray-800;
        @media #{$information-mobile} {
          font-size: 16px !important;
        }
      }
      &--mobile-wrapper {
        display: inline-block;
        @media #{$information-mobile} {
          margin-top: 8px;
          display: block;
        }
      }
    }

    &--city {
      @include vertical-middle();
      margin-left: 4px;
      margin-right: 16px;
      font-size: 14px;
      color: $gray-500;
      @media #{$information-mobile} {
        height: inherit;
        margin-right: 0;
      }
      a {
        transition: color 0.075s ease-out;
        &:hover {
          color: $blue-500;
        }
      }
    }
    &__icon {
      width: 16px;
      height: 16px;
      object-fit: contain;
    }
    &--location-icon {
      width: 10px;
      height: 10px;
    }

    &--review {
      @include vertical-middle();
      font-size: 14px;
      margin-left: 4px;
      color: $gray-600;
      transition: color 0.075s ease-out;
      @media #{$information-mobile} {
        height: inherit;
      }
      &:hover {
        color: $blue-500;
      }
      &--event {
        transition: color 0.075s ease-out;
        display: inline-block;
        font-size: 14px;
        color: $blue-500 !important;
        vertical-align: middle;
        &:hover {
          color: $blue-800;
        }
        @media #{$information-mobile} {
          // margin-top: 8px;
        }
      }
      &--event-icon {
        width: 16px;
        height: 16px;
        margin-right: 4px;
        @media #{$information-mobile} {
          // margin-top: 8px;
        }
      }
    }
  }

  //price section
  &__price {
    position: relative;
    @media #{$information-mobile} {
      display: none;
    }

    &--extra-title {
      margin-bottom: 8px;
      font-size: 14px;
      font-weight: 600;
    }

    &__line {
      background-color: $gray-100;
      margin: 16px 0px;
    }

    &__select-wrapper {
      position: relative;
      width: 257px;
      margin-right: 8px;
      background-color: $gray-60;
      border-radius: 4px;
    }

    &__calendar-info {
      font-size: 14px;
      color: #ff7a66;
    }

    &__wrapper {
      position: relative;
      width: 100%;
    }

    &__option {
      width: 100%;
      display: flex;
    }

    &__select-description {
      margin-right: 20px;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 12px;
      color: $gray-800;
      display: inline-block;
    }

    &__question {
      &__wrapper {
        display: inline-block;
        position: absolute;
        top: -32px;
        right: 0;
      }
      &--icon {
        width: 16px;
        height: 16px;
      }
      &--description {
        display: inline;
        font-size: 12px;
        font-weight: bold;
        text-align: right;
        color: $blue-400;
        margin-right: 4px;
        vertical-align: middle;
        transition: color 0.075s ease-out;
        &:hover {
          color: $blue-800;
        }
      }
    }
    &__option-loading {
      display: inline-block;
      width: 250px;
      text-align: center;
      @media #{$information-only-desktop} {
        width: 217px;
      }
      @media #{$information-only-tablet} {
        width: 222px;
        margin-right: 20px;
      }
    }

    &__result-loading {
      margin: 16px 0px;
      text-align: center;
    }

    &__select {
      vertical-align: middle;
      display: inline-block;
      width: 100%;
      font-size: 16px;
      font-weight: 500;
      color: $gray-800;
      text-align: left;
      height: 48px;
      line-height: 1;
      padding: 16px 14px 16px 38px;
      background-repeat: no-repeat;
      background-size: 16px 16px;
      background-position: left 14px top 16px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      transition: all 0.1s ease-out;
      border-radius: 4px;
      background-color: $gray-60;
      border: 0;

      &:hover {
        box-shadow: 0 0 0 1px $gray-500;
      }
      p {
        margin-bottom: 0px;
      }

      & .date-for-lodge {
        display: inline-block;
        font-size: 14px;
      }
      & .date-for-not-lodge {
        display: inline-block;
      }
      &__icon {
        width: 16px;
        height: 16px;
        margin-right: 8px;
      }
      &__label {
        vertical-align: middle;
      }

      &_option {
        padding: 16px 14px;

        &--icon {
          margin-right: 8px;
        }

        &--label {
          vertical-align: middle;
        }
      }
    }

    &__datepicker {
      padding: 16px 14px;
    }
    &__result {
      display: inline-block;
      width: 160px;
      height: 48px;
      opacity: 0.9;
      border-radius: 2px;
      background-color: $blue-80;
      font-size: 16px;
      font-weight: bold;

      text-align: center;
      color: $blue-500;
      line-height: 48px;
      @media #{$information-only-desktop} {
        width: 138px;
      }
      @media #{$information-only-tablet} {
        width: 140px;
      }
    }
    &__total-result {
      border-radius: 2px;
      background-color: $gray-50;
      padding: 16px 16px;
      margin: 16px 0px;
      &--extra {
        border-radius: 2px;
        border: solid 1px $gray-300;
        background-color: $white;
        font-size: 16px;
        color: $gray-700;
        padding: 16px;
        margin-bottom: 12px;
        &--refund {
          color: #848c94;
          font-size: 13px;
        }
        &--icon {
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
        }

        &--selected {
          border: solid 2px $blue-500;
          position: relative;
        }

        &--description {
          font-size: 13px;
          font-weight: 400;
          margin-top: 8px;
          color: $blue-500;
          display: block;
          max-width: 500px;
          @media #{$information-mobile} {
            width: 100%;
          }
        }
      }
      &--link {
        width: 100%;
        text-align: right;
      }

      &--notice {
        font-size: 15px;
        color: $gray-800;
        margin-bottom: 8px;
        font-weight: 700;
      }

      &--title {
        text-align: left;
        font-size: 14px;
        font-weight: 600;
        color: $gray-600;
        display: inline-block;
        max-width: 400px;
        word-break: break-all;
        @media #{$information-only-desktop} {
          max-width: 355px;
        }
        @media #{$information-only-tablet} {
          width: 365px;
        }
      }

      &--sub-price {
        font-size: 12px;
        color: $gray-500;
        display: inline-block;
        margin-right: 32px;
      }
      &--price {
        font-size: 14px;
        color: $gray-800;
        display: inline-block;
      }
      &--price-wrapper {
        display: inline-block;
        text-align: right;
        float: right;
        @media #{$information-mobile} {
          display: block;
        }
      }
      &--total-price {
        color: $blue-500;
        display: inline-block;
        font-size: 20px;
        margin-left: 16px;
      }
      &--total-price-description {
        text-align: right;
        font-size: 14px;
        font-weight: bold;
        color: $gray-800;
      }
    }
    &__option-wrapper {
      position: relative;
      display: inline-block;
      &--disabled {
        pointer-events: none;
        opacity: 0.4;
      }
      select {
        cursor: pointer;
        padding-left: 44px;
      }
    }
  }

  //introduction section
  &__introduction {
    position: relative;

    &--wrapper {
      position: relative;
    }

    &__subtitle {
      font-size: 24px;
      font-weight: bold;
      line-height: 1.38;
      color: $gray-800;
      @media #{$information-mobile} {
        font-size: 22px;
      }
    }
    &__detail {
      font-size: 15px;
      font-weight: normal;
      line-height: 1.67;
      position: relative;
      color: $gray-800;
      margin: 16px 0px;
      @include media('<phone') {
        margin: 24px 0px;
      }
    }
    &__image {
      width: 100%;
    }
  }

  &__notice {
    padding-left: 52px;
    padding-right: 16px;
    padding-top: 24px;
    padding-bottom: 24px;
    background-color: $yellow-50;
    position: relative;

    &--icon {
      position: absolute;
      top: 24px;
      left: 24px;
    }
    &--title {
      font-weight: 500;
      color: $gray-700;
      font-size: 18px;
    }
    &--content {
      line-height: 1.67;
      color: $gray-700;
      font-size: 15px;
      margin-top: 4px;
      word-break: break-all;
    }
  }

  //미니 후기
  &__mini-review {
    &--left {
      display: inline-block;
      width: 64px;
      margin-right: 26px;
      vertical-align: top;
      @media #{$information-mobile} {
        display: flex;
        width: 100%;
      }
      p {
        display: inline-block;
      }
    }
    &--right {
      display: inline-block;
      width: 610px;

      @media #{$information-mobile} {
        width: 100%;
        margin-top: 8px;
      }
    }
    &--star {
      display: inline-block;
      margin-top: 4px;
    }
    &__score {
      font-size: 32px;
      font-weight: bold;
      line-height: 1;
      color: $gray-800;
      margin-right: 8px;
    }
    &__sub {
      margin-top: 4px;
      @media #{$information-mobile} {
        display: flex;
        flex-direction: column;
      }
    }
    &__review-count {
      font-size: 12px;
      color: $gray-500;
      margin-top: 2px;
    }
    &__content {
      margin: 8px 0px 16px;
      font-size: 15px;
      color: $gray-800;
      max-height: 75px;
      text-overflow: ellipsis;
      overflow: hidden;
      line-height: 25px;
    }
    &--user {
      color: $gray-800;
      font-size: 14px;
      font-weight: bold;
      display: inline-block;
      margin-left: 6px;
    }
    &--stats {
      color: $gray-500;
      font-size: 12px;
      margin-top: 4px;
      @media #{$information-mobile} {
        margin-top: 8px;
      }
    }
    &__show-review {
      font-size: 14px;
      font-weight: bold;
      color: #2b96ed;
      display: inline-block;
      transition: color 0.075s ease-out;
      vertical-align: middle;
      &:hover {
        color: $blue-800;
      }
    }
    &__arrow-icon {
      width: 16px;
      height: 16px;
    }
  }

  //포함, 불포함
  &__service {
    &--header {
      font-size: 18px;
      font-weight: bold;
      color: $gray-800;
      margin-bottom: 8px;
      @media #{$information-mobile} {
        font-size: 16px;
      }
    }

    &--price {
      margin: 16px 0px;
      h5 {
        @media #{$information-only-tablet} {
          font-size: 18px;
        }
        color: $gray-800;
        font-size: 16px;
        font-weight: bold;
      }
      table {
        width: 100%;
      }
      @media #{$information-only-tablet} {
        margin-top: 24px !important;
        margin-bottom: 0px !important;
      }
      @media #{$information-mobile} {
        margin-top: 24px !important;
        margin-bottom: 0px !important;
      }
    }
    &--content {
      font-size: 15px;
      line-height: 1.67;
      color: $gray-800;
      margin-bottom: 24px !important;
    }
  }

  &__meeting {
    h2 {
      font-size: 24px;
      font-weight: bold;
      line-height: 1.38;
      color: $gray-800;
      @media #{$information-mobile} {
        font-size: 22px;
      }
    }
    &__map {
      width: 70px;
      height: 70px;
    }
    &__time {
      h5 {
        font-size: 15px;
        line-height: 1.67;
        color: $gray-800;
      }
    }
    &__area {
      &__detail {
        width: 610px;
        vertical-align: top;
        display: inline-block;
        margin-left: 20px;
        @media #{$information-only-desktop} {
          width: 522px;
        }
        @media #{$information-only-tablet} {
          width: 514px;
        }
        @media #{$information-mobile} {
          width: calc(100% - 90px);
        }
      }
      &__title {
        margin-bottom: 16px;
      }
      &__content {
        font-size: 18px;
        font-weight: bold;
        color: $gray-800;
      }
    }
    &__show-map {
      font-size: 14px;
      font-weight: bold;
      color: $blue-500;
      cursor: pointer;
      transition: color 0.075s ease-out;
      &:hover {
        color: $blue-800;
      }
      img {
        width: 16px;
        height: 16px;
      }
    }
    &__photo {
      width: 304px;
      height: 194px;
      display: inline-block;
      margin: 8px 0;

      @media #{$information-mobile} {
        width: 100%;
        height: calc(100% * 0.4);
      }
    }
  }
  &__offer-num {
    font-size: 14px;
    color: black;
    background-color: white;
    text-align: center;
    border-top: 1px solid $gray-100;
    &--content {
      padding: 40px;
      font-size: 16px;
      font-weight: bold;
      span {
        color: $blue-500;
      }
    }
  }
  &__recommend-hotel-ink {
    margin-top: 40px;
    text-align: center;
    @media #{ $information-mobile } {
      margin-top: 16px;
    }
  }
}

.last-image {
  position: relative;
  display: inline-block;
  margin: 0;
  &:hover {
    p {
      opacity: 1;
    }
  }
  &__cover {
    position: absolute;
    top: 0;
    width: 100%;
    height: inherit;
    background-color: rgba(0, 0, 0, 0.75);
    @media #{$information-only-desktop} {
      height: inherit;
    }
    @media #{$information-only-tablet} {
      height: inherit;
    }
    @media #{$information-mobile} {
      height: inherit;
    }
  }
  &__count {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    width: 50%;
    text-align: center;
    height: 30%;
    opacity: 0.75;
    font-size: 36px;
    font-weight: bold;
    color: white;
    @media #{$information-mobile} {
      font-size: 24px;
    }
  }
}

.offer-webview__price {
  position: relative;
  padding-top: 42px;

  .offer-popover-price-single-datepicker {
    width: 100%;
    border-radius: 8px;
    border: 1px solid #e9ecef;
    background: #fff;

    box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.1), 0px 1px 2px 0px rgba(0, 0, 0, 0.05);

    &.Popover.bottom-left {
      top: calc(100% + 12px);
    }
  }

  .offer-container__price__select {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 44px;
    padding: 0 16px;
    border: 1px solid #e9ecef;
    border-radius: 1px;
    background-color: #fff;
    font-size: 14px;

    &:hover {
      box-shadow: none;
    }
  }
}
