@import 'share';
.container {
  height: 100%;
  position: relative;
  width: 100%;
}

.button {
  background-color: #ffffff;
  border: 0;
  border-radius: 50%;
  box-shadow: 0 0 0 1px rgba($black, 0.05), 0 2px 6px 0 rgba($black, 0.05), 0 4px 12px 0 rgba($black, 0.05);
  height: 40px;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  z-index: 10;
  transition: 0.2s;

  &:hover {
    box-shadow: 0 0 0 1px rgba($black, 0.15), 0 2px 6px 0 rgba($black, 0.05), 0 8px 12px 0 rgba($black, 0.2);
  }

  @include media('<phone') {
    display: none;
  }
}

.disabled {
  display: none;
}

.prev {
  left: -20px;
}

.next {
  right: -20px;
}
