@import 'share';
.container {
  position: sticky;
  top: 80px;
  width: 100%;
}

.row {
  width: 100%;
  margin-bottom: 16px;
}

.row:last-child {
  margin-bottom: 0;
}
