@import 'share';
.container {
  margin-bottom: 56px;
}

@include media('<phone') {
  .container {
    margin-bottom: 0;
    padding-bottom: 40px;
  }
}
