@import 'share';
.container {
  width: 100%;
  margin-bottom: 24px;
}

.row {
  width: 100%;
  margin-bottom: 16px;
}

.row:last-child {
  margin-bottom: 0;
}