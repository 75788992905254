@import 'share';
.container {
  position: relative;
}

.container.load {
  height: 280px;
}

.image, .placeholder {
  border-radius: 4px;
  opacity: 0;
  transition: opacity .5s;
}

.image {
  width: 100%;
}

.image.visible, .placeholder.visible {
  opacity: 1,
}

.placeholder {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: $gray-80;
  background-size: 32px 24px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('../../images/icons/img-placeholder-thumnail.svg');
}

@include media('>=tablet', '<desktop') {
  .container.load {
    height: 245px;
  }
}

@include media('>=phone', '<tablet') {
  .container.load {
    height: 162px;
  }
}

@include media('<phone') {
  .container.load {
    height: 160px;
  }
}

