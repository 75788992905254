@import 'share';
.buttonContainer {
  width: 100%;
  margin-top: 24px;
  color: $white;

  &:hover {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
  }
}

.buttonContent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .logo {
    width: 24px;
    height: 24px;
    margin-right: 4px;
    object-fit: contain;
  }
}

.mrtEmail {
  background-color: $blue-400;

  .text {
    color: $white;
  }
}

.kakao {
  background-color: $kakao;

  .text {
    color: $kakao-brown;
  }
}

.naver {
  background-color: $naver;
}

.facebook {
  background-color: $facebook;
}

.join {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  font-size: 14px;
  color: $gray-500;
  line-height: 28px;

  .joinButton {
    margin-left: 4px;
    padding: 0;
    border: 0;
    background: none;
    text-decoration: underline;

    &:hover {
      color: $blue-500;
    }
  }
}
