@import 'share';
.button {
  border: 1px solid $blue-500;
  height: 58px;
  height: auto;
  margin-bottom: 6px;

  &:last-child {
    margin-bottom: 0;
  }
}

.container {
  width: 100%;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 20px;
}

.label {
  flex: 1;
  text-align: left;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.22px;
  color: $blue-500;
  word-break: keep-all;
}

.icon {
  width: 24px;
  height: 24px;
}

.button.loading:disabled {
  border: 1px solid $blue-500;
}

.button:disabled:not(.loading) {
  border: 0;
  background-color: $gray-80;

  .label {
    color: $gray-400;
  }
}
