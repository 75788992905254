@import 'share';
.container {
  overflow: hidden;
}

.container.swiper {
  white-space: nowrap;

  @include media('<phone') {
    padding-left: 16px !important;
  }
}

.card {
  border-radius: 2px;
  box-shadow: inset 0 0 0 1px rgba($gray-800, 0.1);
  display: inline-block;
  height: 332px;
  position: relative;
  width: 250px;

  @include media('<phone') {
    box-shadow: none;
    height: 205px;
    width: 170px;
  }
}

.list > .card {
  margin-bottom: 20px;
  margin-right: 20px;

  @include media('>=phone') {
    &:nth-child(4n) {
      margin-right: 0;
    }
  }

  @include media('<phone') {
    &:nth-child(n + 7) {
      display: none;
    }
  }
}

.swiper > .card {
  margin-right: 20px;

  &:last-child {
    margin-right: 0;
  }
  @include media('<phone') {
    margin-right: 8px;
  }
}

.thumbnail {
  height: 186px;
  width: 100%;

  @include media('<phone') {
    height: 120px;
  }
}

.content {
  height: 146px;
  padding: 8px 16px 12px 16px;
  position: relative;
  width: 100%;

  @include media('<phone') {
    padding: 0;
    padding-top: 8px;
  }
}

.list .content {
  padding: 26px 16px 18px 16px;
}

.category {
  height: 12px;
  margin-bottom: 10px;
  width: 48px;
}

.title {
  height: 12px;
  margin-bottom: 10px;
  width: 178px;

  @include media('<phone') {
    width: 160px;
  }
}

.guide {
  height: 12px;
  width: 80px;
}

.bottom {
  bottom: 16px;
  display: inline-block;
  left: 0;
  padding: 0 16px;
  position: absolute;
  width: 100%;
}

.review {
  float: left;
  height: 12px;
  width: 100px;
}

.swiper .review {
  margin-bottom: 10px;

  @include media('<phone') {
    display: none;
  }
}

.price {
  float: right;
  height: 12px;
  width: 60px;
}

.review,
.price {
  @include media('<phone') {
    float: none;
  }
}
