@import 'share';
.container {
  width: 100%;
  padding: 60px 0;
  background-color: $gray-50;

  img {
    margin: 0 auto;
  }
}

.section1,
.section2,
.section3 {
  padding: 0 91px;
  
  img {
    width: 1058px;
  }
}

.section2,
.section3 {
  margin-top: 68px;
}

@include media('<phone') {
  .container {
    padding: 40px 0 64px;

    img {
      width: 100%;
    }

    hr {
      display: none;
    }
  }

  .section1,
  .section2,
  .section3 {
    padding: 0 26px;
  }

  .section2,
  .section3 {
    margin-top: 40px;
  }
}
