@import 'share';
.container {
  position: relative;
}

.page {
  position: relative;
  left: 0 !important;
  bottom: 0 !important;
  margin-top: 12px;
  line-height: 1;
  text-align: center;
  z-index: 10;
}

.bullet {
  width: 8px;
  height: 8px;
  display: inline-block;
  margin: 0 4px;
  border-radius: 100%;
  background-color: $black;
  opacity: 0.1;
}

.bullet.active {
  opacity: 0.5;
}

.arrow {
  background-color: rgba($black, 0.15);
  border: 0;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.2s ease;
  z-index: 1;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  opacity: 0;
  visibility: hidden;
  &:hover {
    background-color: rgba($black, 0.3);
  }
}

.prev {
  left: 16px;
}

.next {
  right: 16px;
}

@include media('>=tablet') {
  .container:hover .arrow {
    visibility: visible;
    opacity: 1;
  }
}

@include media('<desktop') {
  .page {
    position: relative;
    left: 0 !important;
    bottom: 0 !important;
    margin-top: 12px;
  }

  .bullet {
    width: 6px;
    height: 6px;
    margin: 0 3px;
  }
}

@include media('<tablet') {
  .page {
    margin-top: 8px;
  }

  .arrow {
    display: none;
  }
}

@include media('<phone') {
  .page {
    margin-top: 2px;
  }

  .bullet {
    width: 4px;
    height: 4px;
  }
}
