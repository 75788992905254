@import 'share';
.container {
  width: 100%;
}

.description {
  margin-top: 0;
  margin-bottom: 8px;
  font-size: 15px;
  line-height: 1.6;
  letter-spacing: -0.43px;
  color: $gray-800;
  white-space: pre-wrap;
}

.photos {
  display: flex;
  justify-content: space-between;
}

.image {
  width: 342px;
  height: 200px;
  object-fit: cover;
  border-radius: 4px;
}

@include media('<phone') {
  .photos {
    flex-direction: column;
    justify-content: flex-start;
  }

  .image {
    width: 100%;
    height: 160px;
  }

  .image:nth-child(2) {
    margin-top: 8px;
  }
}
