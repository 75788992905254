@import 'share';
.button {
  background-color: $white;
  border: 0;
  display: inline-block;
  height: calc(100% - 1px);
  font-weight: 700;
  padding: 0;
  width: auto !important;
  letter-spacing: -0.4px;
  font-size: 16px;
}

.button.gray {
  color: $gray-600;
}

.button.primay {
  color: $gray-800;
}

.button.active {
  border-bottom: 3px solid $blue-400;

  &.gray {
    color: $gray-800;
  }

  &.blue {
    color: $blue-500;
  }
}

.xLarge {
  line-height: 52px;
}

.large {
  line-height: 52px;
}

.medium {
  line-height: 42px;
}

@include media('>=phone') {
  .button:hover:not(.active) {
    border-bottom: 2px solid $gray-400;
  }
}
