@import 'share';
.container {
  width: 100%;
}

.container > li {
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: $gray-500;

  strong {
    color: $gray-700;
    font-weight: 800;
  }
}

@include media('<phone') {
  .container {
    width: 210px;
    margin-bottom: 12px;
  }

  .container > li {
    font-size: 12px;
    font-weight: normal;
    line-height: 1.5;
  }
}
