@import 'share';
.select {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  padding-left: 10px;
  background: $white;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><path d='M7 14l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/><path d='M7 10l5-5 5 5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  border: 1px solid $gray-300;
  border-radius: 2px;
  font-weight: 500;
  color: $gray-800;
  outline: none;
  transition: background-color .2s, border .2s;
}

.select::-ms-expand {
  display: none;
}

.select:hover {
  border: 1px solid $gray-500;
}

.select:focus {
  border: 2px solid $blue-500;
}

.invalid.select {
  border: 2px solid #ff8000;
}

.disabled.select {
  border: 1px solid $gray-100;
  background-color: $gray-50;
  color: $gray-400;
}

.select.large {
  height: 48px;
  background-position: top 12px right 4px;
  font-size: 15px;

}

.select.medium {
  height: 40px;
  background-position: top 8px right 4px;
  font-size: 14px;
}

.select.small {
  height: 32px;
  background-position: top 4px right 4px;
  font-size: 13px;
}
