@import 'share';
.container {
  display: inline-block;
}

.icon {
  height: 12px;
  margin-right: 2px;
  width: 12px;
}

.time {
  color: $gray-500;
  font-size: 13px;
  line-height: 1;
  vertical-align: middle;
}