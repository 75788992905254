@import 'share';
:global(.swiper-container).swiper {
  position: relative;
  left: -10px;
  right: -10px;
  width: calc(100% + 20px);
  height: 100%;

  :global(.swiper-wrapper) {
    height: 126px;
  }

  @include media('<phone') {
    left: auto;
    right: auto;
    width: 100%;

    :global(.swiper-wrapper) {
      height: 92px;
    }
  }
}
