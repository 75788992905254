@import 'share';
.container {
  width: 430px;
  padding: 48px;
  margin: 64px auto;
  border: 1px solid $gray-200;
  border-radius: 2px;
}

.oauth {
  margin-bottom: 24px;
}

@include media('<phone') {
  .container {
    width: 100%;
    padding: 28px 16px;
    margin: 0;
    border: 0;
  }
}