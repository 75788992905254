.resp-container {
  width: 100%;

  &--row {
    margin: 0 auto;
    position: relative;
    width: $desktop-width;
  }
}

@include media('<phone') {
  .resp-container {
    &--row {
      &.swiper__row {
        padding: 0;
        width: 100%;
      }
    }
  }
}
