@import 'share';
.container {
  width: 100%;
  padding: 12px 16px;
  background-color: $gray-50;
  border-radius: 4px;
  margin-bottom: 24px;
}

.title {
  font-size: 14px;
  font-weight: 600;
  color: $gray-700;
  margin-bottom: 2px;
  line-height: 1.43;
}

.notices {
  width: 100%;
}

.notice {
  font-size: 13px;
  letter-spacing: -0.17px;
  color: $gray-500;
  line-height: normal;
}