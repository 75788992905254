//price section
.offer-mobile-price {
  width: 100%;
  @media #{$information-only-tablet} {
    display: none;
  }
  @media #{$information-mobile} {
    background-color: white;

    &__wrapper {
      padding-bottom: 120px;

      .offer-popover-price-single-datepicker {
        width: 100%;
        border-radius: 8px;
        border: 1px solid #e9ecef;
        background: #fff;

        box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.1), 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
        z-index: 1000;

        &.Popover.bottom-left {
          top: calc(100% + 12px);
        }
      }
    }

    &__wrapper-inner {
      position: relative;
    }

    &__option-loading {
      text-align: center;
    }
    &__select-description {
      margin-top: 24px;
      margin-right: 20px;
      margin-bottom: 12px;
      font-size: 18px;
      font-weight: bold;
      color: $gray-800;
      display: inline-block;
    }

    &__line {
      background-color: $gray-100;
      margin: 12px 0px;
      width: 100%;
    }

    &--header {
      max-width: 223px;
      overflow: hidden;
      height: 17px;
    }

    &__question {
      &__wrapper {
        margin: 16px 0px;
        text-align: right;
      }
      &--icon {
        width: 16px;
        height: 16px;
      }
      &--description {
        display: inline;
        opacity: 0.9;
        font-size: 12px;
        font-weight: bold;
        text-align: right;
        color: $blue-400;
        margin-right: 4px;
      }
    }
    .popover-container {
      position: relative;
    }
    &--wrapper {
      padding-bottom: 16px;

      &.bottom {
        @include media('<phone') {
          padding-bottom: calc(constant(safe-area-inset-bottom) + 48px);
          padding-bottom: calc(env(safe-area-inset-bottom) + 48px);
        }
      }

      button {
        width: 100%;
      }
    }

    &__select {
      display: flex;
      align-items: center;
      width: 100%;
      height: 48px;
      opacity: 0.9;
      border-radius: 2px;
      background-color: white;
      border: solid 1px $gray-200;
      font-size: 16px;
      color: $gray-800;
      text-align: left;
      margin-right: 20px;
      line-height: 1;
      padding: 12px 0px;
      background-repeat: no-repeat;
      background-size: 24px 24px;
      background-position: left 12px top 12px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      &__icon {
        width: 24px;
        height: 24px;
        margin-right: 8px;
        margin-left: 12px;
      }
    }
    &__result {
      display: inline-block;
      width: 100%;
      height: 48px;
      opacity: 0.9;
      border-radius: 2px;
      background-color: $blue-80;
      opacity: 0.9;
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      color: $blue-500;
      line-height: 48px;
      @media #{$information-only-desktop} {
        width: 138px;
      }
      @media #{$information-only-tablet} {
        width: 140px;
      }
    }

    &__total-result {
      border-radius: 2px;
      background-color: $gray-50;
      padding: 16px 16px;
      margin-bottom: 16px;
      &--extra {
        border-radius: 2px;
        border: solid 1px $gray-300;
        background-color: $white;
        font-size: 16px;
        color: $gray-700;
        padding: 20px;
        margin: 8px 0px;
        &--refund {
          color: #848c94;
          font-size: 10px;
        }
        &--icon {
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
        }
        &--selected {
          border: solid 2px $blue-500;
          position: relative;
        }
      }

      &--notice {
        font-size: 15px;
        color: $gray-800;
        margin-bottom: 8px;
        font-weight: 700;
      }

      &--title {
        text-align: left;
        font-size: 14px;
        font-weight: 600;
        color: $gray-600;
        display: inline-block;
        width: 365px;
        margin-right: 125px;
        @media #{$information-only-desktop} {
          width: 276px;
        }
        @media #{$information-only-tablet} {
          width: 291px;
        }
        @media #{$information-mobile} {
          width: 100%;
        }
      }
      &--sub-price {
        font-size: 12px;
        color: $gray-500;
        display: inline-block;
        margin-right: 32px;
      }
      &--price {
        font-size: 14px;
        color: $gray-800;
        display: inline-block;
        font-weight: bold;
        @media #{$information-mobile} {
          float: right;
        }
      }
      &--price-wrapper {
        display: inline-block;
        @media #{$information-mobile} {
          display: block;
        }
      }
      &--total-price {
        float: right;
        color: $blue-500;
        display: inline-block;
        font-size: 20px;
        margin-left: 16px;
        text-align: right;
      }
      &--total-price-description {
        display: flex;
        width: 100%;
        justify-content: space-between;
        font-size: 14px;
        font-weight: bold;
        color: $gray-800;
      }
    }
    &__option-wrapper {
      position: relative;
      &--disabled {
        pointer-events: none;
        opacity: 0.4;
      }
      select {
        padding-left: 44px;
      }
    }
  }
}
