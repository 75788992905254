@mixin defaultHover {
  background: $white;
  border: 2px solid $blue-400;
  color: none;
  padding: 4px 6px;
}

.DayPicker__withBorder {
  border-radius: 2px;
  box-shadow: none;
}

// month styles
.CalendarMonth_caption {
  color: $gray-800;
  font-size: 20px;
  padding-top: 20px;

  strong {
    font-weight: 500;
  }

  @media #{ $information-mobile } {
    padding-right: 24px;
    text-align: right;
  }
}

// week styles
.DayPicker_weekHeader_li {
  color: $gray-600;

  small {
    font-size: 12px;
  }
}

// day styles
.CalendarDay {
  font-size: 16px;
  min-height: 40px;
  min-width: 40px;
  padding: 0;
  position: relative;

  .day-wrapper {
    display: inline-block;
    height: 100%;
    left: 0;
    max-height: 40px;
    position: absolute;
    top: 0;
    width: 100%;

    @media #{ $information-mobile } {
      top: 50%;
      transform: translateY(-20px);
    }
  }

  .day-item {
    border-radius: 24px;
    border-width: 2px;
    color: $gray-700;
    display: inline-block;
    height: 38px;
    padding: 6px 8px;
    position: relative;
    width: 38px;
  }

  &:hover {
    border: 0;
  }
}

.CalendarDay__default {
  border: 0 !important;
  &:hover {
    border: 0;
    background-color: $white;

    .day-item {
      @include defaultHover;
    }
  }
  & .CalendarDay__blocked_calendar {
    .today {
      font-weight: 700;
    }

    &:hover,
    &:active {
      cursor: not-allowed;
      background-color: $gray-80 !important;

      .day-item {
        border: 2px solid $gray-80 !important;
        background: $gray-80 !important;
      }
    }
  }
  & .CalendarDay__blocked_out_of_range {
    &:hover {
      cursor: not-allowed;
      background-color: $white !important;

      .day-item {
        border: 2px solid $white !important;
        background: $white !important;
      }
    }
  }
}

.CalendarDay__blocked_calendar,
.CalendarDay__blocked_out_of_range {
  border: 0;
  background-color: $white;

  .day-item {
    color: $gray-200 !important;
  }

  &:hover {
    cursor: not-allowed;
    background-color: $white !important;

    .day-item {
      border-color: $white;
      border: 2px solid $white !important;
      background-color: $white !important;
    }
  }
}

.Calendar_range .CalendarDay__selected {
  background: $white !important;
  border: 0;

  .day-wrapper {
    background: $blue-80;
  }

  &:active,
  &:hover {
    border: 0;
    background: $white;

    .day-wrapper {
      background: $blue-80;
    }

    .day-item {
      color: $gray-700;
    }
  }
}

.Calendar_range .CalendarDay__selected_span {
  border: 0;
  background: $white !important;

  &:active,
  &:hover {
    background: $blue-80 !important;
    border: 0;
  }
}

.Calendar_single .CalendarDay__selected {
  background: $white !important;

  &:active,
  &:hover {
    border: 0;
    background: $white;
  }
}

.CalendarDay__hovered_span {
  background: $white;
}

.CalendarDay__selected {
  border: 0;
  font-weight: 600;
  background: $white;
  &:hover {
    background: $white;
    border: 0;
  }
  .day-item {
    background: $blue-400 !important;
    color: $white !important;
  }
}

.CalendarDay__selected_span {
  background: $white !important;
  border: 0;
  .day-wrapper {
    background: $blue-80;
  }

  &:hover {
    background: $white;
    border: 0;
    color: $white;
    .day-item {
      @include defaultHover;
    }
  }
}

.CalendarDay__selected_start {
  background: $white;

  &.CalendarDay__selected_end {
    .day-wrapper {
      background: none !important;
    }
  }

  .day-wrapper {
    border-bottom-left-radius: 24px;
    border-top-left-radius: 24px;

    @media #{ $information-mobile } {
      left: calc((100% - 40px) / 2);
    }
  }
}

.CalendarDay__selected_end {
  background: $white;

  .day-wrapper {
    border-bottom-right-radius: 24px;
    border-top-right-radius: 24px;

    @media #{ $information-mobile } {
      left: auto;
      right: calc((100% - 40px) / 2);
    }
  }
}

.CalendarDay__hovered_span {
  background: $white;

  .day-wrapper {
    background: $blue-80;
    color: $gray-700;
  }

  &:hover {
    background: $white !important;

    .day-wrapper {
      background: $blue-80;
      border: 0;
      border-bottom-right-radius: 24px;
      border-top-right-radius: 24px;
      color: $gray-700;

      @media #{ $information-mobile } {
        left: auto;
        right: calc((100% - 40px) / 2);
      }
    }
  }
}

// Nav buttons
.Calendar_Nav_Button {
  height: 24px;
  position: absolute;
  top: 22px;
  width: 24px;

  &.left {
    left: 30px;
  }

  &.right {
    right: 30px;
  }

  &--disabled {
    cursor: not-allowed !important;
  }
}

//mobile
.Calendar_mobile {
  @media #{ $information-mobile } {
    height: 100vh;
    width: 100vw;
  }

  .DayPicker_weekHeader__verticalScrollable {
    padding: 0 !important;
  }

  .CalendarMonthGrid {
    width: 100% !important;
  }

  .CalendarMonth {
    padding: 0 !important;
  }
}

.ModalDatePicker {
  margin: 0 auto;
  width: fit-content;
}

.Datepicker__Description {
  box-shadow: inset 0 -1px 0 0 $gray-100;
  padding: 16px;
  max-width: 100%;
}
