@import 'share';
.form-control {
  display: block;
  margin-bottom: 24px;
}

.form-control.last {
  margin-bottom: 0;
}

.form-control > p {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 500;
  color: $gray-700;
}

