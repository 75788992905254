@import 'share';
.container {
  display: inline-block;
  width: 100%;
  height: 80px;
}

.image {
  width: 100%;
  object-fit: cover;
  background-position: center;
  border-radius: 2px;
}
