@import 'share';
.container {
  width: 100%;
  height: 24px;
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
}

.option {
  margin-right: 10px;
  font-size: 13px;
  font-weight: 500;
  color: $gray-600;
  transition: color 0.2s;
}

.option:hover {
  color: $gray-700;
}

.option::before {
  content: '';
  display: inline-block;
  width: 4px;
  height: 4px;
  margin-right: 4px;
  margin-bottom: 2px;
  border-radius: 4px;
  background-color: $gray-300;
  vertical-align: middle;
  transition: background-color 0.2s;
}

.option:hover::before {
  background-color: $gray-700;
}

.option:last-child {
  margin-right: 0;
}

.option.active {
  font-weight: bold;
  color: $gray-800;
}

.option.active::before {
  background-color: $gray-800;
}
