@import 'share';
:global(.swiper-slide).city {
  position: relative;
  display: inline-block;
  width: 160px !important;
  height: 107px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 4px;
  background-position: center;
  background-size: cover;
  box-shadow: 0 3px 8px 0 rgba($black, 0.3), 0 0 1px 0 rgba($black, 0.3);
  transition: box-shadow .2s ease;

  &:last-child {
    margin-right: 0 !important;
  }

  &:hover {
    box-shadow: 0 6px 12px 0 rgba($black, 0.3), 0 0 1px 0 rgba($black, 0.3);
  }

  @include media('<phone') {
    height: 80px;
    width: 120px !important;
    margin-left: 0;
    margin-right: 0;
  }
}

.wrapper {
  background-image: linear-gradient(166deg, #fff 40%, hsla(0, 0%, 100%, 0) 68%);
  border-radius: 4px;
  height: 100%;
  width: 100%;
}

.cityName {
  color: $gray-800;
  font-size: 18px;
  font-weight: 700;
  position: absolute;
  top: 14px;
  text-align: left;
  width: 100%;
  padding: 0 16px;
}

.cityName > span {
  display: block;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.day {
  background-color: #fa9a00;
  border-radius: 4px;
  padding: 2px 6px;
  font-size: 14px;
  font-weight: 700;
  color: $white;
  text-align: center;
  margin-top: 6px;
  display: inline-block;
}

.total > .cityName {
  color: $white;
}

@include media('<phone') {
  .cityName {
    font-size: 15px;
    top: 12px;
    padding: 0 12px;
  }

  .day {
    font-size: 11px;
  }
}
