.offer-recommend {
  margin-bottom: 64px;

  &--title {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 16px;
  }
}

@include media('<phone') {
  .offer-recommend {
    margin-bottom: 40px;

    &--title {
      font-size: 20px;
      margin-bottom: 8px;
    }
  }
}
