@import 'share';
.button {
  width: 100%;
  padding-top: 16px;
  background-color: $white;
  text-align: center;
}

.label {
  vertical-align: middle;
}

.icon {
  margin-left: 4px;
}
