@import 'share';
.container {
  width: 100%;
  position: relative;
  text-align: center;
  padding: 72px 0;
}

@include media('>=phone', '<tablet') {
  .container {
    padding: 0 24px;
  }
}

@include media('<phone') {
  .container {
    padding: 0 16px;
  }
}