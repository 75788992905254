@import 'share';
.wrapper {
  display: inline-block;
  font-size: 14px;
  width: 100%;
  min-height: 16px;
  font-size: 14px;
  line-height: 16px;
}

.term-arrow {
  float: right;
  cursor: pointer;
  height: 16px;
  width: 16px;
}
