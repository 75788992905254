@import 'share';
.OfferCardCategory {
  margin-bottom: 2px;
  margin-top: 4px;

  @media #{ $information-mobile } {
    margin-bottom: 4px;
  }

  &__Label {
    font-size: 12px;
    color: $gray-500;
    vertical-align: middle;
  }

  &__Dot {
    margin: 0 4px;
  }
}
