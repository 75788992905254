@import 'share';
.date {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.25;
  color: $gray-800;
  margin-bottom: 16px;
}

.options {
  width: 100%;
}

.error {
  margin: 36px 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  color: $gray-700;
  width: 100%;
  text-align: center;
}

.error > .icon {
  margin-bottom: 8px;
}

.icon > img {
  width: 32px;
  height: 32px;
}

@include media('>=phone') {
  .optionWrapper {
    background-color: $gray-60;
    padding: 24px;
  }

  .optionWrapper.border {
    border: 1px solid $gray-100;
    border-top: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .payment {
    margin: 16px 0;
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
  }

  .button {
    width: 170px;
  }

  .spinner {
    height: 142px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@include media('<phone') {
  .withDatePicker > .optionWrapper {
    background-color: $gray-60;
    position: relative;
    width: 100vw;
    padding: 24px 16px 120px 16px;
    // left: -16px;
    // position: relative;
  }
}
