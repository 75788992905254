@import 'share';
.container {
  min-height: 450px;
  padding-top: 40px;
  padding-bottom: 56px;
  background-color: $white;

  @include media('<phone') {
    min-height: 100vh;
  }
}

.container.opened-by-modal {
  padding: 0;

  :global(.mrt-terms-contents) {
    padding: 24px;
  }

  @include media('<phone') {
    height: 100%;
  }
}
