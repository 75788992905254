.image-viewer {
  max-width: 1156px;
  margin: auto;
  height: 100vh;
  margin-top: 55vh;
  transform: translateY(-40vh);
  padding: 0;
  img {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  }
  &--header {
    position: absolute;
    top: 16px;
    left: 16px;
    @media #{$information-mobile} {
    }
  }
  p {
    margin: 0 0;
  }
  & .swiper-wrapper {
    text-align: center;
    overflow: unset;
    align-items: center;
  }
  & .swiper-button-next,
  .swiper-button-prev {
    background-image: none;

    &:after {
      display: none;
    }
  }
  & .swiper-button-next-custom {
    position: absolute;
    top: 50%;
    width: 48px;
    height: 80px;
    margin-top: -72px;
    z-index: 10;
    cursor: pointer;
    right: 10px;
    left: auto;
    opacity: 0.5;
    transition: all 0.075s;
    border-radius: 4px;
    padding: 4px;
    box-shadow: none;
    &:hover {
      opacity: 1;
      background: rgba(255, 255, 255, 0.1);
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    }
  }
  & .swiper-button-prev-custom {
    position: absolute;
    top: 50%;
    width: 48px;
    height: 80px;
    margin-top: -72px;
    z-index: 10;
    cursor: pointer;
    right: auto;
    left: 10px;
    opacity: 0.5;
    transition: all 0.075s;
    border-radius: 4px;
    padding: 4px;
    box-shadow: none;
    &:hover {
      opacity: 1;
      background: rgba(255, 255, 255, 0.1);
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    }
  }
  & .swiper-slide {
    img {
      max-width: 100%;
      max-height: 66vh;
      min-height: 40vh;
    }
  }

  & .swiper-container-free-mode {
    object-fit: contain;
    width: 100%;
    max-height: 520px;
    max-width: 705px;
    margin-top: 16px;
    @media #{$information-mobile} {
      width: 100%;
      height: 100%;
    }
    & .image-viewer__thumbnail--content {
      width: 100%;
      background-size: cover;
      opacity: 0.4;
      height: 92px;
      @media #{$information-mobile} {
        width: 100%;
        height: calc((100vw - 48px) / 4 * 0.75);
      }
    }
    & .swiper-slide-active {
      & .image-viewer__thumbnail--content {
        opacity: 1;
      }
    }
  }
  &--title {
    font-size: 16px;
    font-weight: 500;
    color: $white;
    @media #{$information-mobile} {
      max-width: 223px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &--image-num {
    font-size: 16px;
    font-weight: 400;
    color: $white;
    width: 100%;
    margin: 8px auto 0 !important;
    -o-object-fit: contain;
    object-fit: contain;
    max-width: 980px;
    max-height: 795px;
    text-align: center;
    width: 100%;
    max-width: 705px;
  }

  &--guide {
    font-size: 16px;
    font-weight: 500;
    opacity: 0.5;
    color: $white;
  }

  &__thumbnail {
    width: auto;
  }

  &__close-icon {
    position: absolute;
    right: 0;
    top: 0;
    padding: 4px;
    transition: all 0.075s;
    border-radius: 4px;
    margin: 8px;
    width: 48px;
    height: 48px;
    z-index: 150;
    opacity: 0.5;
    &:hover {
      opacity: 1;
      background: rgba(255, 255, 255, 0.1);
    }
    @media #{$information-mobile} {
    }
  }
  &__wrapper {
    margin: 24px 4px;
    position: relative;
  }
  &__title {
    font-family: AppleSDGothicNeo;
    font-size: 16px;
    font-weight: 500;
    color: white;
  }
}
