@import 'share';
.container {
  width: 100%;
  text-align: center;
}

.qr {
  width: 100px;
  margin-bottom: 24px;
}

.buttons {
  text-align: center;
  width: 100%;
}

.button > img {
  width: 140px;
  height: 45px;
}

.button:first-child {
  margin-right: 8px;
}
