@import 'share';
.toast {
  box-shadow: 0 4px 8px 0 rgba(33, 37, 41, 0.1);
  color: $white;
  font-size: 14px;
  font-weight: 500;
  padding: 16px;
  position: fixed;
  z-index: 1000;
  display: inline-block;
  opacity: 0;
  visibility: hidden;
  transition: opacity 1s, visibility 1s;
  border-radius: 4px;
}

.toast.visible {
  opacity: 1;
  visibility: visible;
}

@include media('>=phone') {
  .toast {
    top: 16px;
    right: 16px;
    width: 340px;
  }
}

@include media('<phone') {
  .toast-wrapper {
    display: flex;
    justify-content: center;
  }
  .toast {
    bottom: 16px;
    width: calc(100% - 32px);
    left: 50%;
    transform: translateX(-50%);
  }
}

.toast.general {
  background-color: $gray-700;
}

.warning {
  background-color: $yellow-700;
}

.success {
  background-color: $green-500;
}

.title {
  font-weight: bold;
}
