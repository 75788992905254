.offer-required {
  width: 100%;

  &__title {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.38;
    color: #343a40;
    margin-bottom: 16px;
    @media #{$information-mobile} {
      font-size: 22px;
    }
  }
  &__extra {
    &--title {
      font-size: 24px;
      font-weight: bold;
      line-height: 1.38;
      color: #343a40;
      margin: 16px 0px;
      @media #{$information-mobile} {
        font-size: 22px;
      }
    }
  }
  &--price-header {
    font-size: 16px;
    font-weight: bold;
    color: $gray-800;
  }
  &__content {
    font-size: 15px;
    line-height: 1.67;
    color: $gray-800;
    &--message {
      color: $blue-500;
      font-size: 15px;
      margin-bottom: 8px;
    }
  }
  &__guide {
    &--wrapper {
      font-size: 15px;
      &--more {
        font-size: 13px;
        color: $blue-500;
        margin-top: 18px;
      }
    }
    &__header {
      margin-bottom: 12px;
      button {
        float: right;
      }
    }
    &--mail {
      width: 101px;
      height: 40px;
      border-radius: 2px;
      background-color: $white;
      border: solid 1px $gray-300;
      display: inline-block;
      vertical-align: middle;
      &--mobile {
        button {
          margin-top: 16px;
          width: 100%;
        }
      }
    }
    &--image {
      margin-right: 4px;
    }
    &--profile {
      background-size: cover;
      width: 56px;
      height: 56px;
      display: inline-block;
      -moz-border-radius: 28px;
      -webkit-border-radius: 28px;
      border-radius: 28px;
      margin-right: 16px;
      vertical-align: middle;
      border-radius: 50%;
      box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.2);
    }
    &--name {
      font-size: 18px;
      font-weight: bold;
      color: $gray-800;
      display: inline-block;
    }
  }
}
