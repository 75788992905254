@import 'share';
.container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(10, auto);
  grid-column-gap: 20px;
  grid-row-gap: 0;
}

@include media('<phone') {
  .container {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(10, auto);
    grid-column-gap: 6px;
    grid-row-gap: 0;
  }
}