@import 'share';
.container {
  box-shadow: inset 0 -1px 0 0 $gray-100;
  position: relative;
  width: 100%;

  :global(.swiper-container) {
    height: 100%;
  }
}

.container.hideBorder {
  box-shadow: none;
}

.xLarge {
  height: 52px;
}

.large {
  height: 52px;
}

.medium {
  height: 42px;
}

.small {
  height: 16px;
}

.navButton {
  background-color: transparent;
  background-image: none;
  border: 0;
  display: none;
  height: 24px;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 50%;
  text-align: center;
  transform: translate(0, -50%);
  width: 24px;

  &:hover {
    background-color: $gray-300;
    border-radius: 2px;
  }
}

.navButton.show {
  @include media('>=phone') {
    display: block;
  }
}

.navButton.inner {
  background-color: $white;
  height: 100%;
  z-index: 1;
  &:hover {
    border-radius: 0;
  }
}

.prev {
  right: auto;
  left: -24px;
}

.prev.inner {
  left: 0;
}

.next {
  left: 100%;
}

.next.inner {
  left: auto;
  right: 0;
}

.gradient {
  display: none;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: opacity 0.3s linear;
  width: 64px;
  z-index: 1;
}

.gradient.show {
  display: block;
  opacity: 1;
}

.gradientLeft {
  background-image: linear-gradient(to left, rgba($white, 0), $white);
  left: 0;
}

.gradientLeft.inner {
  left: 24px;
}

.gradientRight {
  right: 0;
  background-image: linear-gradient(to right, rgba($white, 0), $white);
}

.gradientRight.inner {
  right: 24px;
}
