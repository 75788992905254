@import 'share';
.container {
  color: $gray-800;
  font-weight: 600;
  margin: 0;
  width: 100%;
}

.container.vertical {
  font-size: 15px;
  line-height: 1.47;
}

.container.horizontal {
  font-size: 18px;
  line-height: 1.33;
}

.title {
  line-height: inherit;
}

@include media('<phone') {
  .container.vertical,
  .container.horizontal {
    font-size: 14px;
    line-height: 1.29;
  }

  .container.horizontal {
    padding-right: 28px;
  }
}
