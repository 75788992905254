.offer-guarantee {
  width: 100%;

  &--mobileInfo {
    button {
      width: 100%;
      margin-top: 16px;
    }
  }

  &--notMobileInfo {
    button {
      float: right;
    }
  }

  &--header {
    font-size: 24px;
    font-weight: bold;
    color: $gray-800;
    display: inline-block;
    @media #{$information-mobile} {
      font-size: 22px;
    }
  }
  &--content {
    font-size: 15px;
    margin: 16px 0px;
  }
}
