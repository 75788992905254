.starRating {
  border: none;
  display: inline-block;
  line-height: 1;
  vertical-align: middle;
  &--empty {
    width: 40px;
    text-align: right;
  }
  &--xl {
    svg {
      width: 24px;
      height: 24px;
    }
  }
  &--l {
    svg {
      width: 16px;
      height: 16px;
    }
  }

  &--m {
    svg {
      width: 12px;
      height: 12px;
    }
  }

  &--s {
    svg {
      width: 10px;
      height: 10px;
    }
  }

  &--xs {
    svg {
      width: 8px;
      height: 8px;
    }
  }
  &--gray {
    .starColor {
      fill: $gray-800;
    }
  }
  &--blue {
    .starColor {
      fill: $blue-400;
    }
  }
  &--red {
    .starColor {
      fill: $red-400;
    }
  }

  &--yellow {
    .starColor {
      fill: $yellow-400;
    }
  }
}

.empty-star {
  display: none;
}
