@import 'share';
.container {
  position: relative;
}

.anchor {
  background-color: transparent;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.anchor > span {
  vertical-align: middle;
  line-height: 1.29px;
}

.eventIcon {
  margin-right: 4px;
}

.eventLabel {
  font-size: 13px;
  font-weight: 600;
  color: $blue-500;
  vertical-align: middle;
  line-height: 1.38 !important;
}

.score {
  margin-left: 4px;
  font-size: 14px;
  font-weight: 600;
  color: $gray-800;
  letter-spacing: normal;
}

.count {
  font-size: 14px;
  color: $gray-500;
  margin-left: 2px;
}
