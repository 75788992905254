.offer-cancelRefund {
  width: 100%;
  h4 {
    font-size: 24px;
    font-weight: bold;
    @media #{$information-mobile} {
      font-size: 22px;
    }
  }
  button,
  h4 {
    display: inline-block;
  }
  button {
    float: right;
  }
  &--content {
  }
  &--header {
    margin-bottom: 32px;
  }
  &--wrapper {
    font-size: 15px;
  }
  &__modal {
    overflow-y: scroll;
    text-align: left;
    width: 520px;
    height: 507px;
    margin: auto;
    border-radius: 4px;
    background-color: white;
    box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(0, 0, 0, 0.15);
    &__header,
    &__body {
      padding: 24px 24px;
    }
    &--content {
      max-height: 411px;
      overflow-y: scroll;
    }
    &__closeIcon {
      float: right;
      width: 24px;
      height: 24px;
    }
  }
}
