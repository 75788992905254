@import 'share';
.container {
  padding: 42px 0 55px;

  img {
    margin: 0 auto;
  }

  .box {
    padding: 0 55px;
    margin: 0 auto 32px;
    border-radius: 16px;
    background-color: $gray-50;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.title {
  img {
    width: 449px;
  }
}

.benefit {
  padding: 0 55px;
  margin-top: 31px;
}

.section1 {
  padding: 32px 0 30px;

  img {
    width: 458px;
  }
}

.section2 {
  padding: 32px 0 30px;

  img {
    width: 454px;
  }

  .link {
    margin-top: 27px;

    .ctaButton {
      width: 100%;
    }
  }
}

.link {
  width: 320px;
  margin: 0 auto;
}

@include media('<phone') {
  .container {
    padding: 22px 0 12px;

    img {
      width: 100%;
    }

    .box {
      padding: 0;
      margin-bottom: 28px;
      border-radius: 16px;
    }
  }

  .title {
    padding: 0 22px;
  }

  .benefit {
    padding: 0 12px;
    margin-top: 19px;

    .section1 {
      padding: 20px 22px;
    }

    .section2 {
      padding: 21px 0 32px;

      .sectionInner {
        padding: 0 28px;
      }

      .link {
        padding: 0 12px;
      }
    }
  }
}
