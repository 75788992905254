@import 'share';
.container {
  width: 100%;
  padding: 24px 0;
  border-bottom: 1px solid $gray-100;
}

.container:last-of-type {
  border-bottom: 0;
}
