.offer-review {
  width: 100%;

  &--more-arrow-icon {
    width: 16px;
    height: 16px;
    margin-left: 5px;
  }

  &--gradient {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 120px;
    background: -moz-linear-gradient(
      top,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 90%,
      rgba(255, 255, 255, 1) 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 90%,
      rgba(255, 255, 255, 1) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 90%,
      rgba(255, 255, 255, 1) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    text-align: center;

    & > button {
      padding: 0 0;
      width: 120px;
      height: 40px;
      @include media('<phone') {
        width: 100%;
      }
      @include content-middle-center;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
    }
  }

  &__istanbul-header {
    &--title {
      margin-bottom: 10px;
      font-size: 22px;
      font-weight: bold;
      color: $gray-800;

      @media #{$information-mobile} {
        font-size: 20px;
      }
    }
    &--content {
      font-size: 14px;
      color: $gray-500;
    }
    margin-bottom: 24px;
  }
  &__header {
    margin-bottom: 16px;
    &__title {
      font-size: 22px;
      font-weight: bold;
      color: $gray-800;
      @media #{$information-mobile} {
        font-size: 20px;
      }
      span {
        margin-left: 8px;
        color: $blue-500;
      }
    }
  }
  &__reply {
    margin-top: 16px;
    margin-left: 16px;
    margin-bottom: 24px;
    &--guide {
      display: inline-block;
      font-size: 14px;
      font-weight: bold;
      color: $gray-800;
    }
    &--message {
      white-space: pre-wrap;
      font-size: 15px;
      color: $gray-800;
      line-height: 25px;
    }
    &--icon {
      vertical-align: top;
      margin-right: 8px;
    }
  }

  &--more {
    text-align: center;
    margin-top: 24px;
    button {
      @media #{$information-mobile} {
        width: 100%;
      }
      line-height: 16px;
      padding: 16px 40px;
    }
  }

  &__detail-info {
    height: 165px;
    margin-bottom: 24px;
    height: 165px;
    &__rating {
      height: 165px;
      width: 250px;
      height: 100%;
      float: left;
      border-radius: 2px;
      background-color: $gray-50;
      margin-right: 8px;
      text-align: center;
      @media #{$information-only-desktop} {
        width: 217px;
      }
      @media #{$information-only-tablet} {
        width: 217px;
      }
      @media #{$information-mobile} {
        width: 100%;
        margin-bottom: 4px;
      }
    }

    &__stats {
      width: 442px;
      height: 100%;
      padding: 24px 24px;
      float: left;
      border-radius: 2px;
      background-color: $gray-50;
      text-align: center;
      @media #{$information-only-desktop} {
        width: 387px;
      }
      @media #{$information-only-tablet} {
        width: 399px;
      }
      @media #{$information-mobile} {
        width: 100%;
      }
      &__count {
        display: inline-block;
        font-size: 12px;
        line-height: 1.42;
        color: $gray-500;
        margin-left: 8px;
        font-size: 12px;
        color: $gray-500;
        width: 36px;
        text-align: left;
      }
      &__sale {
        font-size: 14px;
        font-weight: 500;
        color: $blue-500;
      }
      &__star-wrapper {
        margin: 8px 0px;
        height: 10px;
        text-align: center;
      }
    }
    &__average {
      margin-top: 24px;
      font-size: 48px;
      font-weight: bold;
      color: $gray-800;
    }
  }
  &__list {
    & > div {
      position: relative;
    }

    &--writer {
      display: inline-block;
      margin-left: 4px;
      font-size: 14px;
      font-weight: 500;
      color: $gray-800;
    }

    &--istanbul-date {
      font-size: 12px;
      color: $gray-500;
      margin: 8px 0px;
    }

    &--content {
      margin: 24px 0px;
      @media #{$information-mobile} {
        margin: 16px 0px;
      }
    }

    &--message {
      font-size: 15px;
      line-height: 25px;
      div:last-child {
        display: inline;
      }
    }
    &--purpose {
      margin-top: 4px;
      margin-bottom: 12px;
      font-size: 12px;
      color: $gray-500;
    }
    &--title {
      font-size: 15px;
      font-weight: bold;
      color: $gray-800;
    }

    &--image {
      width: 64px;
      height: 64px;
      background-size: cover;
      float: right;
      margin-left: 16px;
      margin-bottom: 16px;
      position: relative;
    }

    &--image-cover {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: inherit;
      transition: all 0.2s ease-out;
      &:hover {
        background-color: rgba(0, 0, 0, 0.3);
        z-index: 5;
      }
    }
  }
  &__banner {
    width: 700px;
    height: auto;
    border-radius: 2px;
    background-color: $white;
    border: solid 8px $blue-80;
    padding: 24px;
    @media #{$information-only-desktop} {
      width: 612px;
    }
    @media #{$information-only-tablet} {
      width: 100%;
    }
    @media #{$information-mobile} {
      width: 100%;
      height: 100%;
      text-align: center;
    }
    &--first {
      width: 118px;
      height: 72px;
      margin-right: 22px;
      background-size: cover;
      display: inline-block;
      @media #{$information-only-desktop} {
        width: 106px;
        height: 64px;
      }
      @media #{$information-only-tablet} {
        width: 106px;
        height: 64px;
      }
      @media #{$information-mobile} {
        margin-top: 12px;
        width: 91px;
        height: 56px;
      }
    }
    &--second {
      width: 118px;
      height: 72px;
      display: inline-block;
      background-size: cover;
      @media #{$information-only-desktop} {
        width: 106px;
        height: 64px;
      }
      @media #{$information-only-tablet} {
        width: 106px;
        height: 64px;
      }
      @media #{$information-mobile} {
        width: 91px;
        height: 56px;
      }
    }
    &__wrapper {
      width: 315px;
      margin-right: 59px;
      display: inline-block;
      @media #{$information-only-desktop} {
        width: 289px;
        margin-right: 25px;
      }
      @media #{$information-only-tablet} {
        width: 289px;
        margin-right: 25px;
      }
      @media #{$information-mobile} {
        display: block;
        width: 100%;
      }
      h4 {
        font-size: 24px;
        font-weight: 600;
        color: $blue-500;
        @media #{$information-only-desktop} {
          font-size: 20px;
        }
        @media #{$information-only-tablet} {
          font-size: 20px;
        }
        @media #{$information-mobile} {
          font-size: 18px;
          display: block;
        }
      }
      p {
        font-size: 14px;
        color: $gray-700;
      }
    }
  }
}

.clear {
  @media #{$information-mobile} {
    clear: both;
  }
}

.bar-container {
  display: inline-block;
  width: 288px;
  height: 4px;
  border-radius: 4px;
  background-color: $gray-200;
  position: relative;
  margin-left: 8px;
  @media #{$information-only-desktop} {
    width: 232px;
  }
  @media #{$information-only-tablet} {
    width: 256px;
  }
  @media #{$information-mobile} {
    width: 176px;
  }
}

.bar {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  height: 4px;
  border-radius: 4px;
  background-color: $blue-400;
}
