.offer__side {
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  width: 320px;
  @media #{$information-only-desktop} {
    width: 296px;
  }
  &--scroll {
    @media #{$information-above-desktop} {
      position: fixed;
      top: 80px;
      right: 190px;
      display: inline-block;
    }
    @media #{ $information-only-desktop } {
      position: fixed;
      top: 80px;
      right: 48px;
      display: inline-block;
    }
    @media #{$information-tablet} {
      display: none;
    }
    &--finished {
      position: absolute;
      bottom: 0;
      right: 0;
      display: inline-block;
    }
  }

  @media #{ $information-tablet } {
    display: none;
  }

  &__main-box {
    border-radius: 2px;
    background-color: white;
    box-shadow: 0 0 0 1px rgba(52, 58, 64, 0.15), 0 2px 4px 0 rgba(52, 58, 64, 0.15);
    width: 100%;
    &--body {
      padding-top: 24px;
      padding-left: 24px;
      padding-right: 24px;
      padding-bottom: 16px;
    }
    &__table {
      width: 272px;
      margin-bottom: 16px;
      border-collapse: collapse;
      td {
        border-top: solid 1px $gray-200;
        border-bottom: solid 1px $gray-200;
      }

      @media #{$information-mobile} {
        margin-bottom: 24px;
      }
    }

    &__traveler {
      text-align: center;
      background-color: $gray-50;
      font-size: 14px;
      line-height: 2.71;
      color: $gray-800;
    }

    &__question {
      padding-left: 24px;
      padding-right: 24px;
      padding-top: 16px;
      padding-bottom: 16px;
      border-top: solid 1px $gray-200;
      position: relative;
      display: block;
      height: 64px;
      text-align: right;
      &--messageIcon {
        margin-right: 4px;
      }
      &--right {
        display: inline-block;
        font-size: 14px;
        font-weight: bold;
        color: $blue-500;
        text-align: center;
        line-height: 32px;
        width: 84px;
        height: 32px;
        border-radius: 2px;
        transition: all 0.2s ease-out;
        &:hover {
          background-color: $blue-80;
        }
        img {
          vertical-align: text-bottom;
          width: 16px;
          height: 16px;
        }
      }
      &--left {
        float: left;
        display: inline-block;
        &__guide {
          &--name {
            transition: color 0.075s ease-out;
            display: inline-block;
            font-size: 14px;
            font-weight: bold;
            color: $gray-800;
            vertical-align: middle;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            text-align: left;
            margin: 0;
            height: 20px;
            line-height: 20px;
            &:hover {
              color: $blue-500;
            }
          }
          &--profile {
            display: inline-block;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.2);
            background-size: cover;
            vertical-align: middle;
            margin-right: 8px;
          }
        }
      }
    }
    &__travel-price {
      font-size: 14px;
      font-weight: bold;
      line-height: 2.71;
      color: $gray-800;
      padding-left: 16px;
    }
    &__price {
      display: inline-block;
      font-size: 24px;
      font-weight: bold;
      color: $gray-800;
      font-size: 18px;
      margin-bottom: 16px;
      &--main {
        line-height: 1;
        font-size: 24px;
      }
      &--infoLeft {
        margin-right: 4px;
        width: 25px;
        height: 16px;
        font-size: 13px;
        font-weight: 400;
        color: $gray-500;
      }
      &--infoRight {
        margin-left: 4px;
        width: 25px;
        height: 16px;
        font-size: 13px;
        font-weight: 400;
        color: $gray-500;
      }
      &--won {
        font-size: 18px;
      }
    }

    &__hold {
      color: $red-500;
      &--title {
        margin-bottom: 2px;
        font-size: 16px;
        font-weight: 600;
      }
      &--content {
        margin-bottom: 16px;
        font-size: 12px;
        font-weight: 600;
      }
    }

    &__share-wrapper {
      width: 32px;
      height: 32px;
      display: inline-block;
      float: right;
      text-align: center;
      line-height: 31px;
      position: relative;
      transition: all 0.2s ease-out;
      top: -4px;
      &--clicked {
        background-color: $gray-80;
      }
      &:hover,
      &:active {
        border-radius: 2px;
        background-color: $gray-80;
      }
    }
    &__share-icon {
      width: 24px;
      height: 24px;
      object-fit: contain;
      cursor: pointer;
    }
    &__wish-icon {
      width: 18.2px;
      height: 16.6px;
    }
    &__instant {
      text-align: center;
      margin: 8px 0px;
      &--link-wrapper {
        display: inline-block;
      }
      &-booking-icon {
        width: 10px;
        height: 18px;
        margin-right: 8px;
        &--disabled {
          pointer-events: none;
          cursor: default;
        }
      }
      &-booking-yellow-icon {
        width: 10px;
        height: 18px;
        margin-right: 4px;
      }
      &--question-icon {
        width: 16px;
        height: 16px;
      }
      &--content {
        margin-right: 4px;
        display: inline-block;
        text-align: left;
        font-size: 13px;
        color: $gray-500;
      }
    }

    &__wish-num {
      text-align: center;
      height: 16px;
      font-size: 13px;
      text-align: center;
      color: $gray-500;
      margin-bottom: 16px;
    }
    &__discount-box {
      display: block;
      line-height: 1;
      margin-bottom: 4px;
      &--main-price {
        display: inline-block;
        font-size: 13px;
        font-weight: 500;
        color: $gray-500;
        margin-right: 4px;
        text-decoration: line-through;
      }
      &--percent {
        display: inline-block;
        color: $red-700;
        font-size: 13px;
        font-weight: 500;
        margin-right: 1px;
      }
      &--icon {
        width: 10px;
        height: 10px;
      }
    }

    button,
    .offer__side--main-box__ask > a {
      display: block;
      width: 100%;
      margin-bottom: 8px;
      border-radius: 2px;
    }
  }
  &__second-box {
    border-radius: 2px;
    background-color: $gray-60;
    margin-top: 16px;
    font-size: 14px;
    font-weight: 500;
    color: $gray-600;
    padding: 8px 0;

    @media #{$information-mobile} {
      display: flex;
      flex-wrap: wrap;
      margin: 0px -16px;
      width: calc(100% + 32px);
      div {
        min-width: 50%;
      }
    }
    &__icon {
      width: 24px;
      height: 24px;
      margin-top: 8px;
      margin-left: 16px;
      margin-right: 8px;
    }
    &__content {
      word-break: break-all;
      max-width: 248px;
      display: inline-block;
      vertical-align: top;
      padding: 8px 0;
      @media #{$information-only-desktop} {
        max-width: 224px;
      }
      @media #{$information-only-tablet} {
        max-width: 264px;
      }
    }
  }

  &__banner-box {
    width: 320px;
    height: 80px;
    border-radius: 2px;
    margin-top: 16px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    @media #{$information-only-desktop} {
      width: 296px;
      height: 74px;
    }
  }
}
