@import 'share';
.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.link {
  font-size: 13px;
  color: $gray-600;
}