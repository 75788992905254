@import 'share';
.container {
  position: relative;
  padding-top: 72px;
  text-align: center;
  overflow: hidden;
}

.title {
  font-size: 40px;
  font-weight: 700;
  color: $gray-900;
  margin: 0;
  margin-bottom: 16px;
}

.intro {
  font-size: 26px;
  color: $gray-900;
  margin-bottom: 54px;
}

@include media('<phone') {
  .container {
    padding-top: 64px;
  }

  .title {
    font-size: 24px;
    margin-bottom: 8px;
  }

  .intro {
    font-size: 18px;
    letter-spacing: -0.68px;
    margin-bottom: 40px;
  }
}