@import 'share';
.container {
  composes: container from './CardShare.module.scss';
  transition: all 0.15s ease-out !important;

  &:hover {
    box-shadow: 0 2px 8px rgba(102, 109, 117, 0.32);
    transform: translateY(-2px);
  }

  &::after {
    transition: box-shadow 0.15s ease-out;
  }

  &:hover::after {
    box-shadow: inset 0 0 0 1px rgba(102, 109, 117, 0.32);
  }

  &:global(.swiper-slide) {
    left: 10px;
    margin-right: 20px;

    &:last-child {
      margin-right: 0 !important;
    }
  }

  &.grid {
    width: 100%;
    margin-bottom: 20px;
    vertical-align: middle;
  }

  &.offer {
    height: 311px;
  }

  &.hotel {
    height: 287px;
  }
}

.fixedWidth {
  &.swiper {
    width: 250px !important;
  }
}

.loadingCover {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(248, 249, 250, 0.8);
  pointer-events: none;

  svg {
    width: 40px;
    height: 40px;

    rect {
      fill: var(--blue-600);
    }
  }
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

@include media('<phone') {
  .container {
    &:hover {
      box-shadow: none;
      transform: translateY(0px);
      opacity: 0.6;
    }

    &::after {
      box-shadow: none;
    }

    &:hover::after {
      box-shadow: none;
      opacity: 0.6;
    }

    &:global(.swiper-slide) {
      left: 0;
      margin-right: 8px;
    }

    &.offer {
      height: 200px;
    }

    &.hotel {
      height: 180px;
    }

    &.grid {
      margin-bottom: 16px;
    }
  }

  .fixedWidth {
    &.swiper {
      width: 160px !important;
    }
  }
}
