@import 'share';
.container {
  line-height: 1;
  text-align: right;
}

.origin {
  margin-bottom: 4px;
  padding: 0;
}

.origin > del {
  color: $gray-500;
  font-size: 12px;
}

.rate {
  color: #cc3229;
  font-size: 13px;
  font-weight: 600;
  font-style: normal;
  margin-right: 14px;
  vertical-align: text-top;
}

.rate > span {
  margin-right: 2px;
  vertical-align: middle;
}

.rate > img {
  height: 11px;
  width: 12px;
}

.standard {
  color: $gray-500;
  font-size: 14px;
  font-weight: 600;
  margin-right: 14px;
  vertical-align: bottom;
}

.price {
  color: $gray-800;
  font-size: 18px;
  font-weight: 600;
  vertical-align: middle;
}

.immediately {
  display: inline;
  margin-left: 4px;
}

@include media('<phone') {
  .rate, .standard {
    display: none;
  }

  .origin {
    margin-bottom: 2px;
  }

  .origin > del {
    font-size: 11px;
  }

  .price {
    font-size: 13px;
    font-weight: 500;
  }
}

