@import 'share';
.container {
  width: 50%;
  vertical-align: middle;
  display: inline-block;
  padding: 12px 0;
}

.icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.label {
  font-size: 15px;
  line-height: 1.47;
  vertical-align: middle;
  letter-spacing: -0.4px;
  color: $gray-800;
}

@include media('<phone') {
  .container {
    width: 100%;
    padding: 6px 0;
  }
}
