@import 'share';
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 72px;
  padding: 0;
  border: 0;
  border-radius: 50px;
  background-color: $dgmPurple;
  outline: none;
  cursor: pointer;
  color: $white;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.55px;
  line-height: 1;

  :global(.icon) {
    width: 24px;
    margin: 0;
    margin-left: 4px;
  }
}

@include media('<phone') {
  .button {
    height: 50px;
    font-size: 16px;
    letter-spacing: -0.38px;

    :global(.icon) {
      width: 16px;
    }
  }
}
