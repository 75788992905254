@import 'share';
.shareBtn {
  height: 100%;
  background-color: $white;
  outline: none;
  border: 0;
}

.shareBtn > img {
  width: 24px;
  height: 24px;
}
