@import 'share';
.container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0px 16px;
}

.body {
  background-color: $white;
  width: 392px;
  min-height: 120px;
  border-radius: 4px;
  box-shadow: 0 15px 20px 0 rgba($black, 0.15), 0 0 0 1px rgba($black, 0.15);
  padding: 16px;
}

.content {
  padding: 8px;
  font-size: 15px;
  color: $gray-800;
}

.footer {
  width: 100%;
  text-align: right;
}

@include media('<phone') {
  .body {
    width: 100%;
  }

  .content {
    padding: 0;
  }
}

