@import 'share';
.container {
  text-align: left;
  margin-bottom: 16px;
  display: flex;
  align-items: flex-end;
}

.wrapper {
  width: 100%;
  flex: 1;
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
}

.title {
  color: $gray-800;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 0;
}

.subtitle {
  color: $gray-800;
  opacity: 0.6;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0;
  margin-top: 12px;
}

.link {
  line-height: 1;
  margin-left: auto;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@include media('<phone') {
  .title {
    font-size: 20px;
  }

  .subtitle {
    font-size: 14px;
    font-weight: 600;
    margin-top: 6px;
  }
}
