@import 'share';
.input {
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  position: relative;
  margin: 0;
  margin-right: 10px;
  border: 1px solid $gray-300;
  border-radius: 50%;
  background-color: $white;
  vertical-align: top;
}

.label {
  font-weight: normal;
  color: $gray-800;
  display: flex;
  align-items: center;
  line-height: 1;
}

.label-text {
  display: inline-block;
}

.large.label {
  font-size: 16px;
}

.medium.label {
  font-size: 14px;
}

.input,
.label {
  cursor: pointer;
}

.disabled.input,
.disabled.label {
  cursor: not-allowed;
}

.input:hover {
  border: 2px solid $gray-400;
}

.input[disabled] {
  border: 1px solid $gray-100;
}

.input:checked {
  border: 2px solid $blue-400;
}
.input:checked::-ms-check {
  border: 2px solid $blue-400;
  color: $blue-400;
}

.input:checked::after {
  content: ' ';
  display: block;
  position: absolute;
  border-radius: 50%;
  background-color: $blue-500;
}

.input:checked::after::-ms-check {
  content: ' ';
  display: block;
  position: absolute;
  border-radius: 50%;
  background-color: $blue-500;
  color: $blue-500;
}

.large.input:checked::after {
  width: 12px;
  height: 12px;
  top: 4px;
  left: 4px;
}

.medium.input:checked::after {
  width: 10px;
  height: 10px;
  top: 3px;
  left: 3px;
}

.large.input {
  width: 24px;
  height: 24px;
}

.medium.input {
  width: 20px;
  height: 20px;
}
