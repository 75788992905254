.banner-top-line {
  position: relative;
  &__link {
    box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.05); 
    display: inline-block;
    height: 100%;
    width: 100%;
  }

  &__close {
    line-height: 1;
    position: absolute;
    right: 16px;
    top: 16px;

    @media #{ $information-mobile } {
      right: 6px;
      top: 6px;
    }
  }
}
