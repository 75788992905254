@import 'share';
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 64px auto 240px auto;

  &.guest {
    margin: 64px auto 120px auto;
  }
}

.signUpBannerContainer {
  width: 100%;
  max-width: 462px;
  margin: 0 auto;
  padding: 0 16px;
}

.signUpBannerWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 72px;
  margin: 60px auto 0 auto;
  border-radius: 4px;
  background-color: #156adb;
  text-align: center;

  .signUpBannerLink {
    display: inline-block;
    overflow: hidden;
    max-width: 311px;
  }

  .signUpBanner {
    width: auto;
    height: 48px;
  }
}

@include media('<phone') {
  .container {
    &.guest {
      margin: 0 auto 80px auto;
    }
  }

  .signUpBannerWrapper {
    margin-top: 24px;
  }
}