@import 'share';
.container {
  padding: 32px 0;
  border-top: 1px solid $gray-100;
  border-bottom: 1px solid $gray-100;
  display: flex;
}

.noBorderTop {
  border-top: none;
}

.icon {
  width: 48px;
  height: 48px;
  margin-right: 24px;
}

.body {
  flex: 1;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.title {
  font-size: 22px;
  font-weight: 700;
  line-height: 1.36;
  letter-spacing: -0.28px;
  color: $gray-800;
}

.more {
  margin-left: 8px;
  width: 16px;
  height: 16px;
}

.content {
  font-size: 15px;
  line-height: 1.6;
  letter-spacing: -0.3px;
  color: $gray-800;
}

@include media('<phone') {
  .container {
    padding: 24px 0;
  }
}
