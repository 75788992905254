@import 'share';
.container {
  width: 100%;
}

.buttons {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  width: 100%;
}

.button {
  @include media('<phone') {
    flex: 1,
  }
}

.subButton {
  margin-right: 8px;
}