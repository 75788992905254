@import 'share';
.container {
  width: 100%;
  background-color: $blue-600;
  text-align: center;
  padding-top: 96px;
  padding-bottom: 150px;
}

.header {
  margin-bottom: 36px;
}

.title {
  margin: 0;
  margin-bottom: 8px;
  font-size: 48px;
  font-weight: 700;
  line-height: 1.33;
  color: $white;
}

.description {
  font-size: 24px;
  line-height: 1.5;
  color: $blue-100;
}

.coupon {
  margin: 0 auto;
  padding-left: 40px;
  padding-right: 8px;
}

.coupon > img {
  width: 750px;
}

@include media('>=phone', '<tablet') {
  .header {
    margin-bottom: 54px;
  }

  .coupon > img {
    width: 720px;
  }
}

@include media('<phone') {
  .container {
    padding-top: 56px;
    padding-bottom: 64px;
  }

  .title {
    font-size: 28px;
    line-height: 1.29;
  }

  .description {
    font-size: 16px;
  }

  .coupon {
    padding-left: 28px;
    padding-right: 20px;
  }

  .coupon > img {
    width: 100%;
  }
}
