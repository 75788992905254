@import 'share';
.container {
  width: 100%;
  height: 100%;
}

.day {
  width: 40px;
  height: 40px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 16px;
  color: $gray-800;

  @include media('<phone') {
    height: 100%;
    width: 100%;
  }
}

.day:not(.blocked, :global(.CalendarDay__blocked_out_of_range) > .day):hover {
  border: 2px solid $blue-400;
  background-color: $white;
  font-weight: normal;
  color: $gray-800;
  position: absolute;
  top: 0;
}

.blocked:not(.selected-start),
:global(.CalendarDay__blocked_out_of_range) > .day {
  color: $gray-200 !important;
  cursor: not-allowed;
}

.today {
  background-color: $gray-80;
}

.selected-start,
.selected-end {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: $blue-400 !important;
  font-weight: 600 !important;
  color: $white !important;
}

.left-half,
.right-half {
  position: absolute;
  top: 0;
  width: 50%;
  height: 100%;
  background-color: $blue-80;
}

.left-half {
  left: 0;
}

.right-half {
  right: 0;
}

.nav-prev,
.nav-next {
  height: 24px;
  position: absolute;
  top: 22px;
  width: 24px;
  transition: background-color 0.2s;
}

.nav-prev {
  left: 30px;
}

.nav-next {
  right: 30px;
}

.nav-prev:hover,
.nav-next:hover {
  background-color: $gray-80;
}

:global {
  .RangeDatePicker--vertical {
    :local(.left-half),
    :local(.right-half) {
      height: calc(100vw / 7);
    }
  }

  .RangeDatePicker--horizontal {
    :local(.left-half),
    :local(.right-half) {
      height: 40px;
    }
  }

  .CalendarDay__selected_start.CalendarDay__selected_end {
    :local(.left-half),
    :local(.right-half) {
      height: 0;
      width: 0;
    }
  }
}
