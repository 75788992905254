@import 'share';
.container {
  display: flex;
  align-items: center;
}

.image {
  width: 80px;
  height: 80px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 16px;
  border-radius: 4px;
}

.title {
  margin: 0;
  font-size: 17px;
  font-weight: 600;
  line-height: 1.41;
  letter-spacing: -0.4px;
  color: $gray-800;
  flex: 1;
}

@include media('<phone') {
  .image {
    width: 48px;
    height: 48px;
  }
}
