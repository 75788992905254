@import 'share';
.root {
  display: flex;
  flex-direction: row;
  justify-content: center;

  .inner {
    max-width: 1080px;
    padding: 0 64px;
    margin: 64px 0;
  }
}

.title {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 32px;
}

.container {
  margin-bottom: 46px;
  letter-spacing: -0.4px;
  font-size: 15px;
  color: $gray-900;
  white-space: pre-line;
  word-break: keep-all;

  &:last-child {
    margin-bottom: 0;
  }

  h1 {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 32px;
  }

  h2 {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 16px;
  }

  p.subtitle {
    padding-left: 160px;
  }

  ol.orderList {
    padding-left: 0;
  }

  ul.list {
    li {
      margin-left: 0;
      list-style: none;
    }
  }

  ul,
  li {
    list-style: decimal;
    font-size: 15px;
  }

  li {
    margin: 15px 15px 0 15px;
  }

  .noListStyle {
    list-style: none;

    li {
      list-style: none;
      margin: 10px 10px 0 15px;
    }
  }

  .downloadLink {
    font-size: 16px;
    height: 32px;
    padding: 8px;
    color: $blue-500;
    background-color: white;
    border-radius: 4px;
    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);

    &:hover {
      background-color: $blue-50;
    }
  }
}
