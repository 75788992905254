@import 'share';
.toolbar-container {
  width: 100%;
  position: fixed;
  bottom: 16px;
  z-index: 1;
  visibility: hidden;
  text-align: center;
}

.toolbar-container > span > div > button {
  min-width: 98px;
  height: 44px;
  padding: 0px 15px !important;
  border-radius: 22px !important;
  border-left: 0px !important;
  border-right: 0px !important;
  left: 0 !important;
}

.toolbar-container > span {
  display: inline-block;
  visibility: visible;
}

.label {
  line-height: 20px;
}

.label > img {
  margin-right: 6px;
  width: 12px;
  height: 12px;
  margin-bottom: 4px;
  vertical-align: bottom;
}

.label.active {
  color: $blue-500;
}
