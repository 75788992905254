@import 'share';
$border: 1px solid $gray-200;

.container {
  width: 100%;
  border-top: $border;
  border-bottom: $border;
}

.row {
  border-bottom: $border;
  display: flex;
  align-items: center;
}

.row:last-child {
  border-bottom: 0;
}

.col {
  flex: 1;
  padding: 8px 20px;
  font-size: 14px;
  letter-spacing: -0.3px;
  color: $gray-800;
}

.row > .col:nth-child(1) {
  background-color: $gray-50;
}

.row > .col:nth-child(2) {
  font-weight: 700;
}
