@import 'share';
.container {
  background: none;
  border: 0;
  line-height: 1;
  outline: none !important;
  padding: 0;
  vertical-align: middle;

  img {
    width: 24px;
    height: 24px;
    margin-top: 2px;

    @include media('<phone') {
      height: 20px;
      width: 20px;
    }

    &:hover {
      background-color: $gray-80;
      border-radius: 2px;
    }
  }
}

.container.small > img {
  width: 20px;
  height: 20px;
}
