@import 'share';
.container {
  margin-bottom: 4px;
  width: 310px;
}

.title {
  font-size: 16px;
  font-weight: 600;
  color: $gray-800;
  line-height: 1.25;
  margin: 0;
  width: 100%;
}

.description {
  margin-top: 8px;
}

@include media('<phone') {
  .description {
    display: none;
  }
}
