@import 'share';
.CrossSell {
  margin-top: 96px;
  position: relative;
  width: 100%;

  @media #{ $information-only-tablet } {
    margin-top: 68px;
  }

  @media #{ $information-mobile } {
    margin-top: 24px;
  }

  .swiper-wrapper {
    overflow: unset;
  }

  &__Header {
    margin-bottom: 24px;
    position: relative;
    width: 100%;

    @media #{ $information-mobile } {
      margin-bottom: 8px;
    }
  }

  &__Title {
    color: $gray-800;
    font-size: 20px;
    font-weight: 600;

    @media #{ $information-mobile } {
      font-size: 15px;
    }
  }

  &__ShowMore {
    bottom: 0;
    color: $blue-500;
    font-size: 14px;
    font-weight: 500;
    position: absolute;
    right: 0;

    &__Label {
      vertical-align: middle;
      margin-right: 2px;
    }
  }

  &__Assurance {
    background-color: $gray-50;
    border: 1px solid $gray-100;
    border-radius: 2px;
    height: 96px;
    margin-top: 48px;
    padding-top: 32px;
    text-align: center;
    width: 100%;

    @media #{ $information-mobile } {
      text-align: left;
      padding: 28px 20px 0 20px;
    }

    &__Label {
      color: $gray-700;
      display: inline-block;
      font-size: 18px;
      margin-right: 28px;
      vertical-align: middle;

      @media #{ $information-mobile } {
        font-size: 15px;
        margin-right: 22px;
      }

      .Prefix {
        color: $gray-800;
        font-weight: 700;
      }

      .Suffix {
        @media #{ $information-mobile } {
          display: block;
        }
      }
    }

    .app-button {
      padding: 8px 0;
      text-align: center;
      width: 64px;
    }
  }
}

a.CrossSell__ShowMore {
  &:hover {
    color: $blue-500;
  }
}