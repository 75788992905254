.offer-scroll-navbar {
  height: 56px;
  line-height: 56px;
  background-color: $white;
  display: none;
  border-collapse: separate;
  box-shadow: 0 2px 4px 0 rgba($black, 0.1), 0 1px 0 0 rgba($black, 0.1);
  &__content {
    font-size: 15px;
    font-weight: 500;
    color: $gray-700;
    display: inline-block;
    margin-right: 32px;
    transition: all 0.1s ease-out;
    &:hover {
      border-collapse: separate;
      box-shadow: inset 0 -3px 0 $gray-300;
    }
    @media #{$information-mobile} {
      margin-right: 20px;
      font-size: 14px;
    }
  }
  &__share-wrapper {
    display: inline-block;
    position: relative;
    float: right;
  }
  &__share-icon {
    width: 24px;
    height: 24px;
    object-fit: contain;
    cursor: pointer;
    &:hover,
    &:active {
      border-radius: 2px;
      background-color: $gray-80;
    }
  }
  &--visible {
    display: block;
    z-index: 150;
  }
  &--active {
    border-collapse: separate;
    box-shadow: inset 0 -3px 0 #51abf3;
    font-weight: bold;
  }
}
