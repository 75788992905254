@import 'share';
.content {
  padding: 24px;
  color: $gray-800;
  font-size: 15px;
}

.info {
  line-height: 1.67;
}

.offer {
  margin: 0;
  margin-top: 6px;
  padding: 12px;
  border-radius: 6px;
  background-color: $gray-60;
}

.offer > strong {
  font-weight: 600;
  line-height: 1.6;
  margin-bottom: 4px;
}

.offer > span {
  font-size: 14px;
  line-height: 1.79;
}

@include media('<phone') {
  .content {
    padding-bottom: 80px;
  }
}