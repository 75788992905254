@import 'share';
.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  margin: 0;
  margin-bottom: 22px;
  font-size: 20px;
  color: $white;
  opacity: 0.5;
  line-height: normal;
}

.count {
  font-size: 48px;
  font-weight: 700;
  letter-spacing: -0.58px;
  color: $white;
  line-height: normal;
}

@include media('<phone') {
  .title {
    font-size: 16px;
    margin-bottom: 12px;
  }

  .count {
    font-size: 32px;
  }
}