@import 'share';
.title {
  margin: 0;
  margin-bottom: 56px;
  font-size: 36px;
  font-weight: 700;
  color: $gray-800;
}

.items {
  display: flex;
  justify-content: space-between;
  width: 718px;
  margin: 0 auto;
}

@include media('>=phone', '<tablet') {
  .container {
    padding: 72px 0;
  }
}

@include media('<phone') {
  .container {
    padding-top: 48px;
    padding-bottom: 56px;
  }

  .title {
    font-size: 24px;
    margin-bottom: 32px;
  }

  .items {
    width: 100%;
    justify-content: center;
    flex-direction: column;
  }
}