@import 'share';
.container {
  padding: 32px;
  width: 342px;
  height: 406px;
  border-radius: 12px;
  box-shadow: 0 12px 40px 0 rgba($black, 0.1);
}

@include media('<phone') {
  .container {
    width: 100%;
    height: auto;
  }

  .container:first-child {
    margin-bottom: 32px;
  }
}