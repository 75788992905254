@import 'share';
.container {
  padding-top: 42px;
  padding-bottom: 200px;
}

@include media('>=phone', '<desktop') {
  .container {
    padding-top: 48px;
  }
}

@include media('<phone') {
  .container {
    padding-bottom: 48px;
    padding-top: 16px;
  }
}