.modal__content {
  display: block;
  position: absolute;
  padding: 0;
  margin: 0;
  transition: all 0.3s ease 0s;
  &.dialog {
    @media #{ $information-mobile } {
      height: 100%;
      width: 100%;
    }
  }

  &.center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, calc(-50% + 8px));

    &.ReactModal__Content--after-open {
      transform: translate(-50%, -50%);
    }

    &.ReactModal__Content--before-close {
      transform: translate(-50%, calc(-50% + 8px));
    }
  }
  &__empty{
    clear: both;
  }

  &.bottom {
    bottom: 0;
    transform: translate(-50%, calc(-100% + 8px));

    &.ReactModal__Content--after-open {
      transform: translate(-50%, -50%);
    }

    &.ReactModal__Content--before-close {
      transform: translate(-50%, calc(-100% + 8px));
    }
  }

  &.top {
    top: 0;
    transform: translate(-50%, 8px);

    &.ReactModal__Content--after-open {
      transform: translate(-50%, 0);
    }

    &.ReactModal__Content--before-close {
      transform: translate(-50%, 8px);
    }
  }

  &.full {
    width: 100%;
    transform: translate(0, 8px) !important;

    &.ReactModal__Content--after-open {
      transform: translate(0, 0) !important;
    }

    &.ReactModal__Content--before-close {
      transform: translate(0, 8px) !important;
    }
  }
}

.ReactModal__Overlay {
  opacity: 0;
  transition: all 0.3s ease 0s;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Body--open {
  overflow: hidden !important;
}
