@import 'share';
.row {
  margin-bottom: 16px;
  display: flex;
  align-items: flex-start;
}

.row:last-child {
  margin-bottom: 0;
}

.title,
.content {
  display: flex;
  align-items: center;
  align-content: center;
  min-height: 24px;
}

.title {
  font-size: 14px;
  font-weight: 500;
  color: $gray-500;
  width: 60px;
  margin: 0;
  letter-spacing: -0.19px;
}

.content {
  font-size: 15px;
  color: $gray-700;
  margin-left: 12px;
  word-break: break-all;
  flex: 1;
  line-height: 1.33;
}
