@import 'share';
.container > h2 {
  margin-bottom: 24px;
  font-size: 16px;
  font-weight: 600;
  color: $gray-700;
}

.item {
  margin-bottom: 16px;
}
