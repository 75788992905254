@import 'share';
.container {
  position: relative;
  width: 100%;
}

.container.common {
  left: -10px;
  position: relative;
  width: 1080px;

  :global(.swiper-wrapper) {
    padding-bottom: 8px;
    padding-top: 2px;
  }
}

// @include media('>=tablet', '<desktop') {
//   .container.common {
//     width: 948px;
//   }
// }

// @include media('<tablet') {
//   .container.common {
//     left: 0;
//     height: auto;
//     width: 100%;

//     :global(.swiper-wrapper) {
//       left: -10px;
//     }
//   }
// }

@include media('<phone') {
  .container.common {
    left: 0;
    height: auto;
    width: 100%;

    :global(.swiper-wrapper) {
      padding-bottom: 0;
      padding-top: 2px;
    }
  }
}
