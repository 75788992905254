@import 'share';
.container {
  position: relative;
  width: 100%;
}

.row {
  margin: 0 auto;
  position: relative;
  width: $desktop-width;
}

@include media('<phone') {
  .row {
    padding: 0 16px;
    width: $mobile-width;

    &.full {
      padding: 0;
      width: 100%;
    }
  }
}
