@import 'share';
.BookingCautionContact {
  margin-top: 24px;

  @media #{ $information-desktop } {
    margin-top: 48px;
  }

  @media #{ $information-mobile } {
    margin-top: 40px;
  }

  &__Section {
    background-color: $gray-60;
    border-radius: 2px;
    color: $gray-800;
    height: auto;
    min-height: 0;
    padding: 16px;
    vertical-align: middle;
    width: 100%;
    word-wrap: break-word;
    font-weight: 500;

    &.Caution {
      font-size: 14px;
      color: $gray-800;

      @media #{ $information-mobile } {
        font-size: 13px;
      }
    }

    &.Contact {
      font-size: 13px;
      color: $gray-700;
      margin-top: 16px;
      display: flex;
      flex-direction: column;

      article {
        flex: 1 1;
      }

      .Contact__wrapper {
        display: flex;
        flex-direction: row;
        min-height: 0;
        margin-top: 16px;
        @media #{ $information-mobile } {
          margin-top: 0;
          flex-direction: column;
          article {
            border-bottom: 1px solid $gray-200;
            margin-top: 16px;
          }
          article:last-child {
            border-bottom: 0;
          }
        }
      }

      @media #{ $information-mobile } {
        margin-left: 0;
        margin-top: 8px;
      }

      .Contact__Title {
        font-size: 16px;
        font-weight: 600;
        margin: 0;
        border-bottom: 1px solid $gray-200;
        padding-bottom: 10px;
      }

      .Contact__agency-title {
        font-size: 15px;
        font-weight: 600;
        color: $gray-700;
      }

      .Contact__Row {
        margin: 8px 0;
      }

      .Contact__Item {
        display: inline-block;

        &:nth-child(2) {
          margin-left: 24px;
        }

        &__Label {
          font-weight: normal;
        }

        &__Value {
          font-weight: bold;
        }
      }
    }
  }
}
