@import 'share';
.container {
  position: relative;
  padding: 42px 0 40px;
  margin-bottom: 72px;

  img {
    margin: 0 auto;
  }
}

.title {
  img {
    width: 419px;
  }
}

.coupon {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
}

.couponCard {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 329px;
  padding: 0 16px 16px;
  border-radius: 8px;
  background-color: $gray-100;

  &:global(.typeA) {
    margin-right: 8px;
    background-color: #EDE7FF;
  }

  .imgBox {
    display: flex;
    align-items: center;
    height: 239px;
  }

  img {
    width: 227px;
  }

  .couponBtn {
    margin-top: -7px;
  }

  .couponBadge {
    position: absolute;
    top: 80px;
    left: 14px;

    img {
      width: 80px;
    }
  }
}

.indicator {
  position: absolute;
  top: 88px;
  left: 50%;
  transform: translateX(-50%);

  img {
    width: 60px;
  }
}

.notice {
  margin-top: 40px;

  img {
    width: 666px;
  }
}

.link {
  margin-top: 40px;

  .ctaButton {
    width: 320px;
    margin: 0 auto;
  }
}

@include media('<phone') {
  .container {
    padding: 18px 0 32px;
    margin-bottom: 40px;

    img {
      width: 100%;
    }
  }

  .title {
    padding: 0 18px;
  }

  .coupon.couponMobile {
    display: block;
    padding: 0 12px;
    margin-top: 15px;
  }

  .couponCard {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 19px 0 20px;
    border-radius: 8px;
    z-index: 1;

    &:global(.typeA) {
      padding: 2px 0 36px;
      margin-right: 0;
      margin-bottom: 19px;
      background-color: #EDE7FF;
      z-index: 2;
    }

    .imgBox {
      display: block;
      height: auto;
    }

    img {
      width: 245px;
    }

    .couponBtn {
      margin-top: -10px;
      width: 209px;
    }

    .couponBadge {
      top: 104px;
      left: calc(50% + 108px);
      transform: translateX(-50%);
    }
  }

  .indicator {
    position: absolute;
    top: auto;
    bottom: -40px;
    left: 50%;
    transform: translateX(-50%);

    img {
      width: 60px;
    }
  }

  .notice {
    padding: 0 12px;
    margin-top: 32px;
  }

  .link {
    padding: 0 24px;
    margin-top: 32px;

    .ctaButton {
      width: 100%;
    }
  }
}

@include media('<smallPhone') {
  .couponMobile {
    .couponBox {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 154px;
        margin: 0;
      }

      .icoArrow {
        width: 18px;
      }
    }

    .couponBadge {
      left: calc(50% + 94px);
    }
  }
}
