@import 'share';
@include media('<phone') {
  .banner-one {
    width: 100%;
  }

  .container {
    margin-top: 24px;
    margin-bottom: 28px;
  }

  .container.banner-one {
    margin-bottom: 16px;
  }

  .container.lodging-container {
    margin-bottom: 16px;
  }
}

@include media('>=phone') {
  .container.lodging-container {
    margin-bottom: 24px;
  }
}

@include media('>=phone', '<tablet') {
  .container {
    margin-bottom: 56px;
  }
}

@include media('>=tablet', '<desktop') {
  .container {
    margin-bottom: 48px;
  }
}
