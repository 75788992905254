@import 'share';
.button {
  margin-top: 40px;
  padding: 0;
  border: 0;
  outline: none;
  background-color: transparent;
  font-size: 13px;
  color: $gray-500;
  text-decoration: underline;
}

.popup > h1 {
  margin-bottom: 20px;
  font-size: 15px;
  font-weight: normal;
  color: $gray-800;
}

.popup-buttons {
  display: flex;
  justify-content: flex-end;
}

.popup-buttons > button {
  margin-right: 8px;
}

.popup-buttons > button:last-child {
  margin-right: 0;
}
