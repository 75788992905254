@import 'share';
.container {
  width: 430px;
  margin: 0 auto;
  padding: 0 48px;
  border: 1px solid $gray-100;
  border-radius: 2px;
  text-align: center;
}

.welcomeHand {
  width: 56px;
  height: 56px;
  object-fit: contain;
  margin-top: 48px;
  animation-name: shake;
  animation-timing-function: ease-out;
  animation-iteration-count: 2;
  animation-duration: 0.6s;
  animation-delay: 1s;

  @keyframes shake {
    0% {
      transform: rotate(0deg);
    }

    50% {
      transform: rotate(45deg);
    }

    100% {
      transform: rotate(0deg);
    }
  }
}

.title {
  margin-top: 26px;
  color: $gray-800;
  font-size: 28px;
  font-weight: bold;
  line-height: 1.29;
  letter-spacing: -0.47px;

  &.guest {
    margin-top: 22px;
  }
}

.subTitle {
  margin-top: 8px;
  color: $gray-600;
  font-size: 16px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: -0.27px;

  &.guest {
    margin-top: 10px;
  }
}

.kakaoOauthButtonWrapper {
  margin-top: 24px;
  margin-bottom: 32px;

  button {
    width: 100%;
    border: 1px solid $kakao;
    background-color: $kakao;
    color: $kakao-brown;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: -0.2px;

    &:hover {
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
      transition: box-shadow 0.2s;
    }
  }

  .buttonContentContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .kakaoLogo {
    width: 18px;
    height: 18px;
    margin-right: 8px;
    object-fit: contain;
  }
}

.oauthListContainer {
  margin-bottom: 40px;
}

.footerContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 48px;
  color: $gray-500;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.23px;

  &.guest {
    margin-bottom: 0;
  }

  a {
    margin-left: 6px;
    color: $gray-600;
    text-decoration: underline !important;
  }

  a:hover {
    color: $gray-900;
  }
}

.buttonForGuest {
  margin-top: 12px;
  margin-bottom: 48px;
  color: $gray-600;
  font-size: 14px;
  font-weight: 600;

  a {
    text-decoration: underline !important;
  }
}

@include media('<phone') {
  .container {
    width: auto;
    max-width: 430px;
    padding: 0;
    border: none;

    &.guest {
      height: auto;
    }
  }

  .welcomeHand {
    margin-top: 40px;
  }

  .buttonForGuest {
    margin-bottom: 0;
  }

}