@import 'share';
.CardLowPrice {
  background-color: $yellow-600;
  bottom: 0;
  color: $white;
  font-size: 11px;
  font-weight: 500;
  height: 26px;
  padding-top: 7px;
  position: absolute;
  right: 0;
  text-align: center;
  width: 76px;

  @media #{ $information-mobile } {
    border-radius: 2px;
    font-size: 10px;
    height: 18px;
    padding-top: 4px;
    width: 70px;
  }
}
