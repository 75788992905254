@import 'share';
.container {
  margin-top: 16px;
}

.selections {
  margin-bottom: 24px;
}

.row {
  padding: 24px 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $gray-200;
}

.row > p {
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  line-height: 1.29;
  color: $gray-500;

  &.price {
    font-size: 14px;
    font-weight: 600;
    color: $gray-700;
    width: 92px;
    text-align: right;
  }
}

.title {
  flex: 1;
}

.total {
  text-align: right;
  width: 100%;
}

.total > .label {
  font-size: 14px;
  font-weight: 600;
  color: $gray-700;
  margin-right: 16px;
  line-height: normal;
}

.total > .price {
  color: $blue-500;
  font-size: 20px;
  font-weight: 700;
  line-height: 1;
}
