@import 'share';
.container {
  line-height: 1;
  padding: 0;
  text-align: right;
}

.price {
  color: $gray-800;
  display: block;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 4px;
}

.standard {
  color: $gray-500;
  font-size: 12px;
}

@include media('<phone') {
  .price {
    color: $gray-600;
    font-size: 13px;
    font-weight: normal;
  }

  .price .unit {
    font-size: 12px;
  }

  .standard {
    display: none;
  }
}