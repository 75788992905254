@import 'share';
body {
  -webkit-overflow-scrolling: touch;
  line-height: 1.5;
  margin: 0;
  padding: 0;
  overflow-anchor: none;
  &[data-controller-path="instants/reservations"][data-action="ticket_form"] {
    max-width: 100vw;
  }

  @include media('>=tablet', '<desktop') {
    width: 1200px;
  }

  @include media('>=phone', '<tablet') {
    width: 1100px;
  }

  overflow-anchor: none;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
}

a {
  cursor: pointer;
  text-decoration: none;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  color: inherit;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

img {
  border-width: 0;
  vertical-align: middle;
}

.mrt-container {
  @include responsive-row;
}

@import 'components/index';
@import 'containers/index';
