@import 'share';
.container {
  height: 243px;
  width: 100%;
  padding-top: 56px;
  margin-bottom: 80px;
  background-color: $blue-500;
  text-align: center;
}

.headline {
  margin: 64px 0 32px;
  color: $gray-800;
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
}

.search {
  width: 430px;
  margin: 0 auto 48px;
}

@include media('<phone') {
  .container {
    height: 152px;
    padding-top: 32px;
    margin-bottom: 72px;
  }

  .headline {
    margin: 24px 0 20px;
    line-height: 28px;
    font-size: 22px;
  }

  .search {
    width: 311px;
    line-height: 28px;
    margin: 0 auto 32px;

    .experiences {
      margin-right: 0;
    }
  }
}
