@import 'share';
.container {
  padding-top: 32px;
  padding-bottom: 16px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  box-shadow: 0 -12px 16px 0 rgba($black, 0.08);
  background-color: $white;
}

.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 32px;
  align-items: center;
}

.footer > a {
  margin-bottom: 16px;
}

.close {
  border: 0;
  background-color: transparent;
  color: $gray-400;
  font-size: 14px;
  font-weight: 600;
  line-height: 2;
  letter-spacing: -0.17px;
  outline: none;
}
