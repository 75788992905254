@import 'share';
.container {
  margin-bottom: 112px;
}

@include media('<phone') {
  .container {
    margin-bottom: 72px;
  }
}
