@import 'share';
.OfferCardNowUse {
  &__Icon {
    height: 16px;
    width: 9px;
  }

  &__Label {
    color: $gray-600;
    font-size: 12px;
    margin-left: 2px;
    vertical-align: middle;
  }
}