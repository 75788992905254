@import 'share';
.form-control {
  display: block;
  margin-bottom: 24px;
}

.form-control.last {
  margin-bottom: 32px;
}

.form-control > p {
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 500;
  color: $gray-700;
  line-height: 16px;
}

.buttons {
  text-align: center;
}

.termWrapper {
  display: flex;
  margin-bottom: 18px;
}

.termInfoWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-weight: 500;
  font-size: 15px;
  line-height: 20.7px;
  letter-spacing: -1%;
  color: $gray-800;
  gap: 4px;
}

.termInfoTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-right: 10px;
}

.termInfo {
  font-size: 12px;
  line-height: 14.88px;
  letter-spacing: -2%;
  color: $gray-500;
}
