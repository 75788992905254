@import 'share';
.container {
  display: flex;
  align-items: center;
}

.label {
  font-size: 14px;
  color: $gray-600;
}

.icon {
  margin-left: 7px;
}

@include media('<phone') {
  .label {
    font-size: 14px;
  }
}
