@import 'share';
.container {
  width: 100%;
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba($gray-900, 0.1), 0 0 0 1px $gray-100;
  background-color: $white;
  transition: box-shadow 0.2s ease;
  position: relative;
  margin-bottom: 8px;
}

.container:last-child {
  margin-bottom: 0;
}

.container.selected {
  box-shadow: 0 4px 8px 0 rgba($gray-900, 0.1), 0 0 0 1px $blue-400;
}

.body {
  position: relative;
}

.description {
  display: none;
}

@include media('>=phone') {
  .container {
    display: flex;
    padding: 32px 24px;
    align-items: center;
  }

  .body {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

@include media('<phone') {
  .container {
    padding: 16px;
  }

  .body {
    margin-top: 4px;
    display: block;
    min-height: 44px;
  }

  .description {
    display: block;
  }
}
