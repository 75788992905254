@import 'share';
.OfferCard {
  background-color: $white;
  display: inline-block;
  line-height: 1;
  position: relative;

  @include media('<phone') {
    background-color: inherit;
  }

  &--list {
    margin-bottom: 20px;
    margin-right: 20px;

    @include media('>=desktop') {
      &:nth-child(4n) {
        margin-right: 0;
      }
    }

    @include media('>=tablet', '<desktop') {
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }

  &__Cover {
    display: inline-block;
    height: 332px;
    position: relative;
    width: 250px;

    &:before {
      box-shadow: inset 0 0 0 1px rgba($black, 0.16);
      border-radius: 2px;
      content: '';
      left: 0;
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
    }

    @media #{ $information-desktop } {
      width: 296px;
    }

    @media #{ $information-mobile } {
      &:before {
        box-shadow: none;
      }
      height: 205px;
      width: 170px;
    }
  }

  &__Wishlist {
    right: 8px;
    position: absolute;
    top: 8px;
    z-index: 1;
  }

  &__Swiper__Review {
    margin-bottom: 8px;
  }
}

.swiper-slide.OfferCard {
  height: 332px;
  width: 250px;

  @media #{ $information-desktop } {
    width: 296px !important;
  }
  @media #{ $information-mobile } {
    height: 205px;
    width: 170px !important;
  }
}
