@import 'share';
.header {
  padding: 16px 24px;
  margin-bottom: 4px;

  @include media('<phone') {
    padding: 16px;
    margin-bottom: 4px;
  }
}

.message {
  align-items: center;
  background-color: $blue-80;
  border: 1px solid $blue-100;
  border-radius: 4px;
  display: flex;
  height: 40px;
  justify-content: center;
  text-align: center;
  width: 100%;

  img {
    height: 16px;
    width: 16px;
  }

  span {
    color: $blue-600;
    font-size: 16px;
    font-weight: 600;
    margin-left: 8px;
    vertical-align: middle;
  }
}

.body {
  padding: 0px 24px 24px 24px;

  @include media('<phone') {
    padding: 0px 16px 16px 16px;
  }
}

.bodyHeader {
  margin-bottom: 12px;

  .title {
    color: $gray-800;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 6px;
  }

  .desc {
    font-size: 15px;
    color: $gray-600;
  }
}

.offerBox {
  background-color: $white;
  border-radius: 6px;
  box-shadow: 0 4px 10px 0 rgba($gray-600, 0.15), 0 0 1px 0 rgba($gray-600, 0.15);
  cursor: pointer;
  padding: 12px;
  width: 100%;

  @include media('<phone') {
    margin-bottom: 64px;
  }
}

.offer {
  margin-bottom: 16px;
  width: 100%;

  @include media('<phone') {
    margin-bottom: 16px;
  }

  .offerImage {
    background-position: center;
    background-size: cover;
    border-radius: 4px;
    display: inline-block;
    height: 80px;
    width: 80px;
    vertical-align: middle;
  }

  .offerContent {
    display: inline-block;
    height: 80px;
    padding-left: 8px;
    position: relative;
    width: calc(100% - 80px);
    vertical-align: middle;
  }

  .offerCategory {
    color: $blue-500;
    font-size: 12px;
    margin-bottom: 2px;
    font-weight: 600;
  }

  .offerTitle {
    color: $gray-800;
    font-size: 14px;
    font-weight: 600;
    height: 43px;
    line-height: 1.43;
    width: 100%;
  }
  
  .offerTitle > div {
    line-height: 1.42;
  }

  .offerRating {
    bottom: 0;
    left: 8px;
    position: absolute;
  }

  .offerReview {
    color: $gray-600;
    font-size: 11px;
    margin-left: 4px;
  }
}

.review {
  background-color: $gray-60;
  border-radius: 6px;
  padding: 12px;

  .reviewMessage {
    color: $gray-600;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 4px;
    line-height: 1.42;
  }

  .reviewMessage > div {
    line-height: 1.42;
  }

  .reviewInfo {
    color: $gray-500;
    font-size: 13px;
    font-weight: 400;
    margin-top: 6px;
  }
}
