@import 'share';
.container {
  padding: 58px 0 40px;
  margin: 72px 0;
  border-radius: 12px;
  background-color: $gray-50;

  img {
    margin: 0 auto;
  }
}

.title {
  img {
    width: 317px;
  }
}

.offerList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 950px;
  margin: 33px auto 0;
}

.link {
  width: 320px;
  margin: 22px auto 0;

  .ctaButton {
    width: 100%;
  }
}

@include media('<phone') {
  .container {
    padding: 40px 0 32px;
    margin: 40px 0;

    img {
      width: 100%;
    }
  }

  .title {
    padding: 0 33px;
  }

  .offerList {
    display: block;
    width: 100%;
    padding: 0 9px;
    margin-top: 28px;
  }

  .link {
    width: 100%;
    padding: 16px 35px 0;
    margin: 0;
  }
}
