@import 'share';
.container {
  width: 458px;
  box-shadow: 0 4px 24px 0 rgba(123, 123, 123, 0.1), 0 0 12px 0 rgba(123, 123, 123, 0.05);
  background-color: $white;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  display: inline-block;
  padding-top: 32px;
  padding-left: 30px;
  padding-right: 30px;
}

@include media('<phone') {
  .container {
    width: 100%;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    padding-top: 26px;
    padding-left: 24px;
    padding-right: 24px;
  }
}