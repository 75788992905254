@import 'share';
.container {
  margin-bottom: 120px;
}

.box {
  background-color: $gray-50;
  border: 1px solid $gray-200;
  height: 196px;
  padding: 42px 0;
  text-align: center;
  width: 100%;
}

@include media('>=tablet', '<desktop') {
  .box {
    height: 254px;
  }
}

@include media('<tablet') {
  .container {
    margin-bottom: 96px;
  }

  .box {
    height: 500px;
    padding: 32px 0;
  }
}

@include media('<phone') {
  .container {
    margin-top: 42px;
  }
}