@import 'share';
.square {
  & > div > div > div {
    width: auto !important;
    > div {
      padding-bottom: 100%;
    }
  }
}

.container {
  position: relative;
  width: 100%;
  margin-bottom: 16px;
}

.page {
  position: absolute;
  padding: 4px 10px;
  border-radius: 12px;
  background-color: rgba($gray-900, 0.7);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.2px;
  color: $white;
  line-height: normal;
  bottom: 16px;
  right: 16px;
  left: auto;
  z-index: 1;
  width: auto;
}

.photo {
  width: 100%;
  height: 304px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.mask {
  position: absolute;
  width: 100%;
  height: 120px;
  top: 0;
  left: 0;
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.8)
  );
}
