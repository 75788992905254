@import 'share';
.container {
  width: 100%;
  text-align: center;
  margin-bottom: 24px;
}

.logo {
  width: 112px;
  margin-bottom: 16px;
}

.notice {
  font-size: 22px;
  font-weight: 700;
  line-height: 1.41;
  letter-spacing: -0.27px;
  color: $gray-800;
  margin-bottom: 8px;
}

.desc {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.57;
  letter-spacing: -0.2px;
  color: $gray-700;
}

@include media('>=phone') {
  .logo {
    margin-bottom: 32px;
  }

  .notice {
    font-size: 24px;
    line-height: normal;
    letter-spacing: -0.2px;
    color: $gray-700;
  }
}
