@import 'share';
.container {
  width: 100%;
}

.datepicker {
  width: 100%;
  position: relative;
}

@include media('>=phone') {
  .datepicker {
    width: 100%;
    display: flex;
    justify-content: center;
    border: solid 1px $gray-100;
    border-radius: 4px;
    padding: 30px 0;
  }

  .datepicker.load {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}

@include media('<phone') {
  .container {
    position: relative;
    width: 100vw;
    margin: 0 -20px;
  }
  .datepicker {
    padding-bottom: 10px;
  }
  .container :global {
    .CalendarMonth_caption {
      padding-right: 0;
      text-align: center;
      font-size: 14px;
      line-height: 2.1;
    }

    .CalendarMonth_caption > strong {
      font-weight: 600;
    }

    .DayPicker {
      width: 100% !important;
    }

    .DayPicker_transitionContainer {
      width: 100% !important;
      height: calc((100vw / 7) * 5 + 120px) !important;
    }

    .DayPicker_weekHeader_li {
      font-size: 13px;
    }

    .CalendarDay {
      font-size: 15px;
    }

    img[class^='RangeDatePicker-module__nav-prev'] {
      left: 12px;
    }

    img[class^='RangeDatePicker-module__nav-next'] {
      right: 12px;
    }
  }
}
