.Popover{
  border-radius: 2px;
  background-color: $white;
  box-shadow: 0 0 0 1px rgba($black, .1), 0 5px 10px 0 rgba($black, .12);
  display: none;
  opacity: 0;
  position: absolute;
  transform: scale(0.5);
  transform-origin: top;
  transition: transform .12s ease, opacity .12s ease;
  z-index: 100;

  &.not-absolute{
    position: relative;
  }

  &.show {
    display: block;
  }

  &.Animate--show {
    opacity: 1;
    transform: scale(1);
  }

  &.vertical {
    border-radius: 8px;
    margin-top: 8px;

    @media #{ $information-mobile } {
      margin-top: 0;
    }
  }

  &.bottom-left {
    left: 0;
    top: 100%;
    transform-origin: left top;
  }

  &.bottom-right {
    right: 0;
    top: 100%;
  }

  &.right-bottom {
    left: 100%;
    top: 0;
  }

  &.right-top {
    left: 100%;
    bottom: 0;
  }
  
  &.right-reverse{
    right: 0;
    transform-origin: right top;
  }

  .PopoverItem {
    &__CloseButton {
      position: absolute;
      right: 16px;
      top: 16px;
    }
  }
};

.popover__bottom {
  display: inline-block;
  height: 32px;
  margin-top: 16px;
  width: 100%;

  button {
    &:first-child {
      float: left;
      line-height: 2;
    }
    &:last-child {
      float: right;
    }
  }

  &.datepicker {
    margin-top: 0;
    padding: 0 22px 24px 22px;
    height: 56px;
  }
}
