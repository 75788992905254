@import 'share';
.container {
  color: $gray-500;
  font-size: 12px;
  max-width: 100%;
  overflow: hidden;
}

.label {
  line-height: initial;
}
