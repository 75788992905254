@import 'share';
.container {
  position: relative;
}

.body {
  width: 100%;
  overflow: hidden;
}

.more {
  width: 100%;
}

.more.absolute {
  position: absolute;
  bottom: 0;
  left: 0;
}

.button {
  width: 100%;
  padding-top: 16px;
  background-color: $white;
  text-align: center;
}

.gradation {
  width: 100%;
  height: 120px;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), $white 99%);
}

.buttonLabel,
.textLabel {
  vertical-align: middle;
}

.buttonIcon {
  margin-left: 4px;
}

.text {
  font-size: 14px;
  font-weight: 700;
  line-height: 1.14;
  color: $blue-500;
  margin-top: 4px;
}

.textIcon {
  margin-left: 2px;
}
