@import 'share';
.container {
  position: relative;
  width: 100%;
  box-shadow: 0 4px 8px rgba(33, 37, 41, 0.1);
}

.line {
  width: 100%;
  height: 16px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  left: 0;
  top: 0;

  &:global(.dgm) {
    background-color: #582c9a;
  }

  &:global(.mrt) {
    background-color: $blue-500;
  }
}

.body {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: $white;
}

@include media('<phone') {
  .container {
    box-shadow: 0 3px 5px rgba(33, 37, 41, 0.1);
  }

  .line {
    height: 11px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .body {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}