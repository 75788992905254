@import 'share';
.container {
  display: inline-block;
  position: relative;
}

.icon {
  width: 9px;
}

.text {
  color: $gray-600;
  font-size: 12px;
  margin-left: 2px;
  vertical-align: middle;
}

@include media('<phone') {
  .container.vertical {
    display: none;
  }

  .container.bestSeller {
    .icon {
      width: 7px;
    }
  }
}
