@import 'share';
.container {
  margin: 0 auto 100px;
}

.row {
  margin-bottom: 16px;

  > label > input[type="checkbox"] {
    margin: 0 8px 0 0;
  }
}
