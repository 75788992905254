@import 'share';
.description {
  display: none;
}

@include media('<phone') {
  .container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .description {
    display: block;
  }
}
