@import 'share';
.container {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.partner {
  display: flex;
  flex: 1;
  align-items: center;
}

.photo {
  width: 48px;
  height: 48px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 16px;
  box-shadow: 0 0 0 1px rgba(33, 37, 41, 0.15);
}

.name {
  margin: 0;
  flex: 1;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.33;
  letter-spacing: -0.4px;
  color: $gray-800;
  margin-right: 24px;
}

.messageIcon {
  margin-right: 4px;
}

.messageLabel {
  vertical-align: middle;
}

.otherActionWrapper {
  display: flex;
  align-items: center;
  > * + * {
    margin-left: 10px;
  }
  @media #{$information-mobile} {
    > * + * {
      margin-left: 20px;
    }
  }
}

@include media('<phone') {
  .photo {
    width: 40px;
    height: 40px;
    margin-right: 14px;
  }

  .name {
    font-size: 15px;
  }
}
