@import 'share';
.container {
  margin: 0;
  margin-top: 32px;
}

.item {
  margin-bottom: 24px;
  width: 100%;
}

.item:last-child {
  margin-bottom: 0;
}

@include media('<phone') {
  .container {
    margin-top: 16px;
  }
}
