@import 'share';
.container {
  margin-bottom: 56px;
}

.link {
  display: inline-block;
}

.banner {
  box-shadow: inset 0 0 0 1px rgba($black, 0.05);
  border-radius: 4px;
  width: 100%;
}

@include media('<phone') {
  .container {
    margin-bottom: 24px;
  }

  .link:global(.swiper-slide)  {
    width: calc(100vw - 32px);
  }
}
