@import 'share';
.container {
  border-radius: 2px;
  background-color: $white;
  box-shadow: 0 0 0 1px rgba($black, 0.1), 0 5px 10px 0 rgba($black, 0.12);
  display: none;
  opacity: 0;
  position: absolute;
  transform: scale(0.5);
  transform-origin: top;
  transition: transform 0.12s ease, opacity 0.12s ease;
  z-index: 100;
}

.show {
  display: block;
}

.animation {
  opacity: 1;
  transform: scale(1);
}

.bottomLeft {
  left: 0;
  top: 100%;
  transform-origin: left top;
}

.bottomRight {
  right: 0;
  top: 100%;
  transform-origin: right top;
}

.rightBottom {
  left: 100%;
  top: 0;
}

.rightTop {
  left: 100%;
  bottom: 0;
}

.topRight {
  bottom: 100%;
  right: 0;
}

.topLeft {
  bottom: 100%;
  left: 0;
}

.close {
  position: absolute;
  right: 20px;
  top: 20px;
}

.close > button > img {
  width: 20px;
  height: 20px;
  margin-top: unset;
}
