@import 'share';
.container {
  width: 400px;
  height: 580px;
  background-color: $white;
  border-radius: 4px;
  box-shadow: 0 15px 20px 0 rgba($black, 0.15), 0 0 0 1px rgba($black, 0.15);
  display: flex;
  flex-direction: column;
  position: relative;
}

.body {
  padding: 32px 36px 0 36px;
  width: 100%;
  flex: 1;
  overflow: auto;
}

.title {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.42;
  color: $gray-800;
  margin-bottom: 8px;
  text-align: center;
}

.footer {
  border-top: 1px solid $gray-200;
  padding: 0 16px;
  display: flex;
  height: 72px;
  align-items: center;
  justify-content: space-between;

  button {
    margin-right: 8px;
    height: 40px;
    font-size: 14px;
    padding: 0;
    flex: 1;
  }

  button:last-child {
    margin-right: 0
  }
}

.close {
  display: none;
  position: absolute;
  top: 12px;
  left: 12px;
  z-index: 10;
}

@include media('<phone') {
  .container {
    width: 100%;
    height: 100vh;
    border-radius: 0;
  }

  .body {
    padding: 72px 24px 96px 24px;
  }

  .close {
    display: block;
  }

  .footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: $white;
  }
}