.app-banner-top {
  height: 56px;
  position: relative;
  width: 100%;
  z-index: 25;

  &--fixed {
    left: 0;
    position: fixed;
    top: 0;
  }
}
