@import 'share';
.modal {
  composes: marginZero paddingZero from './layout.module.scss';

  display: block;
  position: absolute;
  transition: opacity 0.3s, transform 0.3s;
}

.modal.top {
  width: 100%;
  top: 0;
  transform: translateY(-100%);

  &:global(.ReactModal__Content--after-open) {
    transform: translateY(0);
  }

  &:global(.ReactModal__Content--before-close) {
    transform: translateY(-100%);
  }
}

.modal.center {
  top: 50%;
  left: 50%;
  opacity: 0;
  transform: translate(-50%, calc(-50% + 8px));

  &:global(.ReactModal__Content--after-open) {
    opacity: 1;
    transform: translate(-50%, -50%);
  }

  &:global(.ReactModal__Content--before-close) {
    opacity: 0;
    transform: translate(-50%, calc(-50% + 8px));
  }
}

.modal.bottom {
  width: 100%;
  bottom: 0;
  transform: translateY(100%);

  &:global(.ReactModal__Content--after-open) {
    transform: translateY(0);
  }

  &:global(.ReactModal__Content--before-close) {
    transform: translateY(100%);
  }
}

.modal.full-mode {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  transform: translateY(8px);

  &:global(.ReactModal__Content--after-open) {
    opacity: 1;
    transform: translateY(0);
  }

  &:global(.ReactModal__Content--before-close) {
    opacity: 0;
    transform: translateY(8px);
  }
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(102, 109, 117, 0.9);
  transition: opacity 0.3s;
  opacity: 0;
  z-index: 999;

  &:global(.ReactModal__Overlay--after-open) {
    opacity: 1;
  }

  &:global(.ReactModal__Overlay--before-close) {
    opacity: 0;
  }
}

@include media('<phone') {
  .modal {
    width: 100%;
  }
}
