@import 'share';
@keyframes skeleton {
  0% {
    transform: translateX(0);
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  80% {
    opacity: 1;
  }

  100% {
    transform: translateX(100%);
    opacity: 0;
  }
}

.SkeletonLoader {
  background-color: $gray-80;
  position: relative;
  overflow: hidden;

  &__Bar {
    position: absolute;
    top: 0;
    left: 0;
    animation-name: skeleton;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in;
    height: 100%;
    position: absolute;
    width: 100%;
  }

  &__Indicator {
    height: 100%;
    width: 0;
  }
}
