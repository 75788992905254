@import 'share';
.popup > h1 {
  margin-bottom: 20px;
  font-size: 15px;
  font-weight: normal;
  color: $gray-800;
}

.popup-button {
  display: flex;
  justify-content: flex-end;
}
