@import 'share';
.container {
  width: 100%;
  height: 100%;
  position: relative;
}

.image,
.placeholder {
  object-fit: cover;
  opacity: 0;
  transition: opacity 0.5s;
}

.image.visible,
.placeholder.visible {
  opacity: 1;
}

.placeholder {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: $gray-80;
  background-size: 32px 24px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('../../images/icons/img-placeholder-thumnail.svg');
}
