@import 'share';
@value borderZero from './layout.module.scss';

.button {
  composes: borderZero;

  height: 40px;
  padding: 10px 16px;
  border-right: 1px solid $gray-80;
  font-size: 14px;
  font-weight: 500;
  color: $gray-700;
  background-color: $white;
}

.button:first-child {
  padding-left: 24px;
  border-left: 1px solid $white;
  border-radius: 20px 0 0 20px;
}

.button:last-child {
  padding-right: 24px;
  border-right: 1px solid $white;
  border-radius: 0 20px 20px 0;
}

.button > img {
  width: 12px;
  height: 12px;
  margin-right: 6px;
}
