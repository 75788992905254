@import 'share';
.container {
  background-color: $white;
  width: 400px;
  border-radius: 4px;
  box-shadow: 0 15px 20px 0 rgba($black, 0.15), 0 0 0 1px rgba($black, 0.15);
  display: flex;
  flex-direction: column;
}

.main {
  padding: 28px 28px 48px 28px;
}

.body {
  margin-top: 24px;
}

.close {
  position: absolute;
}

.footer {
  border-top: 1px solid $gray-200;
  padding: 16px 24px;
}

@include media('>=phone') {
  .close {
    top: 24px;
    right: 24px;
  }
}

@include media('<phone') {
  .container {
    width: 100%;
    height: 100vh;
    border-radius: 0;
  }

  .main {
    flex: 1;
    padding: 16px;
  }

  .body {
    padding-top: 38px;
  }

  .close {
    top: 12px;
    left: 12px;
  }

  .footer {
    padding: 8px;
  }
}
