@import 'share';
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.spinner {
  width: 48px;
  height: 48px;
  margin-bottom: 24px;
  animation: rotate 3s linear infinite;
}

.title {
  font-size: 24px;
  font-weight: 700;
  color: $gray-800;
  margin-top: 0;
  margin-bottom: 2px;
}

.description {
  font-size: 18px;
  font-weight: 500;
  color: $gray-600;
}