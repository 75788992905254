@import 'share';
.container {
  width: 100%;
  text-align: center;
  margin-bottom: 24px;
}

.icon {
  width: 72px;
  height: 72px;
  margin-bottom: 27px;
}

.title {
  font-size: 24px;
  font-weight: 700;
  color: $gray-700;
  margin-top: 0;
  margin-bottom: 16px;
  line-height: normal;
}

.description {
  font-size: 14px;
  color: $gray-700;
}

@include media('<phone') {
  .title {
    margin-bottom: 10px;
  }
}
