@import '~app/styles/share';
@import 'OfferCancelRefund';
@import 'OfferRequired';
@import 'OfferCourse';
@import 'OfferDescription';
@import 'OfferReview';
@import 'OfferSideBar';
@import 'OfferBottomBar';
@import 'OfferScrollNavbar';
@import 'OfferMobilePrice';
@import 'OfferGuarantee';
@import 'OfferRecommend';
@import 'OfferMobileBanner';

.OfferDetail {
  position: relative;
  @media #{$information-only-tablet} {
    width: 100%;
    margin: 0 auto;
  }
  @media #{$information-mobile} {
    margin-top: 24px;
  }

  &--date-popup {
    width: 392px;
    height: 121px;
    font-size: 15px;
    font-weight: normal;
    color: $gray-800;
    background-color: white;
    position: relative;
    border-radius: 4px;
    box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(0, 0, 0, 0.15);
    padding: 24px;
    font-size: 15px;
    position: relative;
    p {
      font-size: 15px;
      color: $gray-800;
    }
    @media #{$information-mobile} {
      width: 304px;
    }
  }
}

hr,
p {
  margin: 0 0;
}
