@import 'share';
.link {
  display: inline-block;
  line-height: 1;
  box-sizing: border-box;

  &:focus {
    outline: none;
  }
}

.xLarge {
  padding-top: 22px;
}

.large {
  padding-top: 14px;
}

.medium {
  padding-top: 12px;
}

.small {
  padding-top: 8px;
}
