// 코스부분
.offer-course {
  width: 700px;

  @media #{$information-mobile} {
    width: 100%;
  }

  &--more-button-wrapper {
    margin-top: 16px;
    text-align: center;
    button {
      @include media('<phone') {
        width: 100%;
      }
      padding: 16px 40px;
      height: unset;
      line-height: 16px;
    }
  }

  &__line {
    width: 2px;
    background-color: $gray-200;
    margin-left: 9px;
    height: calc(100% - 44px);
    margin-top: 8px;
  }

  &__map-wrapper {
    display: inline-block;
    vertical-align: top;
    position: absolute;
    height: 100%;
  }
  &--arrow-icon {
    width: 16px;
    height: 16px;
    margin-left: 5px;
  }
  &--gradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: -moz-linear-gradient(
      top,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 90%,
      rgba(255, 255, 255, 1) 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 90%,
      rgba(255, 255, 255, 1) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 90%,
      rgba(255, 255, 255, 1) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    text-align: center;

    & > button {
      padding: 0 0;
      width: 120px;
      height: 40px;
      @include content-middle-center;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
    }
  }
  &__introduce {
    width: 100%;
    &__detail--wrapper {
      display: block;
      @media #{$information-mobile} {
        margin-right: 16px;
        display: flex;
        flex-direction: column;
      }
    }
    &--parent {
      display: inline-block;
      width: 648px;
      margin-left: 16px;
      @media #{$information-only-desktop} {
        width: 560px;
      }
      @media #{$information-only-tablet} {
        width: 572px;
      }
      @media #{$information-mobile} {
        width: 100%;
      }
    }
    &__title {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 16px;
      line-height: 1.38;
      color: $gray-800;
      @media #{$information-mobile} {
        font-size: 22px;
      }
    }
    &__map {
      width: 20px;
      height: 20px;
      object-fit: contain;
    }
    &--wrapper {
      display: block;
      vertical-align: top;
      margin: 0 0 0 16px;
    }
    &__content {
      padding: 16px 16px;
      border-radius: 3px;
      background-color: $gray-50;
      width: 700px;
      position: relative;
      margin-bottom: 8px;
      @media #{$information-only-desktop} {
        width: 612px;
      }
      @media #{$information-only-tablet} {
        width: 100%;
      }
      @media #{$information-mobile} {
        width: 100%;
        padding: 12px 12px 12px 8px;
      }
      &--last {
        background-color: $gray-50;
        position: relative;
        height: 80px;
        width: 700px;
        padding: 16px 16px;
        overflow: hidden;
        max-height: 80px;
        @media #{$information-only-desktop} {
          width: 612px;
        }
        @media #{$information-only-tablet} {
          width: 100%;
        }
        @media #{$information-mobile} {
          width: 100%;
          padding: 8px 8px;
        }
      }
      &__destination {
        display: inline;
        font-size: 14px;
        font-weight: bold;
        line-height: 1.57;
        margin-right: 8px;
        color: $gray-800;
        order: 1;
      }
      &__duration {
        display: inline;
        font-size: 12px;
        line-height: 1.83;
        color: #848c94;
        order: 1;
      }
      &__detail {
        margin-top: 8px;
        @media #{$information-mobile} {
          order: 2;
        }
        &--wrapper {
          width: 362px;
          display: inline-block;
          @media #{$information-mobile} {
            width: 100%;
          }
        }
        font-size: 14px;
        line-height: 1.71;
        color: $gray-800;
        &--photo {
          margin-left: 16px;
          float: right;
          width: 254px;
          margin-bottom: 8px;
          border-radius: 2px;
          height: 169px;
          display: inline-block;
          background-size: cover;
          @media #{$information-mobile} {
            margin: 8px 0;
            width: 100%;
            height: 50vw;
            order: 3;
          }
        }
      }
    }
  }
}
