@import 'share';
.container {
  position: relative;
}

.origin {
  color: $gray-500;
  font-size: 12px;
  margin-right: 4px;
  text-decoration: line-through;
}

.origin > .unit {
  font-size: 11px;
}

.main {
  color: $gray-600;
  font-size: 14px;
  font-weight: 500;
}

.main > .unit {
  font-size: 13px;
}

.standard {
  color: $gray-500;
  font-size: 12px;
  margin-left: 2px;
}

@include media('<phone') {
  .origin.visible {
    display: inline;
  }

  .main {
    font-size: 13px;
  }

  .main > .unit {
    font-size: 12px;
  }

  .standard {
    display: none;
  }
}
