@import 'share';
.container {
  width: 1242px;
  padding-top: 20px;
  padding-bottom: 112px;
  margin: 0 auto;

  img {
    display: block;
  }
}

@include media('<phone') {
  .container {
    width: 100%;
    padding-top: 12px;
    padding-bottom: 92px;
  }
}
